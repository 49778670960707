// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import { Popover } from "antd-mobile";
import Language from "../../modules/common/Language";
import PropTypes from "prop-types";

class Index extends React.Component {
  state = {
    visible: false,
    selected: Language.CurLanguage(),

    languageIndex: 0,
    languageOptions: [
      { text: "EN", icon: require("../assets/lang_en.svg") },
      { text: "中文", icon: require("../assets/lang_zh.svg") },
      { text: "Spanish", icon: require("../assets/lang_es.svg") }
    ]
  };

  componentDidMount() {
    switch (Language.CurLanguage()) {
      case "English":
        this.setState({ languageIndex: 0 });
        break;
      case "中文":
        this.setState({ languageIndex: 1 });
        break;
      case "Spanish":
        this.setState({ languageIndex: 2 });
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div
        className={"row box-shadow"}
        style={{
          // width: apx(750),
          height: apx(88),
          backgroundColor: "#fff",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #d8d8d8"
        }}
      >
        <img
          src={require("../assets/drawer_menu.svg")}
          style={{
            position: "absolute",
            left: apx(30),
            width: apx(46),
            height: apx(46)
          }}
          alt=""
          onClick={this.props.onDrawer}
        />

        <img
          src={require("../assets/rio_logo.svg")}
          style={{ width: apx(160), height: apx(36) }}
          alt=""
        />

        <Popover
          visible={this.state.visible}
          overlay={this.state.languageOptions.map(({ text, icon }) => (
            <div
              className={"row"}
              style={{
                width: apx(140),
                height: apx(46),
                justifyContent: "flex-start",
                alignItems: "center"
              }}
              onClick={() => {
                switch (text) {
                  case "EN":
                    this.setState({ visible: false, languageIndex: 0 });
                    localStorage.setItem("language", "English");
                    break;
                  case "中文":
                    this.setState({ visible: false, languageIndex: 1 });
                    localStorage.setItem("language", "中文");
                    break;
                  case "Spanish":
                    this.setState({ visible: false, languageIndex: 2 });
                    localStorage.setItem("language", "Spanish");
                    break;
                  default:
                    break;
                }
                window.location.reload();
              }}
            >
              <img
                src={icon}
                style={{
                  width: apx(26),
                  height: apx(26),
                  marginLeft: apx(17),
                  marginRight: apx(13)
                }}
                alt=""
              />
              <span
                style={{
                  fontSize: apx(22),
                  color: "#0B103C",
                  fontWeight: "bold"
                }}
              >
                {text}
              </span>
            </div>
          ))}
          onSelect={opt => {
            console.log(opt);
          }}
        >
          <div
            className={"row"}
            style={{
              backgroundColor: "#F3F7FF",
              width: apx(140),
              height: apx(46),
              justifyContent: "flex-start",
              alignItems: "center",
              borderRadius: apx(23),
              position: "absolute",
              right: apx(30)
            }}
          >
            <img
              src={this.state.languageOptions[this.state.languageIndex].icon}
              style={{
                width: apx(26),
                height: apx(26),
                marginLeft: apx(17),
                marginRight: apx(13)
              }}
              alt=""
            />
            <span
              style={{
                fontSize: apx(22),
                color: "#0B103C",
                width: apx(50),
                fontWeight: "bold"
              }}
            >
              {this.state.languageOptions[this.state.languageIndex].text}
            </span>
            <img
              src={require("../assets/arrow_down.svg")}
              style={{ width: apx(26), height: apx(26) }}
            alt=""
            />
          </div>
        </Popover>
      </div>
    );
  }
}

Index.propTypes = {
  onDrawer: PropTypes.func.isRequired
};

export default Index;
