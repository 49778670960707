import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import Container from "@material-ui/core/Container";
import Language from '../../modules/common/Language';
import { Fade } from "react-reveal";

const styles = (theme) => ({
  root: {
    position: "relative",
    color: "#fff",
    background: "url('/banner-preview.png') no-repeat",
    backgroundSize: "100%",
  },
  container: {
    // padding: '156px 0',
    // height: '500px',
    // display: 'flex',
    // position: 'relative',
    // flexDirection: 'column',
    zIndex: "100",
    position: "absolute",
    top: 0,
    bottom: 0,
    paddingLeft: "5%",
    display: "flex",
    alignItems: "center",
  },

  video: {
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // top: 0,
    // bottom: 0,
  },
  title: {
    color: "#fff",
    fontSize: "20px",
    fontWeight: 600,
    // textAlign: 'center',
    margin: "0 auto 24px",
    position: "relative",
    display: "block",
    width: "100%",
  },
  content: {
    width: "80%",
  },
  desc: {
    fontSize: "14px",
  },
});

function Banner(props) {
  const { classes } = props;
  const [currentSlide, setCurrentSlide] = useState(1);
  // const [videoLoaded, setVideoLoaded] = useState(false)
  const lang = Language.CurLanguage();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === 1 ? 2 : 1));
    }, 8000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <section id="Banner" className={classes.root}>
      <ReactPlayer
        loop={true}
        playing={true}
        width="100%"
        height="auto"
        muted={true}
        className={classes.video}
        url="https://media.riochain.io/banner-bg.mp4"
      />
      <div className={classes.shadow} />
      <Container className={classes.container}>
        {lang !== "Spanish" && (
          <div className={classes.content}>
            {currentSlide === 1 && (
              <Fade>
                <h1 className={classes.title}>
                  <div style={{ fontSize: "18px" }}>Financial Services</div>
                  <div>
                    <span className="color-light">Re</span>invented
                  </div>
                </h1>
                <div className={classes.desc}>
                  <strong>RioDeFi</strong> accelerates the mass adoption of
                  digital assets by bridging traditional and decentralized
                  finance.
                </div>
              </Fade>
            )}
            {currentSlide === 2 && (
              <Fade>
                <h1 className={classes.title}>
                  <div style={{ fontSize: "18px" }}>
                    Blockchain Infrastructure
                  </div>
                  <div>
                    <span className="color-light">Re</span>imagined
                  </div>
                </h1>
                <div className={classes.desc}>
                  <strong>RioChain</strong> network provides access to cross
                  chain DeFi services. It efficiently connects and process
                  transactions across various types of networks.
                </div>
              </Fade>
            )}
          </div>
        )}

        {lang === "Spanish" && (
          <div className={classes.content}>
            {currentSlide === 1 && (
              <Fade>
                <h1 className={classes.title}>
                  <div style={{ fontSize: "18px" }}>Servicios financieros</div>
                  <div>
                    <span className="color-light">Re</span>inventado
                  </div>
                </h1>
                <div className={classes.desc}>
                  <strong>RioDeFi</strong> acelera la adopción masiva de activos
                  digitales uniendo las finanzas tradicionales y las
                  descentralizadas.
                </div>
              </Fade>
            )}
            {currentSlide === 2 && (
              <Fade>
                <h1 className={classes.title}>
                  <div style={{ fontSize: "18px" }}>
                    Infraestructura de la cadena de bloques
                  </div>
                  <div>
                    <span className="color-light">Re</span>inventado
                  </div>
                </h1>
                <div className={classes.desc}>
                  La red de <strong>RioChain</strong> proporciona acceso a los
                  servicios DeFi. Conecta y procesa eficientemente transacciones
                  a través de varios tipos de redes.
                </div>
              </Fade>
            )}
          </div>
        )}
      </Container>
    </section>
  );
}

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Banner);
