import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Container, Link } from '@material-ui/core';
import Typography from '../components/Typography';
import { apx } from "../../themes/util";
import Language from '../common/Language';
import recruiment_header_en from '../../assets/img/recruiment_header_en.png';
import recruiment_header_cn from '../../assets/img/recruiment_header_cn.png';

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        marginBottom: '80px'
    },
    container: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
    },
});

function Recruitment(props) {
    const { classes } = props;
    const lang = Language.CurLanguage();

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <img src={
                    "English" === lang ? recruiment_header_en  : recruiment_header_cn
                } alt="" style={{
                    width: 'apx(1200)',
                    height: apx(572),
                    marginTop: '100px'
                }} />
                {
                    Language.Recruitment.Job[lang].map((item, index, arr) => (
                        <Typography style={{
                            width: '80%',
                            marginTop: '56px',
                            textAlign: 'left'
                        }}>
                            <Typography style={{
                                fontSize: apx(32),
                                color: 'rgba(11,16,60,1)',
                                foneWeight: 800
                            }}>
                                {item.job}
                            </Typography>
                            {
                                item.detail.map((item, index, arr) => (
                                    <>
                                        <Typography style={{
                                            marginTop: '24px',
                                            fontSize: '18px',
                                            foneWeight: 500,
                                            color: 'rgba(11,16,60,1)'
                                        }}>
                                            {item.title}
                                        </Typography>
                                        {
                                            item.detail.map(item => (
                                                <Typography style={{
                                                    marginTop: '16px',
                                                    fontSize: '16px',
                                                    foneWeight: 400,
                                                    color: 'rgba(90,90,90,1)'
                                                }}>
                                                    {item}
                                                </Typography>
                                            ))
                                        }
                                    </>
                                ))
                            }

                            <Link
                                underline="none"
                                href="mailto:contact@riodefi.com"
                            >

                                <Typography
                                    style={{
                                        marginTop: '24px',
                                        padding: '8px 24px',
                                        textAlign: 'center',
                                        borderRadius: apx(4),
                                        fontSize: '16px',
                                        display:'inline-block',
                                        fontWeight: 500,
                                        border: '1px solid rgba(11,16,60,1)'
                                    }}>
                                    {Language.Recruitment.Resume[lang]}
                                </Typography>
                            </Link>
                        </Typography>
                    ))
                }

            </Container>
        </section>
    );
}

Recruitment.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Recruitment);
