import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Container from '@material-ui/core/Container';
// import Typography from '../components/Typography';
// import { Fade } from 'react-reveal'
// import { Link } from '@material-ui/core'
// import Language from '../common/Language';


import PartnersComp from '../../mobile/components/Partners'


function Partners(props) {
    // const { classes } = props;

    return (
        <section id="Partners">
            <PartnersComp/>
        </section>
    );
}

Partners.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default Partners;
