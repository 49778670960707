// --- Post bootstrap -----
import React, {useState, useEffect} from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";
import { message as AntMessage } from 'antd'
import { Button } from "@material-ui/core";
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const SubscribeForm = ({ status, message, onValidated }) => {
  const lang = Language.CurLanguage();
  const [email, setEmail] = useState(null);

  useEffect(() => {
    if (status === 'sending') {
      AntMessage.info(Language.Home.Subscribing.Btn[lang] + '...');
    }
    if (status === 'success') {
      AntMessage.success(Language.Home.Subscribe.Success[lang]);
    }
    if (status === 'error') {
      AntMessage.error(Language.Home.Subscribe.Error[lang]);
    }
  }, [status, lang])

  const submit = () => {
    if (!email || (email && email.indexOf('@') === -1)) {
      AntMessage.error('Email address not valid!');
      return false;
    }
    onValidated({
      EMAIL: email
    })
  }

  return (
    <div
      className={"row"}
      style={{ marginTop: apx(40), marginBottom: apx(100) }}
    >
      <input
        className={"box-sizing"}
        onChange={event => { setEmail(event.target.value) }}
        style={{
          fontSize: apx(22),
          width: apx(450),
          height: apx(76),
          border: `1px solid #979797`,
          paddingLeft: apx(25),
          borderRadius: apx(10),
          marginRight: apx(20)
        }}
        placeholder={Language.Home.Subscribe.Name[lang]}
      />

      <Button onClick={submit} style={{padding: 0, textTransform:'none'}}>
        <div
          className={"column center"}
          style={{
            width: apx(220),
            height: apx(76),
            borderRadius: apx(10),
            backgroundColor: "#0B103C",
            boxShadow: `0px ${apx(20)} ${apx(60)} ${apx(
              -10
            )} rgba(11,16,60,0.5)`
          }}
        >
          <span style={{ fontSize: apx(26), color: "#FFFFFF" }}>
            {Language.Home.Subscribe.Btn[lang]}
          </span>
        </div>
      </Button>
    </div>
  )
}


class Index extends React.Component {

  render() {
    const lang = Language.CurLanguage();
    const subscribeUrl = '//riochain.us19.list-manage.com/subscribe/post?u=ad224be546fae87258d90ca8a&amp;id=62db27dbbc'

    return (
      <div
        className={"column"}
        style={{ paddingLeft: apx(30), paddingRight: apx(30) }}
      >
        <span
          style={{
            fontSize: apx(36),
            color: "#0B103C",
            width: apx(499),
            lineHeight: apx(50),
            marginTop: apx(100)
          }}
        >
          {Language.Home.Subscribe.Title[lang]}
        </span>
        <MailchimpSubscribe url={subscribeUrl} render={({ subscribe, status, message }) => (
          <SubscribeForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )} />

        {/* <div
          className={"row"}
          style={{ marginTop: apx(40), marginBottom: apx(100) }}
        >
          <input
            className={"box-sizing"}
            style={{
              fontSize: apx(22),
              width: apx(450),
              height: apx(76),
              border: `1px solid #979797`,
              paddingLeft: apx(25),
              borderRadius: apx(10),
              marginRight: apx(20)
            }}
            placeholder={Language.Home.Subscribe.Name[lang]}
          />

          <Link href="#">
            <div
              className={"column center"}
              style={{
                width: apx(220),
                height: apx(76),
                borderRadius: apx(10),
                backgroundColor: "#0B103C",
                boxShadow: `0px ${apx(20)} ${apx(60)} ${apx(
                  -10
                )} rgba(11,16,60,0.5)`
              }}
            >
              <span style={{ fontSize: apx(26), color: "#FFFFFF" }}>
                {Language.Home.Subscribe.Btn[lang]}
              </span>
            </div>
          </Link>
        </div> */}
      </div>
    );
  }
}

export default Index;
