// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";

class Index extends React.Component {
  state = {};

  componentDidMount() {}

  render() {
    const lang = Language.CurLanguage();

    return (
      <div className={"column center"} style={{ backgroundColor: "#ffffff", marginBottom: '56px' }}>
        <span
         dangerouslySetInnerHTML={{__html: Language.Home.Arch.Title[lang]}}
          style={{
            fontSize: apx(36),
            color: '#002060',
            padding: '8px 18px',
            fontWeight: "bolder",
            marginTop: apx(40)
          }}
        >
        </span>

        <div
          className={"row"}
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: apx(60)
          }}
        >
          <img
            src={require("../assets/Arch1.svg")}
            style={{
              width: apx(90),
              height: apx(90),
              marginTop: apx(16),
              marginRight: apx(30)
            }}
            alt=""
          />
          <div className={"column"}>
            <span
              style={{
                width: apx(500),
                fontSize: apx(26),
                color: "#4E87F7",
                fontWeight: "bold"
              }}
            >
              {Language.Home.Arch.Hybrid[lang].Title}
            </span>
            <span
              style={{
                width: apx(500),
                fontSize: apx(22),
                color: "#5A5A5A",
                lineHeight: apx(30),
                marginTop: apx(8)
              }}
            >
              {Language.Home.Arch.Hybrid[lang].Content}
            </span>
          </div>
        </div>

        <div
          className={"row"}
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: apx(60)
          }}
        >
          <img
            src={require("../assets/Arch2.svg")}
            style={{
              width: apx(90),
              height: apx(90),
              marginTop: apx(16),
              marginRight: apx(30)
            }}
            alt=""
          />
          <div className={"column"}>
            <span
              style={{
                width: apx(500),
                fontSize: apx(26),
                color: "#4E87F7",
                fontWeight: "bold"
              }}
            >
              {Language.Home.Arch.Flexible[lang].Title}
            </span>

            {lang === "English" && (
              <span
                style={{
                  width: apx(500),
                  fontSize: apx(22),
                  color: "#5A5A5A",
                  lineHeight: apx(30),
                  marginTop: apx(8)
                }}
              >
                - Virtual Machine Interpreter supports Web Assembly
                <br />
                - Light client requirements
                <br />- Customizable consensus algorithms
              </span>
            )} 
            {lang === '中文' && (
              <span
                style={{
                  width: apx(500),
                  fontSize: apx(22),
                  color: "#5A5A5A",
                  lineHeight: apx(30),
                  marginTop: apx(8)
                }}
              >
                - 虚拟机解释器支持Web Assembly
                <br />
                - 轻客户端的要求
                <br />- 可定制的共识算法
              </span>
            )}
             {lang === "Spanish" && (
              <span
                style={{
                  width: apx(500),
                  fontSize: apx(22),
                  color: "#5A5A5A",
                  lineHeight: apx(30),
                  marginTop: apx(8)
                }}
              >
                - El intérprete de la máquina virtual es compatible con Web Assembly
                <br />
                - Requerimientos de clientes ligeros
                <br />- Algoritmos de consenso personalizables
              </span>
            )} 
          </div>
        </div>

        <div
          className={"row"}
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: apx(60)
          }}
        >
          <img
            src={require("../assets/Arch3.svg")}
            style={{
              width: apx(90),
              height: apx(90),
              marginTop: apx(16),
              marginRight: apx(30)
            }}
            alt=""
          />
          <div className={"column"}>
            <span
              style={{
                width: apx(500),
                fontSize: apx(26),
                color: "#4E87F7",
                fontWeight: "bold"
              }}
            >
              {Language.Home.Arch.Interoperability[lang].Title}
            </span>
            <span
              style={{
                width: apx(500),
                fontSize: apx(22),
                color: "#5A5A5A",
                lineHeight: apx(30),
                marginTop: apx(8)
              }}
            >
              {Language.Home.Arch.Interoperability[lang].Content}
            </span>
          </div>
        </div>

        {/* <img
          src={require("../assets/Arch_icon.svg")}
          style={{
            width: apx(438),
            height: apx(438),
            marginTop: apx(85),
            marginBottom: apx(70)
          }}

          alt=""
        /> */}
      </div>
    );
  }
}

export default Index;
