import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Link } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import CalvinHead from "../../assets/img/calvin_head.svg";
import EkaterinaHead from "../../assets/img/ekaterina_head.svg";
import SunnyHead from "../../assets/img/team_sunny.png";
import ShahenHead from "../../assets/img/team_shahen.png";
import ArguHead from "../../assets/img/team_argu.png";
import StacieHead from "../../assets/img/team_stacie.png";
import PhyrexHead from "../../assets/img/team_phyrex.png";
import WillHead from "../../assets/img/team_will.png";
import JohnHead from "../../assets/img/team_john.png";
// import BillyHead from "../../assets/img/team_billy.png";
// import VideoThumb3 from '../../assets/img/video-thumb-3.png';


import HarrisonHead from "../../assets/img/team_harrison.png";
// import TerenceHead from "../../assets/img/terence_head.jpeg";
import GinoHead from "../../assets/img/gino_head.svg";
import JamesHead from "../../assets/img/james_head.svg";
import StephaneHead from "../../assets/img/stephane_head.svg";
import InLogo from "../../assets/img/in_logo.png";
import Language from '../common/Language';
// import ReactPlayer from 'react-player';

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        minWidth: '1280px',
        backgroundColor: 'rgba(249,249,249,1)',
    },
    container: {
        marginTop: '72px',
        marginBottom: '80px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 5),
    },
    title: {
        fontSize: '66px',
        fontWeight: 500,
        width: '594px',
        color: 'rgba(11,16,60,1)',
        lineHeight: '92px',
        textAlign: 'center',
        // fontFamily: 'PingFangSC-Medium,PingFang SC;'
    },
    advisorTitle: {
        fontSize: '66px',
        fontWeight: 500,
        width: '594px',
        color: 'rgba(11,16,60,1)',
        lineHeight: '92px',
        textAlign: 'center',
        marginTop: '52px',
        // fontFamily: 'PingFangSC-Medium,PingFang SC;'
    },
    question_text: {
        marginTop: '36px',
        width: '621px',
        fontSize: '16px',
        fontWeight: 500,
        color: 'rgba(90,90,90,1)',
        lineHeight: '22px',
        textAlign: 'center',
        // fontFamily: 'PingFangSC-Medium,PingFang SC;'
    },
    video: {
        margin: '96px auto 0',
    },
    grid_root1: {
        marginTop: '40px'
    },
    grid_root20: {
        marginTop: '20px'
    },
    grid_root: {
        marginTop: '60px'
    },
    name: {
        fontSize: '26px',
        fontWeight: 500,
        color: 'rgba(0,0,0,1)',
        lineHeight: '27px',
        alignItems: 'center',
        // fontFamily: 'PingFangSC-Medium,PingFang SC;'
    },
    contact_icon: {
        height: '15px',
        width: '15px',
        marginLeft: '8px'
    },
    major: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#5485E7',
        lineHeight: '37px',
        textTransform: 'uppercase'
        // fontFamily: 'PingFangSC-Medium,PingFang SC;'
    },
    avatar: {
        borderRadius: '100%',
        width: '100px',
    },
    interduction: {
        fontSize: '16px',
        fontWeight: 500,
        width: '352px',
        marginTop: '10px',
        lineHeight: '22px',
        color: 'rgba(90,90,90,1)',
        // fontFamily: 'PingFangSC-Medium,PingFang SC;'
    }
});

function Ourteam(props) {
    const { classes } = props;
    const lang = Language.CurLanguage();

    return (
        <section id="ourteam" className={classes.root}>
            <Container className={classes.container}>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={12}>
                        <h2 className={classes.item}>
                            <Typography className={classes.title}>
                                {Language.About.Team.Title[lang]}
                            </Typography>
                            <Typography className={classes.question_text}>
                                {Language.About.Team.Desc[lang]}
                            </Typography>
                        </h2>
                    </Grid>
                </Grid>
                <Grid container spacing={0} className={classes.grid_root1}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{
                                textAlign: 'right',
                            }}>
                                <img src={JamesHead} alt="" />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.James.Name[lang]}<Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://www.linkedin.com/in/jamesanderson88/" alt=""><img className={classes.contact_icon} src={InLogo} alt="" /></Link>
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.James.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.James.Inter1[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.James.Inter2[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={2}>
                                <img src={CalvinHead} alt="" />
                            </Grid>
                            <Grid item xs={12} md={10} style={{
                                paddingLeft: '25px'
                            }}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.Calvin.Name[lang]}<Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://www.linkedin.com/in/dragoncalvin/" alt=""><img className={classes.contact_icon} src={InLogo} alt="" /></Link>
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.Calvin.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Calvin.Inter1[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Calvin.Inter2[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={0} className={classes.grid_root}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{
                                textAlign: 'right',
                            }}>
                                <img src={StephaneHead} alt="" />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.Stephane.Name[lang]}<Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://www.linkedin.com/in/stephane-laurent-villedieu-%E7%BD%97%E6%80%9D%E5%87%A1-1b6ab769/" alt=""><img className={classes.contact_icon} src={InLogo} alt="" /></Link>
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.Stephane.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Stephane.Inter1[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Stephane.Inter2[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={2} style={{
                                textAlign: 'right',
                            }}>
                                <img src={EkaterinaHead} alt="" />
                            </Grid>
                            <Grid item xs={12} md={10} style={{
                                paddingLeft: '25px'
                            }}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.Ekaterina.Name[lang]}<Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://www.linkedin.com/mwlite/in/ekaterina-volkova-397b6261" alt=""><img className={classes.contact_icon} src={InLogo} alt="" /></Link>
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.Ekaterina.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Ekaterina.Inter1[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Ekaterina.Inter2[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={0} className={classes.grid_root}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{
                                textAlign: 'right',
                            }}>
                                <img src={ArguHead} alt="" className={classes.avatar} />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.Argu.Name[lang]}
                                    {/* <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://www.linkedin.com/mwlite/in/ekaterina-volkova-397b6261" alt=""><img className={classes.contact_icon} src={InLogo} alt="" /></Link> */}
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.Argu.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Argu.Inter1[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Argu.Inter2[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={2}>
                                <img src={PhyrexHead} alt="" className={classes.avatar} />
                            </Grid>
                            <Grid item xs={12} md={10} style={{
                                paddingLeft: '25px'
                            }}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.Phyrex.Name[lang]}
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.Phyrex.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Phyrex.Inter1[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={0} className={classes.grid_root20}>

                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{
                                textAlign: 'right',
                            }}>
                                <img src={StacieHead} alt="" className={classes.avatar} />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.Stacie.Name[lang]}
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.Stacie.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Stacie.Inter1[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={2}>
                                <img src={HarrisonHead} alt="" className={classes.avatar} />
                            </Grid>
                            <Grid item xs={12} md={10} style={{
                                paddingLeft: '25px'
                            }}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.Harrison.Name[lang]}
                                    {/* <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://www.linkedin.com/in/starit/" alt=""><img className={classes.contact_icon} src={InLogo} alt="" /></Link> */}
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.Harrison.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Harrison.Inter1[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid container spacing={0} className={classes.grid_root}>
                   
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{
                                textAlign: 'right',
                            }}>
                                <img src={ShahenHead} alt="" className={classes.avatar} />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.Shahen.Name[lang]}
                                    {/* <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://www.linkedin.com/mwlite/in/ekaterina-volkova-397b6261" alt=""><img className={classes.contact_icon} src={InLogo} alt="" /></Link> */}
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.Shahen.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Shahen.Inter1[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={2}>
                                <img src={BillyHead} alt="" className={classes.avatar} />
                            </Grid>
                            <Grid item xs={12} md={10} style={{
                                paddingLeft: '25px'
                            }}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.Billy.Name[lang]}
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.Billy.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Billy.Inter1[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={12}>
                        <h2 className={classes.item}>
                            <Typography className={classes.advisorTitle}>
                                {Language.About.Advisors.Title[lang]}
                            </Typography>
                            {/* <Typography className={classes.question_text}>
                                {Language.About.Team.Desc[lang]}
                            </Typography> */}
                        </h2>
                    </Grid>
                </Grid>
                <Grid container spacing={0} className={classes.grid_root20}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{
                                textAlign: 'right',
                            }}>
                                <img src={GinoHead} alt="" />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.Gino.Name[lang]}<Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://www.linkedin.com/mwlite/in/ginoyu" alt=""><img className={classes.contact_icon} src={InLogo} alt="" /></Link>
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.Gino.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Gino.Inter1[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Gino.Inter2[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={2}>
                                <img src={SunnyHead} alt="" className={classes.avatar} />
                            </Grid>
                            <Grid item xs={12} md={10} style={{
                                paddingLeft: '25px'
                            }}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.Sunny.Name[lang]}
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.Sunny.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Sunny.Inter1[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={0} className={classes.grid_root20}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} style={{
                                textAlign: 'right',
                            }}>
                                <img src={WillHead} alt="" className={classes.avatar} />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.Will.Name[lang]}
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.Will.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.Will.Inter1[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={2}>
                                <img src={JohnHead} alt="" className={classes.avatar} />
                            </Grid>
                            <Grid item xs={12} md={10} style={{
                                paddingLeft: '25px'
                            }}>
                                <Typography className={classes.name}>
                                    {Language.About.Team.Member.John.Name[lang]}
                                </Typography>
                                <Typography className={classes.major}>
                                    {Language.About.Team.Member.John.Major[lang]}
                                </Typography>
                                <Typography className={classes.interduction}>
                                    {Language.About.Team.Member.John.Inter1[lang]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <ReactPlayer light={VideoThumb3} controls className={classes.video} url="https://media.riochain.io/why-rio.mov" /> */}
            </Container>


        </section>
    );
}

Ourteam.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Ourteam);
