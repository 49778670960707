import withRoot from "./modules/withRoot";
// --- Post bootstrap -----
import React from "react";
import AppAppBar from "./modules/views/AppAppBar";
import Divider from "@material-ui/core/Divider";
import AppFooter from "./modules/views/AppFooter";
import Simplex from "./mobile/views/Simplex";
// function Index() {
//   return (
//     <React.Fragment>
//       <AppAppBar wallet="1"/>
//       <Divider />
//       <ProductWallet />
//       <AppFooter />
//     </React.Fragment>
//   );
// }

const iframeWrapperStyle = {
  // background: "url('/moonpay-bg.png') no-repeat",
  backgroundSize: "100% 100%",
  padding: "88px 0",
  textAlign: "center",
};

const iframeStyle = {
  width: "500px",
  height: "700px",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  border: 0,
  overflow: "hidden",
  background: "#fff",
};

class Index extends React.Component {
  IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod",
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  componentDidMount() {
    if (document.getElementById("simplex-form")) {
      const trackScript = document.createElement("script");
      trackScript.src = "https://checkout.simplexcc.com/splx.js";
      document.body.appendChild(trackScript);

      const script = document.createElement("script");
      script.src = "https://iframe.simplex-affiliates.com/form.js";
      document.body.appendChild(script);


      const script2 = document.createElement("script");
      script2.src = "/handle-simplex.js";
      document.body.appendChild(script2);

    }
  }

  render() {
    return true === this.IsPC() ? (
      <React.Fragment>
        <AppAppBar moonpay="1" />
        <Divider />
        <div style={iframeWrapperStyle}>
          <div id="simplex-form"></div>
        </div>
        <AppFooter />
      </React.Fragment>
    ) : (
      <Simplex />
    );
  }
}
export default withRoot(Index);
