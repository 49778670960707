import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '../components/Typography';
import { apx } from "../../themes/util";
import Language from '../common/Language';


const styles = theme => ({
    root: {
        marginBottom: '80px',
    },
    container: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
    },
    heading: {
        fontSize: apx(16),
        color: '#111760',
    },
    expandPanel: {
        width: '100%'
    },
    expandIcon: {
        fontSize: apx(32)
    },
    sectionTitle: {
        color: '#717F99',
        margin: '30px 0 20px',
        textAlign: 'left',
        fontSize: '0.4rem'
    },
    title: {
        padding: '132px 0',
        background: 'rgba(11,16,60,1)',
        fontSize: apx(50),
        textAlign: 'center',
        // width: apx(884),
        fontWeight: apx(600),
        lineHeight: apx(92),
        color: '#fff',
    },
    // titlecon: {
    //     borderRadius: apx(10),
    //     width: apx(660),
    //     height: apx(200),
    //     backgroundColor: 'rgba(247,248,249,1)',
    //     display: 'flex',
    //     position: 'relative',
    //     flexDirection: 'column',
    //     alignItems: 'left',
    // },
});

function ProductFaq(props) {
    const { classes } = props;
    const lang = Language.CurLanguage();

    return (
        <section className={classes.root}>
            <Typography className={classes.title}>
                {Language.ProductFaq.Title[lang]}
            </Typography>
            <Container className={classes.container}>
                <h1>
                    <Typography className={classes.sectionTitle}>
                        RioChain
                    </Typography>
                </h1>
                {Language.ProductFaq.RioChain[lang].map((item, index, arr) => (
                    <ExpansionPanel className={classes.expandPanel}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
                            <Typography className={classes.heading}>{item.title}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
                <Typography className={classes.sectionTitle}>
                    {Language.ProductFaq.GeneralTitle[lang]}
                </Typography>
                {Language.ProductFaq.General[lang].map((item, index, arr) => (
                    <ExpansionPanel className={classes.expandPanel}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
                            <Typography className={classes.heading}>{item.title}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
            </Container>
        </section>
    );
}

ProductFaq.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductFaq);
