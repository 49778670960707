import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { Dropdown, Menu } from "antd";
import AppBar from "../components/AppBar";
import Toolbar, { styles as toolbarStyles } from "../components/Toolbar";
import Logo from "../../assets/img/rio_logo.svg";
import English from "../../assets/img/english.png";
import Chinese from "../../assets/img/chinese.png";
// import Vietnam from "../../assets/img/vietnam.svg";
import Spanish from "../../assets/img/spain.svg";
import DropIcon from "../../assets/img/drop_icon.png";
import { Grid, Paper, Popover, Typography } from "@material-ui/core";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Language from "../common/Language";

const styles = (theme) => ({
  logo: {
    marginLeft: "50px",
  },
  logoImg: {
    width: "160px",
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    backgroundColor: "rgba(255,255,255,1)",
    justifyContent: "space-between",
    boxShadow: "0px 2px 10px 0px rgba(240,240,240,1)",
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  rightLink: {
    fontSize: "16px",
    fontWeight: 500,
    color: "rgba(11,16,60,1)",
    // fontFamily: 'PingFangSC-Medium,PingFang SC;',
    marginLeft: "35px",
    lineHeight: "46px",
  },
  rightLink1: {
    fontSize: "16px",
    fontWeight: 500,
    color: "rgba(90,90,90,1)",
    // fontFamily: 'PingFangSC-Medium,PingFang SC;',
    marginLeft: "35px",
    lineHeight: "46px",
  },
  langLink: {
    borderRadius: 25,
    border: 2,
    paddingLeft: 15,
    paddingRight: 25,
    textAlign: "center",
    marginRight: "50px",
    backgroundColor: "rgba(243,247,255,1)",
    fontSize: "16px",
    fontWeight: 500,
    color: "rgba(11,16,60,1)",
    // fontFamily: 'PingFangSC-Medium,PingFang SC;',
    marginLeft: "35px",
    lineHeight: "46px",
  },
  lang_content: {
    display: "inline-block",
    whiteSpace: "nowrap",
    // paddingRight: '15px',
    color: "#000000",
  },
  lang_item: {
    display: "flex",
    flexDirection: "row",
    padding: "5px",
    cursor: "pointer",
  },
});

function AppAppBar(props) {
  const { classes, about, block, wallet, moonpay } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lang, setLang] = React.useState(Language.CurLanguage());

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const changeEnglish = () => {
    localStorage.setItem("language", "English");
    document.location.reload();
  };

  const changeChinses = () => {
    localStorage.setItem("language", "中文");
    document.location.reload();
  };

  const changeSpanish = () => {
    localStorage.setItem("language", "Spanish");
    document.location.reload();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const buyMenu = (
    <Menu>
      <Menu.Item>
        <Link underline="none" href="/moonpay">
          {Language.Nav.PurchaseMoonpay[lang]}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link underline="none" href="/simplex">
          {Language.Nav.PurchaseSimplex[lang]}
        </Link>
      </Menu.Item>
    </Menu>
  );

  const whitePaperLink =
    lang !== "中文"
      ? "/Rio_DeFi_Whitepaper_English.pdf"
      : "/Rio_DeFi_Whitepaper_Chinese.pdf";

  React.useEffect(() => {
    var temp = null;
    temp = localStorage.getItem("language");
    if ("null" === temp || "" === temp) {
      temp = "English";
    }

    localStorage.setItem("language", temp);
    setLang(temp);
  }, [setLang]);

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <Link
            underline="none"
            color="inherit"
            className={classes.logo}
            href="/"
            alt=""
          >
            <img src={Logo} className={classes.logoImg} alt="" />
          </Link>
          <div className={classes.right}>
            <Dropdown overlay={buyMenu}>
              <Link
                underline="none"
                className={
                  moonpay != null ? classes.rightLink : classes.rightLink1
                }
                // href="/moonpay"
              >
                {Language.Nav.Purchase[lang]}
              </Link>
            </Dropdown>

            {null != about ? (
              <Link
                underline="none"
                className={classes.rightLink}
                href="/about"
              >
                {Language.Nav.About[lang]}
              </Link>
            ) : (
              <Link
                underline="none"
                className={classes.rightLink1}
                href="/about"
              >
                {Language.Nav.About[lang]}
              </Link>
            )}

            {null != block ? (
              <Link
                underline="none"
                target="_blank"
                rel="noreferrer noopener nofollow"
                className={classes.rightLink}
                href="https://scan.v1.riochain.io/"
              >
                {Language.Nav.Block[lang]}
              </Link>
            ) : (
              <Link
                underline="none"
                target="_blank"
                rel="noreferrer noopener nofollow"
                className={classes.rightLink1}
                href="https://scan.v1.riochain.io/"
              >
                {Language.Nav.Block[lang]}
              </Link>
            )}

            {null != wallet ? (
              <Link
                underline="none"
                className={classes.rightLink}
                href="/wallet"
              >
                {Language.Nav.Wallet[lang]}
              </Link>
            ) : (
              <Link
                underline="none"
                className={classes.rightLink1}
                href="/wallet"
              >
                {Language.Nav.Wallet[lang]}
              </Link>
            )}
            {
              <Link
                underline="none"
                className={classes.rightLink1}
                href={whitePaperLink}
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                {Language.Nav.Whitepaper[lang]}
              </Link>
            }
            <Link
              underline="none"
              className={classes.langLink}
              aria-describedby={id}
              onClick={handleClick}
            >
              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                  <div>
                    <Grid container spacing={0} {...bindTrigger(popupState)}>
                      <Grid item md={5}>
                        <span className={classes.lang_content}>
                          <img
                            src={
                              lang === "English"
                                ? English
                                : lang === "Spanish"
                                ? Spanish
                                : Chinese
                            }
                            style={{
                              marginBottom: "0px",
                              height: "19.9px",
                              width: "19.9px",
                            }}
                            alt=""
                          />
                        </span>
                      </Grid>
                      <Grid item md={7}>
                        <span className={classes.lang_content}>
                          {lang}
                          <img
                            src={DropIcon}
                            style={{
                              marginBottom: "0px",
                              height: "19.9px",
                              width: "19.9px",
                            }}
                            alt=""
                          />
                        </span>
                      </Grid>
                    </Grid>
                    {/* </Button> */}
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Paper
                        style={{
                          padding: "5px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className={classes.lang_item}
                          data="value"
                          onClick={changeEnglish}
                        >
                          <Grid container spacing={1}>
                            <Grid item md={4}>
                              <img
                                src={English}
                                style={{ height: "19.9px", width: "19.9px" }}
                                alt=""
                              />
                            </Grid>
                            <Grid item md={8}>
                              <Typography>{"English"}</Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <div
                          className={classes.lang_item}
                          onClick={changeChinses}
                        >
                          <Grid container spacing={1}>
                            <Grid item md={4}>
                              <img
                                src={Chinese}
                                style={{ height: "19.9px", width: "19.9px" }}
                                alt=""
                              />
                            </Grid>
                            <Grid item md={8}>
                              <Typography>{"中文"}</Typography>
                            </Grid>
                          </Grid>
                        </div>

                        <div
                          className={classes.lang_item}
                          onClick={changeSpanish}
                        >
                          <Grid container spacing={1}>
                            <Grid item md={4}>
                              <img
                                src={Spanish}
                                style={{ height: "19.9px", width: "19.9px" }}
                                alt=""
                              />
                            </Grid>
                            <Grid item md={8}>
                              <Typography>Spanish</Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </Paper>
                    </Popover>
                  </div>
                )}
              </PopupState>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
