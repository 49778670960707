import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppAppBar from './modules/views/AppAppBar';
import Divider from '@material-ui/core/Divider';
import ProductWallet from './modules/views/ProductWallet';
import AppFooter from './modules/views/AppFooter';
import MWallet from "./mobile/views/Wallet";
// function Index() {
//   return (
//     <React.Fragment>
//       <AppAppBar wallet="1"/>
//       <Divider />
//       <ProductWallet />
//       <AppFooter />
//     </React.Fragment>
//   );
// }

class Index extends React.Component {
  IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod"
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  render() {
    return ((true === this.IsPC() ? (
      <React.Fragment>
        <AppAppBar wallet="1" />
        <Divider />
        <ProductWallet />
        <AppFooter />
      </React.Fragment>
    ) : (
        <MWallet />
      )
    )
    );
  }
}
export default withRoot(Index);
