import withRoot from "../../modules/withRoot";
// --- Post bootstrap -----
import React, { useEffect, useState } from "react";
import AppAppBar from "../../modules/views/AppAppBar";
import Divider from "@material-ui/core/Divider";
import MobileLayout from "../../mobile/components/Layout";
import MobileFooter from "../../mobile/components/MobileFooter";
import AppCardFooter from "../../modules/views/AppCardFooter";
import ArrowRight from "../../assets/img/card/arrow-right.svg";
import BannerCard from "../../assets/img/card/banner-card.jpg";
import Feature1 from "../../assets/img/card/feature-1.svg";
import Feature2 from "../../assets/img/card/feature-2.svg";
import Feature3 from "../../assets/img/card/feature-3.svg";
import IconAdd from "../../assets/img/card/add.svg";
import IconMinus from "../../assets/img/card/minus.svg";
import Tokens from "../../assets/img/card/tokens.png";
import style from "./style.module.scss";
import WhitelistModal from "../../modules/modals/WhitelistModal";

const faqList = [
  {
    q: `Is RioCard is a debit or credit card?`,
    a: `RioCard is a prepaid card. Broadly speaking, prepaid cards are the same as debit cards. The difference is that debit cards are linked to your bank account, but prepaid cards need to be topped up. In our case, you can top up using bank account transfers, other credit/debit cards, or cryptocurrency.`,
  },
  {
    q: `How do I top up my debit card?`,
    a: `On RioChain, do a withdrawal transaction to your Rio Card wallet. On Other chains, do a Send transaction to your Rio Card wallet.`,
  },
  {
    q: `What are the associated card fees?`,
    a: `monthly card fee - 0.8 <br/>
    Card loading fee  - 2%<br/>
    Card Launch fee - 1.5<br/ >
    Card Reissue/Replacement Fee - TBD<br/ >
    New Virtual Card Fee - TBD<br/ >
    PIN Reminder Fee - TBD<br/ >
    Card Cancellation Fee - TBD<br/ >
    Residency Fee - TBD<br/ >
    ATM transaction fee - TBD<br/ >
    Retail transaction fee - TBD<br/ >
    PIN Change at Domestic ATM - € 1.00<br/ >
    PIN Change at Foreign ATM - € 1.00<br/ >
    Balance Checking at Domestic ATM - € 0.35<br/ >
    Balance Checking at Foreign ATM - € 0.35<br/ >
    Declined Transaction at Domestic ATM - € 0.00<br/ >
    Declined Transaction at Foreign ATM - € 0.00<br/ >
    Cancellation of recurring transaction - € 5.00<br/ >
    Disputed transactions - € 35.00<br/ >
    `,
  },
  {
    q: `In which countries can I use the card?`,
    a: `Every country where Mastercard is accepted.`,
  },
  {
    q: `What is the KYC process?`,
    a: `Email<br/ >
    Password<br/ >
    - 2FA<br/ >
    Country of residence<br/ >
    Date of birth<br/ >
    Nationality<br/ >
    Address details (psotcode, address)<br/ >
    KYC verification with Verif:<br/ >
    Phone number<br/ >
    SMS received with URL <br/ >
    Identity document (Passport, National ID)`,
  },
  {
    q: `What cryptocurrencies can I use for purchases?`,
    a: `BTC - USDT - RFUEL - USDC - XRP - XTZ - BXX - BCH - LTC`,
  },
];

export default withRoot(function CardPage() {
  const [whitelistModalVisible, setWhitelistModalVisible] = useState(false);
  const [isPC, setIsPC] = useState(false);
  const [active, setActive] = useState(0);

  useEffect(() => {
    window.scroll(0, 0);

    var userAgentInfo = navigator.userAgent;
    var Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod",
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    setIsPC(flag);
  }, []);

  const MainContent = () => (
    <>
      <div className={style.cardPage}>
        <div className={style.banner}>
          <div>
            <div className={style.title}>
              Spend Your Crypto with{" "}
              <span className={style.highlight}>40,000,000</span> Global
              Merchants.
            </div>
            <a
              className={style.btnBlue}
              onClick={() => setWhitelistModalVisible(true)}
            >
              Get Whitelisted <img src={ArrowRight} />
            </a>
          </div>
          <img src={BannerCard} className={style.bannerCard} />
        </div>
        <div className={style.analytics}>
          <div className={style.item}>
            <div className={style.top}>Only</div>
            <div className={style.num}>1</div>
            <div className={style.bottom}>Card</div>
          </div>
          <div className={style.item}>
            <div className={style.top}>Accepted in</div>
            <div className={style.num}>210</div>
            <div className={style.bottom}>Countries</div>
          </div>
          <div className={style.item}>
            <div className={style.top}>Accepted by</div>
            <div className={style.num}>40 M</div>
            <div className={style.bottom}>Merchants</div>
          </div>
        </div>
        <div className={style.features}>
          <div className={style.title}>
            Crypto Debit card for
            <br /> <span className={style.highlight}>the world.</span>
          </div>
          <div className={style.featureList}>
            <div className={style.item}>
              <div className={style.iconWrapper}>
                <img src={Feature1} />
              </div>
              <div className={style.desc}>
                Top up and manage your card easly and securly through RioWallet
                app.
              </div>
            </div>
            <div className={style.item}>
              <div className={style.iconWrapper}>
                <img src={Feature2} />
              </div>
              <div className={style.desc}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </div>
            <div className={style.item}>
              <div className={style.iconWrapper}>
                <img src={Feature3} />
              </div>
              <div className={style.desc}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </div>
          </div>
        </div>
        <div className={style.purchase}>
          <div className={style.left}>
            <div className={style.title}>
              Use your crypto for{" "}
              <span className={style.highlight}>everyday purchases.</span>
            </div>
            <div className={style.desc}>
              Supported cryptocurrencies on RioCard.
            </div>
          </div>
          <img src={Tokens} className={style.tokens} />
        </div>
        <div className={style.faq}>
          <div className={style.title}>FAQ</div>
          {faqList.map((item, index) => (
            <div className={style.item}>
              <div
                className={style.q}
                onClick={() =>
                  setActive((prev) => (prev === index ? null : index))
                }
              >
                <div dangerouslySetInnerHTML={{ __html: item.q }} />
                <img
                  src={index === active ? IconMinus : IconAdd}
                  className={style.icon}
                />
              </div>
              {index === active && (
                <div
                  className={style.a}
                  dangerouslySetInnerHTML={{ __html: item.a }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      {whitelistModalVisible && (
        <WhitelistModal onCancel={() => setWhitelistModalVisible(false)} />
      )}
    </>
  );

  return (
    <React.Fragment>
      {isPC ? (
        <>
          <AppAppBar card="1" />
          <Divider />
          <MainContent />
          <AppCardFooter />
        </>
      ) : (
        <MobileLayout>
          <MainContent />
          <MobileFooter />
        </MobileLayout>
      )}
    </React.Fragment>
  );
});
