// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";
import { Link } from "@material-ui/core";

class Index extends React.Component {
    state = {};

    componentDidMount() { }

    render() {
        const lang = Language.CurLanguage();


        return (
            <div className={"column"} style={{ alignItems: 'center', backgroundColor: 'rgba(255,255,255,1)' }}>
                <Link
                href="/recruit"
                underline="none">
                    {
                        "中文" !== lang ? (
<img
                    src={require("../assets/career_en.svg")}
                    style={{ width: apx(750), height: apx(400) }}
                    alt=""
                />
                        ): (
<img
                    src={require("../assets/career_cn.svg")}
                    style={{ width: apx(750), height: apx(400) }}
                    alt=""
                />
                        )
                    }
                
                </Link>
                
                {/* <div
                    className={"column"}
                    style={{ position: "absolute", top: apx(60), left: apx(30) }}
                >
                    <span
                        style={{
                            width: apx(326),
                            color: "#0B103C",
                            fontSize: apx(37),
                            lineHeight: apx(37),
                            fontWeight: "600",
                            marginLeft: apx(50)
                        }}
                    >
                        {Language.About.Careers.Title[lang]}
                    </span>

                    <div
                        className={"column"}
                        style={{ position: "absolute", top: apx(60), left: apx(30) }}
                    >
                        <span
                            style={{
                                width: apx(326),
                                color: "#0B103C",
                                fontSize: apx(26),
                                lineHeight: apx(37),
                                fontWeight: "600"
                            }}
                        >
                            {Language.About.Careers.Content[lang]}
                        </span>
                        <Link
                                underline="none"
                                href="#"
                                className={['highlight']}
                                style={{
                                    borderRadius: apx(60),
                                    width: apx(260),
                                    height: apx(88),
                                    lineHeight: apx(88),
                                    textAlign: 'center',
                                    fontSize: apx(16),
                                    fontWeight: 500,
                                    color: '#ffffff',
                                    border:'1px solid rgba(255,255,255,1)'
                                }}>
                                {Language.About.Careers.Btn[lang]}
                            </Link>
                    </div>
                </div> */}
                </div>
                );
            }
        }
        
        export default Index;
