// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";
import { Link } from "@material-ui/core";

class Index extends React.Component {
  state = {};

  componentDidMount() {}

  render() {
    const lang = Language.CurLanguage();

    return (
      <div className={"column"} style={{ backgroundColor: "#fff" }}>
        <div className={"column center"}>
          <img
            src={require("../assets/value_1.svg")}
            style={{ width: apx(82), height: apx(82), marginTop: apx(99) }}
            alt=""
          />
          <span
            style={{
              fontSize: apx(26),
              color: "#4E87F7",
              fontWeight: "bolder",
              marginTop: apx(40)
            }}
          >
            {Language.Home.Value.Mission[lang].Title}
          </span>
          <span
            style={{
              width: apx(600),
              fontSize: apx(22),
              color: "#5A5A5A",
              marginTop: apx(20),
              marginBottom: apx(30),
              textAlign: "center"
            }}
          >
            {Language.Home.Value.Mission[lang].Company}
            {Language.Home.Value.Mission[lang].Desc}
          </span>
          <Link href="/about">
            <span style={{ fontSize: apx(22), color: "#0D90FD" }}>
              {Language.Home.Value.Mission[lang].Learn}
            </span>
          </Link>
        </div>

        <div className={"column center"}>
          <img
            src={require("../assets/value_2.svg")}
            style={{ width: apx(82), height: apx(82), marginTop: apx(78) }}
            alt=""
          />
          <span
            style={{
              fontSize: apx(26),
              color: "#4E87F7",
              fontWeight: "bolder",
              marginTop: apx(40)
            }}
          >
            {Language.Home.Value.Technology[lang].Title}
          </span>
          <span
            style={{
              width: apx(600),
              fontSize: apx(22),
              color: "#5A5A5A",
              marginTop: apx(20),
              marginBottom: apx(30),
              textAlign: "center"
            }}
          >
            {Language.Home.Value.Technology[lang].Desc}
          </span>
          <Link href="#architecture">
            <span style={{ fontSize: apx(22), color: "#0D90FD" }}>
              {Language.Home.Value.Technology[lang].Explore}
            </span>
          </Link>
        </div>

        <div className={"column center"} style={{ marginBottom: apx(99) }}>
          <img
            src={require("../assets/value_3.svg")}
            style={{ width: apx(82), height: apx(82), marginTop: apx(78) }}
            alt=""
          />
          <span
            style={{
              fontSize: apx(26),
              color: "#4E87F7",
              fontWeight: "bolder",
              marginTop: apx(40)
            }}
          >
            {Language.Home.Value.Philosophy[lang].Title}
          </span>
          <span
            style={{
              width: apx(600),
              fontSize: apx(22),
              color: "#5A5A5A",
              marginTop: apx(20),
              marginBottom: apx(30),
              textAlign: "center"
            }}
          >
            {Language.Home.Value.Philosophy[lang].Desc}
          </span>
          <Link href="#ecosystem">
            <span style={{ fontSize: apx(22), color: "#0D90FD" }}>
              {Language.Home.Value.Philosophy[lang].Explore}
            </span>
          </Link>
        </div>
      </div>
    );
  }
}

export default Index;
