import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Mission from "../../assets/img/mission.svg";
import Technology from "../../assets/img/technology.svg";
import Pjilosophy from "../../assets/img/pjilosophy.svg";
import Link from "@material-ui/core/Link";
import Language from '../common/Language';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: 'rgba(255,255,255,1)',
  },
  container: {
    margin: '100px auto',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    // height: 80,
    // width: 58,
  },
  title: {
    marginTop: '30px',
    fontSize: '26px',
    fontWeight: 600,
    lineHeight: '31px',
    color: '#4E87F7',
    // fontFamily: 'PingFangSC-Semibold,PingFang SC;'
  },
  content: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'center',
    width: '353px',
    height: 140,
    paddingTop: 21,
    color: 'rgba(90,90,90,1)',
    // fontFamily: 'PingFangSC-Regular,PingFang SC;'
  },
  learnmore: {
    marginTop: '25px',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '19px',
    color: 'rgba(13,144,253,1)',
    // fontFamily: 'PingFangSC-Regular,PingFang SC;'
  },
  value_item: {
    borderLeft: '2px solid rgba(229,229,229,1)',
  }
});

function ProductValues(props) {
  const { classes } = props;
  const lang =Language.CurLanguage();

  return (
    <section className={classes.root} id="product-values">
      <Container className={classes.container}>
        <Grid container  justify="flex-end" wrap="nowrap"  spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={Mission}
                alt="suitcase"
              />
              <Typography className={classes.title}>
                {Language.Home.Value.Mission[lang].Title}
              </Typography>
              <Typography className={classes.content}>
              {Language.Home.Value.Mission[lang].Company}
              {Language.Home.Value.Mission[lang].Desc}
              </Typography>
            </div>
            <div className={classes.item}>
              <Link href="/about" className={classes.learnmore}>
              {Language.Home.Value.Mission[lang].Learn}
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={4} className={classes.value_item}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={Technology}
                alt="graph"
              />
              <Typography className={classes.title}>
              {Language.Home.Value.Technology[lang].Title}
              </Typography>
              <Typography className={classes.content}>
              {Language.Home.Value.Technology[lang].Desc}
              </Typography>
            </div>
            <div className={classes.item}>
              <Link href="#architecture" className={classes.learnmore}>
              {Language.Home.Value.Technology[lang].Explore}
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={4} className={classes.value_item}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={Pjilosophy}
                alt="clock"
              />
              <Typography className={classes.title}>
              {Language.Home.Value.Philosophy[lang].Title}
              </Typography>
              <Typography className={classes.content}>
              {Language.Home.Value.Philosophy[lang].Desc}
              </Typography>
            </div>
            <div className={classes.item}>
              <Link href="#ecosystem" className={classes.learnmore}>
              {Language.Home.Value.Philosophy[lang].Explore}
              </Link>
            </div>
          </Grid>
        </Grid>
        {/* <Grid container style={{marginTop: '10px'}}  justify="flex-end" wrap="nowrap"   spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <Link href="/about" className={classes.learnmore}>
              {Language.Home.Value.Mission[lang].Learn}
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <Link href="#architecture" className={classes.learnmore}>
              {Language.Home.Value.Technology[lang].Explore}
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <Link href="#ecosystem" className={classes.learnmore}>
              {Language.Home.Value.Philosophy[lang].Explore}
              </Link>
            </div>
          </Grid>
        </Grid> */}
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
