import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { withStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import Language from "../common/Language";
import Container from "@material-ui/core/Container";
// import VideoThumb from '../../assets/img/banner-preview.png'
import { Fade } from "react-reveal";
// import Typography from '../components/Typography';

const styles = (theme) => ({
  root: {
    position: "relative",
    // background: 'blue',

    color: "#fff",
    background: "url('/banner-preview.png') no-repeat",
  },
  container: {
    // padding: '156px 0',
    // height: '500px',
    // display: 'flex',
    // position: 'relative',
    // flexDirection: 'column',
    zIndex: "100",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "5%",
    display: "flex",
    alignItems: "center",
  },
  shadow: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '50%',
    background: 'linear-gradient(90deg, rgba(0, 32, 96, 0.81) 61.43%, rgba(0, 32, 96, 0) 94.66%)'
  },
  video: {
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // top: 0,
    // bottom: 0,
  },
  title: {
    color: "#fff",
    fontSize: "58px",
    letterSpacing: "4px",
    fontWeight: 600,
    // textAlign: 'center',
    margin: "0 auto 44px",
    position: "relative",
    display: "block",
    width: "100%",
  },
  content: {
    marginTop: "-44px",
  },
  desc: {
    fontSize: "20px",
    letterSpacing: "2px",
  },
  exploreBtn: {
    marginTop: "32px",
    background: "transparent",
    border: "1px solid #fff",
    color: "#fff",
    "&:hover": {
      border: "1px solid #fff",
      background: "#fff",
      color: "#4E87F7",
    },
  },
});

function Banner(props) {
  const { classes } = props;
  const [currentSlide, setCurrentSlide] = useState(1);
  const lang = Language.CurLanguage();
  // const [videoLoaded, setVideoLoaded] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === 1 ? 2 : 1));
    }, 8000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <section id="Banner" className={classes.root}>
      <ReactPlayer
        loop={true}
        playing={true}
        width="100%"
        height="auto"
        muted={true}
        className={classes.video}
        url="https://media.riochain.io/banner-bg.mp4"
      />
      <div className={classes.shadow} />
      <Container className={classes.container}>
        {lang !== "Spanish" && (
          <div className={classes.content}>
            {currentSlide === 1 && (
              <Fade>
                <h1 className={classes.title}>
                  <div style={{ fontSize: "35px" }}>Financial Services</div>
                  <div>
                    <span className="color-light">Re</span>invented
                  </div>
                </h1>
                <div className={classes.desc}>
                  <strong>RioDeFi</strong> accelerates the mass adoption of
                  digital
                  <br /> assets by bridging traditional and decentralized
                  <br /> finance.
                  <br />
                  <br />
                  Our applications enable lower transaction fees, faster
                  <br /> confirmations, more efficiency, better returns for
                  <br /> savings account holders, and global reach.
                </div>
                <a href="#product-values">
                  <Button className={classes.exploreBtn}>Learn More</Button>
                </a>
              </Fade>
            )}
            {currentSlide === 2 && (
              <Fade>
                <h1 className={classes.title}>
                  <div style={{ fontSize: "28px" }}>
                    Blockchain Infrastructure
                  </div>
                  <div>
                    <span className="color-light">Re</span>imagined
                  </div>
                </h1>
                <div className={classes.desc}>
                  <strong>RioChain</strong> network provides access to cross
                  chain DeFi
                  <br /> services. It efficiently connects and process
                  <br /> transactions across various types of networks.
                  <br />
                  <br />
                  RioChain is a public Blockchain built on the Parity
                  <br /> Substrate framework and fully compatible with the
                  <br /> POLKADOT network.
                </div>
                <a href="#architecture">
                  <Button className={classes.exploreBtn}>Explore</Button>
                </a>
              </Fade>
            )}
          </div>
        )}
        {lang === "Spanish" && (
          <div className={classes.content}>
            {currentSlide === 1 && (
              <Fade>
                <h1 className={classes.title}>
                  <div style={{ fontSize: "35px" }}>Servicios financieros</div>
                  <div>
                    <span className="color-light">Re</span>inventado
                  </div>
                </h1>
                <div className={classes.desc}>
                  <strong>RioDeFi</strong> acelera la adopción masiva de activos
                  digitales
                  <br />
                  uniendo las finanzas tradicionales y las
                  <br />
                  descentralizadas.
                  <br />
                  <br />
                  Nuestras aplicaciones permiten reducir las tarifas de las 
                  <br /> transacciones, acelerar confirmaciones, más eficiencia, mejores
                  <br /> rendimientos para los titulares de las cuentas de ahorro y un alcance global.
                </div>
                <a href="#product-values">
                  <Button className={classes.exploreBtn}>Explorar</Button>
                </a>
              </Fade>
            )}
            {currentSlide === 2 && (
              <Fade>
                <h1 className={classes.title}>
                  <div style={{ fontSize: "28px" }}>
                    Infraestructura de la cadena de bloques
                  </div>
                  <div>
                    <span className="color-light">Re</span>inventado
                  </div>
                </h1>
                <div className={classes.desc}>
                  La red de RioChain proporciona acceso a los
                  <br /> servicios DeFi. Conecta y procesa eficientemente
                  <br /> transacciones a través de varios tipos de redes.
                  <br />
                  <br />
                  RioChain es una Blockchain pública construida en el marco
                  <br /> de Parity Substrate y totalmente compatible con
                  <br /> la red POLKADOT.
                </div>
                <a href="#architecture">
                  <Button className={classes.exploreBtn}>Explorar</Button>
                </a>
              </Fade>
            )}
          </div>
        )}
      </Container>
    </section>
  );
}

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Banner);
