import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import ProductFaq from './modules/views/ProductFaq';
import AppAppBar from './modules/views/AppAppBar';
import AppFooter from './modules/views/AppFooter';
import MFaq from "./mobile/views/FAQ";

class Index extends React.Component {
  IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod"
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  render() {
    return (
      true === this.IsPC() ? (
        <React.Fragment>
          <AppAppBar about="1" />
          <ProductFaq />
          <AppFooter />
        </React.Fragment>
      ) : (
          <MFaq />
        )
    )
  }
}

export default withRoot(Index);
