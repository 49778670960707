import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
// import ArchRight from "../../assets/img/arch_right.svg";
import ArchImage1 from "../../assets/img/arch_img1.svg";
// import TitleArrow from "../../assets/img/title-arrow.png";
import VideoThumb from '../../assets/img/video-thumb-1.png';
import Flexible from "../../assets/img/flexible.svg";
import Interoper from "../../assets/img/interoper.svg";
import Language from '../common/Language';
import { STYLES } from "../../themes";
import ReactPlayer from 'react-player'
import { Fade } from 'react-reveal'


const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: 'rgba(255,255,255,1)',
    },
    container: {
        marginTop: 0,
        marginBottom: 0,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
    arch_left: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'right',
        padding: theme.spacing(0, 5),
    },
    arch_right: {
        marginLeft: '64px',
        width: '100%',
        '& div': {
            height: 'unset !important'
        }
        // padding: theme.spacing(0, 5),
    },
    arch_left_card: {
        width: 520,
        height: 180,
        marginBottom: 22,
        paddingLeft: 28,
        ...STYLES.row,
        alignItems: 'center',
    },
    arch_left_card_title: {
        fontSize: '24px',
        spacing: '31px',
        fontWeight: 'bold',
        color: '#4E87F7',
        lineHeight: '31px',
        // fontFamily: 'SFUIText-Bold,SFUIText;'
    },
    arch_left_card_icon: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
    },
    arch_left_card_icon1: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
    },
    grid_root: {
        marginTop: '32px',
        marginBottom: '56px',
    },
    title: {
        marginTop: '140px',
        fontSize: '46px',
        fontWeight: 600,
        color: '#002060',
        padding: '8px 18px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        // fontFamily: 'PingFangSC-Semibold,PingFang SC;'
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180,
    },
    content: {
        marginTop: theme.spacing(2),
        color: 'black',
        fontSize: 16
    },
    body_font_content: {
        marginTop: '10px',
        width: 357,
        fontSize: '16px',
        fontWeight: 400,
        color: 'rgba(90,90,90,1)',
        lineHeight: '30px',
        // fontFamily: 'PingFangSC-Regular,PingFang SC;'
    }
});

function Architecture(props) {
    const { classes } = props;
    const lang = Language.CurLanguage();

    return (
        <section id="architecture" className={classes.root}>
            <Container className={classes.container}>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={12}>
                        <h2 className={classes.item}>
                            <Typography className={classes.title}>
                                <Fade left>
                                    {/* <img src={TitleArrow} className="title-arrow" /> */}
                                    <span dangerouslySetInnerHTML={{ __html: Language.Home.Arch.Title[lang] }}></span>
                                </Fade>
                            </Typography>
                        </h2>
                    </Grid>
                </Grid>
                <Grid container wrap="nowrap" className={classes.grid_root}>
                    <Grid item xs={12} md={12}>
                        <div className={classes.item}>
                            <Grid container justify="flex-end" wrap="wrap" spacing={0}>
                                <Grid item xs={12} md={6} style={{
                                    alignItems: 'right',
                                }}>
                                    <div className={classes.arch_left}>
                                        <Typography className={classes.arch_left_card}>
                                            <img src={ArchImage1} alt=""
                                                style={{ width: 89, height: 89, marginRight: 26 }} />
                                            <div style={{ ...STYLES.column, alignItems: 'flex-start' }}>
                                                <Typography className={classes.arch_left_card_title}>
                                                    {Language.Home.Arch.Hybrid[lang].Title}
                                                </Typography>
                                                <Typography className={classes.body_font_content}>
                                                    {Language.Home.Arch.Hybrid[lang].Content}
                                                </Typography>
                                            </div>
                                        </Typography>
                                        <Typography className={classes.arch_left_card}>
                                            <img src={Flexible} alt=""
                                                style={{ width: 89, height: 89, marginRight: 26 }} />
                                            <div style={{ ...STYLES.column, alignItems: 'flex-start' }}>
                                                <Typography className={classes.arch_left_card_title}>
                                                    {Language.Home.Arch.Flexible[lang].Title}
                                                </Typography>
                                                {lang === 'English' && <Typography className={classes.body_font_content}>
                                                    - Virtual Machine Interpreter supports Web Assembly<br />
                                                    - Light client requirements<br />
                                                    - Customizable consensus algorithms
                                                </Typography>}
                                                {lang === '中文' && <Typography className={classes.body_font_content}>
                                                    - 虚拟机解释器支持Web Assembly<br />
                                                    - 轻客户端的要求<br />
                                                    - 可定制的共识算法
                                                </Typography>}

                                                {lang === 'Spanish' && <Typography className={classes.body_font_content}>
                                                    - El intérprete de la máquina virtual es compatible con Web Assembly<br />
                                                    - Requerimientos de clientes ligeros<br />
                                                    - Algoritmos de consenso personalizables
                                                </Typography>}
                                            </div>
                                        </Typography>


                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <div className={classes.arch_right}>
                                        <Typography className={classes.arch_left_card} style={{ maarginBottom: 0 }}>
                                            <img src={Interoper} alt=""
                                                style={{ width: 89, height: 89, marginRight: 26 }} />
                                            <div style={{ ...STYLES.column, alignItems: 'flex-start' }}>
                                                <Typography className={classes.arch_left_card_title}>
                                                    {Language.Home.Arch.Interoperability[lang].Title}
                                                </Typography>
                                                <Typography className={classes.body_font_content}>
                                                    {Language.Home.Arch.Interoperability[lang].Content}
                                                </Typography>
                                            </div>
                                        </Typography>
                                        {/* <ReactPlayer light={VideoThumb} className={classes.video} width="100%" url="https://media.riochain.io/rio-chain-intro.mov" controls /> */}
                                        {/* <img src={ArchRight} alt="" /> */}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

Architecture.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Architecture);
