// --- Post bootstrap -----
import React from "react";
import AboutHeader from "../components/AboutHeader";
import OurTeam from "../components/OurTeam";
import AboutFooter from "../components/AboutFooter";
import Layout from "../components/Layout";
import MobileFooter from "../components/MobileFooter";


class Index extends React.Component {
  state = {
    open: false
  };

  render() {
    return (
      <React.Fragment>
        <Layout>
          <AboutHeader />
          <OurTeam />
          <AboutFooter />
          <MobileFooter />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Index;
