import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import { useParams } from 'react-router-dom'
import Divider from '@material-ui/core/Divider';
// import Language from '../common/Language';
import GhostContentApi from '@tryghost/content-api'

const GhostApi = new GhostContentApi({
    url: 'https://jamesanderson.ghost.io',
    key: 'd0f65e85d6126ffa2cec366c05',
    version: 'v3'
})

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        marginBottom: '69px',
    },
    container: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
    },
    loading: {
        fontSize: '18px',
        margin: '100px auto',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 5),
    },
    title: {
        marginTop: '64px',
        marginBottom: '32px',
        fontSize: '24px',
        fontWeight: 600,
        // textAlign: 'center',
        // fontFamily: 'PingFangSC-Medium,PingFang SC;',
        lineHeight: '50px',
        color: 'rgba(11,16,60,1)'
    },
    info: {
        margin: '12px 0 16px',
        fontSize: '16px',
        fontWeight: '600',
        '&>div': {
            marginRight: '16px',
            display: 'inline-block',
        }
    },
    text: {
        marginTop: '16px',
        fontFamily: 'georgia,serif',
        '&>p': {
            fontSize: '20px',
            lineHeight: '30px',
        }
    },
    content: {
        width: '65%',
        margin: '32px auto 64px',
        fontSize: '18px',
        color: '#343f44',
        '& .kg-embed-card': {
            position: 'relative',
            paddingBottom: '52.25%',
            height: '0',
            '& iframe': {
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
            }
        },
        '& img': {
            maxWidth: '100%',
            margin: '0 auto'
        },
        '& figure': {
            textAlign: 'center'
        }
    },
});

function ArticleContent(props) {
    const { classes } = props;
    const [article, setArticle] = useState('');
    const [loading, setLoading] = useState(true);
    const { slug } = useParams()
    // const lang = Language.CurLanguage();
    useEffect(() => {
        async function getArticle() {
            try {
                GhostApi.posts.read({ slug: slug }, { include: 'tags,authors' }).then(article => {
                    console.log('article is', article)
                    setLoading(false)
                    setArticle(article)
                })
            } catch{
                setLoading(false)
            }
        }
        getArticle()
    }, [slug])

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                {loading && (
                    <div className={classes.loading}>
                        Loading article...
                    </div>
                )}
                {!loading && (
                    <div>
                        <Typography className={classes.content}>
                            <Typography className={classes.title}>
                                <h1>
                                    {article.title}
                                </h1>
                            </Typography>
                            <Typography className={classes.info}>
                                {article.authors && article.authors.length > 0 && article.authors.map(author => (
                                    <div className={classes.author}>{author.name}</div>
                                ))}
                                {new Date(article.published_at).toLocaleString()}
                            </Typography>
                            <Divider />
                            <div className={classes.text} dangerouslySetInnerHTML={{ __html: article.html }}>
                            </div>
                        </Typography>
                    </div>
                )}

            </Container>
        </section>
    );
}

ArticleContent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticleContent);
