// import English from "../../assets/img/english.png";
// import Chinese from "../../assets/img/chinese.png";

class Language { }

Language.CurLanguage = function () {
    var lang = localStorage.getItem("language");
    if ("null" === lang || null == lang) {
        lang = "English";
        localStorage.setItem("language", lang);

        return
    }


    return lang;
};

Language.Nav = {};
Language.Nav.Home = {}
Language.Nav.Home["English"] = "Home";
Language.Nav.Home["中文"] = "主页";
Language.Nav.Home["Spanish"] = "Pagina de inicio";

Language.Nav.About = {};
Language.Nav.About["English"] = "About Us";
Language.Nav.About["中文"] = "关于我们";
Language.Nav.About["Spanish"] = "Sobre nosotros";

Language.Nav.Block = {};
Language.Nav.Block["English"] = "Block Explorer";
Language.Nav.Block["中文"] = "区块浏览器";
Language.Nav.Block["Spanish"] = "Explorador de bloques";

Language.Nav.Whitepaper = {};
Language.Nav.Whitepaper["English"] = "Whitepaper";
Language.Nav.Whitepaper["中文"] = "白皮书";
Language.Nav.Whitepaper["Spanish"] = "Whitepaper";

Language.Nav.Wallet = {};
Language.Nav.Wallet["English"] = "Wallet";
Language.Nav.Wallet["中文"] = "钱包";
Language.Nav.Wallet["Spanish"] = "Billetera";

Language.Nav.Purchase = {};
Language.Nav.Purchase["English"] = "Buy Crypto";
Language.Nav.Purchase["中文"] = "购买数字货币";
Language.Nav.Purchase["Spanish"] = "Comprar criptomonedas";

Language.Nav.PurchaseMoonpay = {};
Language.Nav.PurchaseMoonpay["English"] = "Moonpay";
Language.Nav.PurchaseMoonpay["中文"] = "Moonpay";
Language.Nav.PurchaseMoonpay["Spanish"] = "Moonpay";

Language.Nav.PurchaseSimplex = {};
Language.Nav.PurchaseSimplex["English"] = "Simplex";
Language.Nav.PurchaseSimplex["中文"] = "Simplex";
Language.Nav.PurchaseSimplex["Spanish"] = "Simplex";

Language.Common = {};
Language.Common.NotFoundHint = {};
Language.Common.NotFoundHint["English"] = "The page you requested doesn't exist.";
Language.Common.NotFoundHint["中文"] = "您访问的页面不存在。";
Language.Common.Empty = {};
Language.Common.Empty["English"] = "Content empty";
Language.Common.Empty["中文"] = "暂无条目";
Language.Common.Loading = {};
Language.Common.Loading["English"] = "Loading...";
Language.Common.Loading["中文"] = "加载中...";


Language.Home = {};
Language.Home.Top = {};
Language.Home.Top.Title = {};
Language.Home.Top.Title["English"] = "Our Wallet is Here!";
Language.Home.Top.Title["中文"] = "RioDeFi钱包";

Language.Home.Top.SubTitle = {};
Language.Home.Top.SubTitle["English"] = "Our mission is to accelerate the mass adoption of digital assets by bridging traditional and decentralized finance. ";
Language.Home.Top.SubTitle["中文"] = "我们的使命是连接传统的和去中心化金融，加速数字资产的大规模应用。";

Language.Home.Top.DownLoad = {};
Language.Home.Top.DownLoad["English"] = "Download Now";
Language.Home.Top.DownLoad["中文"] = "现在下载";

Language.Home.Top2 = {};
Language.Home.Top2.Title = {};
Language.Home.Top2.Title["English"] = "Explore Rio, the Blockchain for businesses";
Language.Home.Top2.Title["中文"] = "探索Rio商业区块链";

Language.Home.Top2.Btn = {};
Language.Home.Top2.Btn["English"] = "Learn more";
Language.Home.Top2.Btn["中文"] = "了解更多";

Language.Home.Value = {};
Language.Home.Value.Mission = {}
Language.Home.Value.Mission["English"] = {}
Language.Home.Value.Mission["English"].Title = "Mission";
Language.Home.Value.Mission["English"].Company = "RioDeFi is a blockchain technology company. ";
Language.Home.Value.Mission["English"].Desc = " Our mission is to accelerate the mass adoption of digital assets by bridging traditional and decentralized finance.";
Language.Home.Value.Mission["English"].Learn = "Learn More >";
Language.Home.Value.Mission["中文"] = {}
Language.Home.Value.Mission["中文"].Title = "使命";
Language.Home.Value.Mission["中文"].Company = "RioDeFi是一家区块链公司";
Language.Home.Value.Mission["中文"].Desc = "我们的使命是连接传统的和去中心化金融，加速数字资产的大规模应用。";
Language.Home.Value.Mission["中文"].Learn = "了解更多>";
Language.Home.Value.Mission["Spanish"] = {}
Language.Home.Value.Mission["Spanish"].Title = "Misión";
Language.Home.Value.Mission["Spanish"].Company = "RioDeFi es una empresa de tecnología blockchain.";
Language.Home.Value.Mission["Spanish"].Desc = "Nuestra misión es acelerar la adopción masiva de activos digitales uniendo las finanzas tradicionales y las descentralizadas.";
Language.Home.Value.Mission["Spanish"].Learn = "Más información >>";

Language.Home.Value.Technology = {}
Language.Home.Value.Technology["English"] = {}
Language.Home.Value.Technology["English"].Title = "Technology";
Language.Home.Value.Technology["English"].Company = "";
Language.Home.Value.Technology["English"].Desc = "At the heart of the RioDeFi ecosystem is RioChain, a powerful digital infrastructure tailored for mass adoption. ";
Language.Home.Value.Technology["English"].Explore = "Explore >";
Language.Home.Value.Technology["中文"] = {}
Language.Home.Value.Technology["中文"].Title = "技术";
Language.Home.Value.Technology["中文"].Company = "";
Language.Home.Value.Technology["中文"].Desc = "RioDeFi生态系统的核心是Rio链，为大规模定制采用功能强大的数字基础设施。";
Language.Home.Value.Technology["中文"].Explore = "探索>";
Language.Home.Value.Technology["Spanish"] = {}
Language.Home.Value.Technology["Spanish"].Title = "Tecnología";
Language.Home.Value.Technology["Spanish"].Company = "";
Language.Home.Value.Technology["Spanish"].Desc = "En el corazón del ecosistema RioDeFi se encuentra RioChain, una potente infraestructura digital diseñada para su adopción masiva.";
Language.Home.Value.Technology["Spanish"].Explore = "Explorar >>";

Language.Home.Value.Philosophy = {}
Language.Home.Value.Philosophy["English"] = {}
Language.Home.Value.Philosophy["English"].Title = "Philosophy";
Language.Home.Value.Philosophy["English"].Company = "";
Language.Home.Value.Philosophy["English"].Desc = "When designing RioChain, we prioritized security, speed, scalability, and interoperability. We operate across blockchains to leverage the strengths of each.";
Language.Home.Value.Philosophy["English"].Explore = "Learn More >";
Language.Home.Value.Philosophy["中文"] = {}
Language.Home.Value.Philosophy["中文"].Title = "哲学";
Language.Home.Value.Philosophy["中文"].Company = "";
Language.Home.Value.Philosophy["中文"].Desc = "在设计Rio链时，我们优先考虑安全性，速度，可伸缩性和互操作性。 我们以跨区块链形式运作，以利用每个区块链的优势。";
Language.Home.Value.Philosophy["中文"].Explore = "探索>";
Language.Home.Value.Philosophy["Spanish"] = {}
Language.Home.Value.Philosophy["Spanish"].Title = "Filosofía";
Language.Home.Value.Philosophy["Spanish"].Company = "";
Language.Home.Value.Philosophy["Spanish"].Desc = "Al diseñar RioChain, hemos dado prioridad a la seguridad, la velocidad, la escalabilidad y la interoperabilidad. Operamos a través de blockchains para aprovechar los puntos fuertes de cada uno.";
Language.Home.Value.Philosophy["Spanish"].Explore = "Más información >>";

Language.Home.Partners = {};
Language.Home.Partners.VentureTitle = {};
Language.Home.Partners.VentureTitle["English"] = "Venture Partners";
Language.Home.Partners.VentureTitle["中文"] = "商业合作伙伴";
Language.Home.Partners.VentureTitle["Spanish"] = "Socios comerciales";
Language.Home.Partners.ExchangeTitle = {};
Language.Home.Partners.ExchangeTitle["English"] = "Listed Exchanges";
Language.Home.Partners.ExchangeTitle["中文"] = "交易所合作伙伴";
Language.Home.Partners.ExchangeTitle["Spanish"] = "Exchanges";
Language.Home.Partners.EcosystemTitle = {};
Language.Home.Partners.EcosystemTitle["English"] = "Ecosystem Partners";
Language.Home.Partners.EcosystemTitle["中文"] = "战略合作伙伴";
Language.Home.Partners.EcosystemTitle["Spanish"] = "Socios del ecosistema";

Language.Home.Arch = {};
Language.Home.Arch.Title = {};
Language.Home.Arch.Title["English"] = `Rio<span class="color-light">Chain</span> Architecture`;
Language.Home.Arch.Title["中文"] = `Rio<span class="color-light">Chain</span>架构`;
Language.Home.Arch.Title["Spanish"] = `Arquitectura de RioChain`;

Language.Home.Arch.Hybrid = {};
Language.Home.Arch.Hybrid["English"] = {};
Language.Home.Arch.Hybrid["English"].Title = "Security";
Language.Home.Arch.Hybrid["English"].Content = "RioChain validators verify the parachains' state transitions and register them on the relay chain.";
Language.Home.Arch.Hybrid["中文"] = {}
Language.Home.Arch.Hybrid["中文"].Title = "混合模式";
Language.Home.Arch.Hybrid["中文"].Content = "为了确保可伸缩性，安全性，速度（2S /块），和交易吞吐量（理论最高 3000 TPS），RioChain采用联合模型。";
Language.Home.Arch.Hybrid["Spanish"] = {}
Language.Home.Arch.Hybrid["Spanish"].Title = "Seguridad";
Language.Home.Arch.Hybrid["Spanish"].Content = "Los validadores de RioChain verifican las transiciones de estado de las parachains y las registran en la cadena de relevo.";


Language.Home.Arch.Flexible = {};
Language.Home.Arch.Flexible["English"] = {};
Language.Home.Arch.Flexible["English"].Title = "Interoperability";
Language.Home.Arch.Flexible["English"].Content = "RioChain infrastructure efficiently connects and process transactions across various types of networks.";
Language.Home.Arch.Flexible["Spanish"] = {};
Language.Home.Arch.Flexible["Spanish"].Title = "Interoperabilidad";
Language.Home.Arch.Flexible["Spanish"].Content = "RioChain infrastructure efficiently connects and process transactions across various types of networks.";
Language.Home.Arch.Flexible["中文"] = {}
Language.Home.Arch.Flexible["中文"].Title = "灵活的方法";
Language.Home.Arch.Flexible["中文"].Content = "虚拟机解释器支持Web Assembly，轻客户端的要求，可定制的共识算法";

Language.Home.Arch.Interoperability = {};
Language.Home.Arch.Interoperability["English"] = {};
Language.Home.Arch.Interoperability["English"].Title = "Adaptability";
Language.Home.Arch.Interoperability["English"].Content = "RioChain Virtual Machine Interpreter supports Web Assembly. Parachains can adopt any type of consensus algorithm.";
Language.Home.Arch.Interoperability["中文"] = {}
Language.Home.Arch.Interoperability["中文"].Title = "互操作性";
Language.Home.Arch.Interoperability["中文"].Content = "Rio的基础设施高效地跨链连接不同机构（银行，商户，dApps）。";
Language.Home.Arch.Interoperability["Spanish"] = {}
Language.Home.Arch.Interoperability["Spanish"].Title = "Adaptabilidad";
Language.Home.Arch.Interoperability["Spanish"].Content = "El intérprete de la máquina virtual RioChain soporta Web Assembly. Las parachains pueden adoptar cualquier tipo de algoritmo de consenso.";

Language.Home.Ecosystem = {};
Language.Home.Ecosystem.Title = {};
Language.Home.Ecosystem.Title["English"] = "Rio<span class='color-light'>Chain</span> Ecosystem";
Language.Home.Ecosystem.Title["Spanish"] = "Ecosistema Rio<span class='color-light'>Chain</span> ";
Language.Home.Ecosystem.Title["中文"] = "Rio链系统";
Language.Home.Ecosystem.Content1 = {};
Language.Home.Ecosystem.Content1["English"] = "RioChain comes ready-made with a comprehensive suite of tools. Rio Wallet is accessible from either a smart phone or web browser, enabling users to store a wide variety of digital assets. Rio Block Explorer allows anyone to easily monitor the network, view transactions, and check wallet balances.";
Language.Home.Ecosystem.Content1["Spanish"] = "RioChain comes ready-made with a comprehensive suite of tools. Rio Wallet is accessible from either a smart phone or web browser, enabling users to store a wide variety of digital assets. Rio Block Explorer allows anyone to easily monitor the network, view transactions, and check wallet balances.";
Language.Home.Ecosystem.Content1["中文"] = "Rio链具有现成的一整套工具。可通过手机或网页访问Rio 钱包，使用户能够存储各种各样的数字资产。Rio资源管理器使任何人都可以轻松监控网络，查看交易，并检查钱包余额。";

Language.Home.LatestNews = {};
Language.Home.LatestNews.Title = {};
Language.Home.LatestNews.Title["English"] = "<span class='color-light'>Latest</span> News";
Language.Home.LatestNews.Title["中文"] = "<span class='color-light'>最近</span>发布";
Language.Home.LatestNews.Title["Spanish"] = "<span class='color-light'>Últimas</span> noticias";
Language.Home.LatestNews.SubTitle = {}
Language.Home.LatestNews.Link = {}
Language.Home.LatestNews.Link['English'] = 'More'
Language.Home.LatestNews.Link['中文'] = '更多'
Language.Home.LatestNews.Link['Spanish'] = 'Más'

Language.Home.DApp = {};
Language.Home.DApp.Title = {};
Language.Home.DApp.Title["English"] = "DApp Ecosystem";
Language.Home.DApp.Title["中文"] = "DApp 生态";
Language.Home.DApp.SubTitle = {}
Language.Home.DApp.SubTitle["English"] = "RioChain hosts a broad array of decentralized applications. Our protocol is designed to accommodate high performance software solutions, which in return will contribute to the platform’s economy.";
Language.Home.DApp.SubTitle["中文"] = "Rio链承载了许多去中心化应用程序。 我们的协议设计旨在容纳高性能软件解决方案，反之将有助于平台的经济发展。";


Language.Home.MediumNews = {};
Language.Home.MediumNews.Title = {};
Language.Home.MediumNews.Title["English"] = "Medium News";
Language.Home.MediumNews.Title["中文"] = "Medium发布";
Language.Home.MediumNews.SubTitle = {}
Language.Home.MediumNews.Link = {}
Language.Home.MediumNews.Link['English'] = 'More'
Language.Home.MediumNews.Link['中文'] = '更多'

Language.Home.Question = {};
Language.Home.Question.Title = {};
Language.Home.Question.Title["English"] = "Have a question?";
Language.Home.Question.Title["中文"] = "有问题需要咨询？";
Language.Home.Question.Desc = {};
Language.Home.Question.Desc["English"] = "We're here to help with any questions you might have on investing or understanding this fast-evolving market. Please send us a message if you would like to learn more!";
Language.Home.Question.Desc["中文"] = "我们在这里为您对投资或了解这个快速发展的市场中的任何问题提供帮助。如果您想了解更多，请给我们留言！";

Language.Home.Question.SendMsg = {};
Language.Home.Question.SendMsg["English"] = "Send Message";
Language.Home.Question.SendMsg["中文"] = "发送信息";

Language.Home.Question.Name = {};
Language.Home.Question.Name["English"] = "Name";
Language.Home.Question.Name["中文"] = "名称";

Language.Home.Question.Email = {};
Language.Home.Question.Email["English"] = "Email";
Language.Home.Question.Email["中文"] = "电子邮箱";

Language.Home.Question.Message = {};
Language.Home.Question.Message["English"] = "Message";
Language.Home.Question.Message["中文"] = "信息";

Language.Home.Question.Send = {};
Language.Home.Question.Send["English"] = "Send";
Language.Home.Question.Send["中文"] = "发送";

Language.Home.WhitePaper = {};
Language.Home.WhitePaper.Title = {};
Language.Home.WhitePaper.Title["English"] = "RioDeFi Whitepaper";
Language.Home.WhitePaper.Title["中文"] = "RioDeFi 白皮书";
Language.Home.WhitePaper.SubTitle = {};
Language.Home.WhitePaper.SubTitle["English"] = "Accelerate the mass adoption of digital assets";
Language.Home.WhitePaper.SubTitle["中文"] = "加速数字资产的大规模采用";
Language.Home.WhitePaper.Link = {};
Language.Home.WhitePaper.Link["English"] = "Read";
Language.Home.WhitePaper.Link["中文"] = "阅读";

Language.Home.Subscribe = {};
Language.Home.Subscribe.Title = {};
Language.Home.Subscribe.Title["English"] = "Subscribe to Our Newsletter";
Language.Home.Subscribe.Title["Spanish"] = "Suscríbase a nuestro boletín de noticias";
Language.Home.Subscribe.Title["中文"] = "订阅我们的新闻";

Language.Home.Subscribe.Name = {};
Language.Home.Subscribe.Name["English"] = "Email Address";
Language.Home.Subscribe.Name["Spanish"] = "Dirección de email";
Language.Home.Subscribe.Name["中文"] = "邮箱地址";

Language.Home.Subscribe.Btn = {};
Language.Home.Subscribe.Btn["English"] = "Subscribe";
Language.Home.Subscribe.Btn["Spanish"] = "Suscribirse";
Language.Home.Subscribe.Btn["中文"] = "订阅";

Language.Home.Subscribing = {};
Language.Home.Subscribing.Btn = {};
Language.Home.Subscribing.Btn["English"] = "Subscribing";
Language.Home.Subscribing.Btn["Spanish"] = "Subscribing";
Language.Home.Subscribing.Btn["中文"] = "订阅中";

Language.Home.Subscribe.Success = {};
Language.Home.Subscribe.Success["English"] = "Successfully subscribed!";
Language.Home.Subscribe.Success["Spanish"] = "Successfully subscribed!";
Language.Home.Subscribe.Success["中文"] = "订阅成功！";

Language.Home.Subscribe.Error = {};
Language.Home.Subscribe.Error["English"] = "Failed to subscribe!";
Language.Home.Subscribe.Error["Spanish"] = "Failed to subscribe!";
Language.Home.Subscribe.Error["中文"] = "订阅失败！";

Language.Home.Footer = {};
Language.Home.Footer.Address = {};
Language.Home.Footer.Address["English"] = "Unit Level 9F(2), Main Office Tower, Financial Park Labuan, Jalan Merdeka, 87000 Federal Territory of Labuan, Malaysia.";
Language.Home.Footer.Address["Spanish"] = "Unit Level 9F(2), Main Office Tower, Financial Park Labuan, Jalan Merdeka, 87000 Federal Territory of Labuan, Malaysia.";
Language.Home.Footer.Address["中文"] = "Unit Level 9F(2), Main Office Tower, Financial Park Labuan, Jalan Merdeka, 87000 Federal Territory of Labuan, Malaysia.";

Language.Home.Footer.Email = {};
Language.Home.Footer.Email["English"] = "Email: ";
Language.Home.Footer.Email["Spanish"] = "Correo: ";
Language.Home.Footer.Email["中文"] = "邮箱: ";

Language.Home.Footer.Products = {};
Language.Home.Footer.Products.Title = {};
Language.Home.Footer.Products.Title["English"] = "Products";
Language.Home.Footer.Products.Title["Spanish"] = "Productos";
Language.Home.Footer.Products.Title["中文"] = "产品";

Language.Home.Footer.Products.Mobile = {};
Language.Home.Footer.Products.Mobile["English"] = "Rio Mobile Wallet";
Language.Home.Footer.Products.Mobile["Spanish"] = "Billetera Móvil Rio";
Language.Home.Footer.Products.Mobile["中文"] = "Rio移动端钱包";

Language.Home.Footer.Products.Web = {};
Language.Home.Footer.Products.Web["English"] = "Rio Web Wallet";
Language.Home.Footer.Products.Web["Spanish"] = "Billetera Web Río";
Language.Home.Footer.Products.Web["中文"] = "Rio网页钱包";

Language.Home.Footer.Products.White = {};
Language.Home.Footer.Products.White["English"] = "Whitepaper";
Language.Home.Footer.Products.White["Spanish"] = "Whitepaper";
Language.Home.Footer.Products.White["中文"] = "白皮书";

Language.Home.Footer.Products.Pitch = {};
Language.Home.Footer.Products.Pitch["English"] = "Pitch Deck";
Language.Home.Footer.Products.Pitch["Spanish"] = "Pitch Deck";
Language.Home.Footer.Products.Pitch["中文"] = "融资方案";

Language.Home.Footer.Company = {};
Language.Home.Footer.Company.Title = {};
Language.Home.Footer.Company.Title["English"] = "Company";
Language.Home.Footer.Company.Title["Spanish"] = "Empresa";
Language.Home.Footer.Company.Title["中文"] = "公司";

Language.Home.Footer.Company.About = {};
Language.Home.Footer.Company.About["English"] = "About Us";
Language.Home.Footer.Company.About["Spanish"] = "Sobre nosotros";
Language.Home.Footer.Company.About["中文"] = "关于我们";

Language.Home.Footer.Company.Team = {};
Language.Home.Footer.Company.Team["English"] = "Our Team";
Language.Home.Footer.Company.Team["Spanish"] = "Nuestro equipo";
Language.Home.Footer.Company.Team["中文"] = "我们的团队";

Language.Home.Footer.Company.Contact = {};
Language.Home.Footer.Company.Contact["English"] = "Contact Us";
Language.Home.Footer.Company.Contact["Spanish"] = "Contact Us";
Language.Home.Footer.Company.Contact["中文"] = "联系我们";

Language.Home.Footer.Legal = {};
Language.Home.Footer.Legal.Title = {};
Language.Home.Footer.Legal.Title["English"] = "Legal";
Language.Home.Footer.Legal.Title["Spanish"] = "Legal";
Language.Home.Footer.Legal.Title["中文"] = "法律";
Language.Home.Footer.Legal.Term = {};
Language.Home.Footer.Legal.Term["English"] = "Terms of Use";
Language.Home.Footer.Legal.Term["Spanish"] = "Condiciones de uso";
Language.Home.Footer.Legal.Term["中文"] = "使用条款";
Language.Home.Footer.Legal.Policy = {};
Language.Home.Footer.Legal.Policy["English"] = "Privacy Policy";
Language.Home.Footer.Legal.Policy["Spanish"] = "Política de privacidad";
Language.Home.Footer.Legal.Policy["中文"] = "隐私政策";

Language.Home.Footer.Support = {};
Language.Home.Footer.Support.Title = {};
Language.Home.Footer.Support.Title["English"] = "Support";
Language.Home.Footer.Support.Title["Spanish"] = "Soporte";
Language.Home.Footer.Support.Title["中文"] = "技术支持";
Language.Home.Footer.Support.FAQ = {};
Language.Home.Footer.Support.FAQ["English"] = "FAQ";
Language.Home.Footer.Support.FAQ["Spanish"] = "PREGUNTAS FRECUENTES";
Language.Home.Footer.Support.FAQ["中文"] = "常见问题";


Language.About = {};
Language.About.Mission = {};
Language.About.Mission.Title = {};
Language.About.Mission.Title["English"] = "Mission & Vision";
Language.About.Mission.Title["Spanish"] = "Misión y visión";
Language.About.Mission.Title["中文"] = "使命, 价值观";

Language.About.Mission.Content1 = {};
Language.About.Mission.Content1["English"] = "";
Language.About.Mission.Content1["Spanish"] = "";
Language.About.Mission.Content1["中文"] = "RioDeFi是一家区块链公司。";

Language.About.Mission.Content2 = {};
Language.About.Mission.Content2["English"] = "RioDeFi is a Blockchain technology company. Our mission is to accelerate the mass adoption of digital assets by bridging traditional and decentralized finance. ";
Language.About.Mission.Content2["Spanish"] = "RioDeFi es una empresa de tecnología Blockchain. Nuestra misión es acelerar la adopción masiva de activos digitales tendiendo un puente entre las finanzas tradicionales y las descentralizadas.";
Language.About.Mission.Content2["中文"] = "我们的使命是连接传统的和去中心化金融，加速数字资产的大规模应用。";

Language.About.Mission.Content3 = {};
Language.About.Mission.Content3["English"] = "Our vision is a world in which everyone has access to decentralized financial (DeFi) services. To that end, we develop applications that connect people to digital assets, mobile payments, and DeFi services such as savings and lending. Our solutions enable lower transaction fees, faster confirmations, energy efficiency, secure storage, and global reach.";
Language.About.Mission.Content3["Spanish"] = "Nuestra visión es un mundo en el que todos tengan acceso a servicios financieros descentralizados (DeFi). Para ello, desarrollamos aplicaciones que conectan a las personas con los activos digitales, los pagos móviles y los servicios DeFi, como los ahorros y los préstamos. Nuestras soluciones permiten reducir las tarifas de las transacciones, agilizar las confirmaciones, aumentar la eficiencia energética, asegurar el almacenamiento y tener un alcance global.";
Language.About.Mission.Content3["中文"] = "我们的宗旨是，人人都可以使用去中心化金融（DeFi）服务。 为此，我们开发了将用户连接到数字资产，移动支付和DeFi服务（例如储蓄和贷款）的应用程序。 我们的解决方案可降低交易费用，加快确认速度，提高能效，安全存储并覆盖全球。";

Language.About.Mission.Content4 = {};
Language.About.Mission.Content4["English"] = "";
Language.About.Mission.Content4["Spanish"] = "";
Language.About.Mission.Content4["中文"] = "";

Language.About.Advisors = {};
Language.About.Advisors.Title = {};
Language.About.Advisors.Title["English"] = "Advisors";
Language.About.Advisors.Title["Spanish"] = "Asesores";
Language.About.Advisors.Title["中文"] = "顾问";

Language.About.Team = {};
Language.About.Team.Title = {};
Language.About.Team.Title["English"] = "Team";
Language.About.Team.Title["Spanish"] = "Equipo";
Language.About.Team.Title["中文"] = "团队";

Language.About.Team.Desc = {};
Language.About.Team.Desc["English"] = "RioDeFi co-founders have put together an international team with backgrounds in blockchain, finance, communications, design, and software development. We are dedicated to bringing transparency and inclusion to the world of finance.";
Language.About.Team.Desc["Spanish"] = "Los cofundadores de RioDeFi han reunido un equipo internacional con experiencia en blockchain, finanzas, comunicaciones, diseño y desarrollo de software. Nos dedicamos a aportar transparencia e inclusión al mundo de las finanzas.";
Language.About.Team.Desc["中文"] = "RioDeFi的联合创始人组成了一个国际团队，其团队具有区块链，金融，通信，设计和软件开发的背景。 我们致力于为金融世界带来透明度和包容性。";

Language.About.Team.Member = {};
Language.About.Team.Member.James = {};
Language.About.Team.Member.James.Name = {};
Language.About.Team.Member.James.Name["English"] = "James Anderson";
Language.About.Team.Member.James.Name["Spanish"] = "James Anderson";
Language.About.Team.Member.James.Name["中文"] = "James Anderson";
Language.About.Team.Member.James.Major = {};
Language.About.Team.Member.James.Major["English"] = "CEO/CO-FOUNDER";
Language.About.Team.Member.James.Major["Spanish"] = "director general / cofundador";
Language.About.Team.Member.James.Major["中文"] = "CEO/联合创始人";
Language.About.Team.Member.James.Inter1 = {};
Language.About.Team.Member.James.Inter1["English"] = "James Anderson is a Blockchain advocate and serial entrepreneur whose involvement in Blockchain goes back to early 2013. ";
Language.About.Team.Member.James.Inter1["Spanish"] = "James Anderson es un defensor de Blockchain y emprendedor en serie cuya participación en Blockchain se remonta a principios de 2013.";
Language.About.Team.Member.James.Inter1["中文"] = "James Anderson是一位区块链倡导者和连续创业者，2013年初进入区块链行业。";
Language.About.Team.Member.James.Inter2 = {};
Language.About.Team.Member.James.Inter2["English"] = "Upon  contributing to the Ethereum ICO, James embarked on a journey into social entrepreneurship. Most notably, James co-founded Social Evolution, a non-profit organization whose aim is to raise awareness about the potential of digital currencies and the benefits of decentralized finance.";
Language.About.Team.Member.James.Inter2["Spanish"] = "Tras contribuir a la ICO de Ethereum, James se embarcó en un viaje hacia el emprendimiento social. En particular, James cofundó Social Evolution, una organización sin ánimo de lucro cuyo objetivo es concienciar sobre el potencial de las monedas digitales y los beneficios de las finanzas descentralizadas.";
Language.About.Team.Member.James.Inter2["中文"] = "在致力于以太坊ICO过程中，James踏上了弘扬社会企业家精神的旅程。最值得注意的是，James共同创立的名为社会演进的一家非盈利组织，其宗旨就是促进数字货币和去中心化金融的发展。";

Language.About.Team.Member.Stephane = {};
Language.About.Team.Member.Stephane.Name = {};
Language.About.Team.Member.Stephane.Name["English"] = "Stephane Laurent Villedieu";
Language.About.Team.Member.Stephane.Name["Spanish"] = "Stephane Laurent Villedieu";
Language.About.Team.Member.Stephane.Name["中文"] = "Stephane Laurent Villedieu";
Language.About.Team.Member.Stephane.Major = {};
Language.About.Team.Member.Stephane.Major["English"] = "CMO/CO-FOUNDER";
Language.About.Team.Member.Stephane.Major["Spanish"] = "CMO/CO-FOUNDER";
Language.About.Team.Member.Stephane.Major["中文"] = "CMO/联合创始人";
Language.About.Team.Member.Stephane.Inter1 = {};
Language.About.Team.Member.Stephane.Inter1["English"] = "Stephane is a full-stack marketer whose career spans 12 years and a broad array of corporations, agencies and startups. ";
Language.About.Team.Member.Stephane.Inter1["Spanish"] = "Stephane es un comercializador completo cuya carrera abarca 12 años y una amplia gama de corporaciones, agencias y startups.";
Language.About.Team.Member.Stephane.Inter1["中文"] = "Stephane是一位有12年经验的全栈营销人员。";
Language.About.Team.Member.Stephane.Inter2 = {};
Language.About.Team.Member.Stephane.Inter2["English"] = "An expert of China digital landscape, Stephane has helped dozens of Blockchain projects set foot in Asia, assisting them with fundraising, content creation and community building.";
Language.About.Team.Member.Stephane.Inter2["Spanish"] = "Experto en el panorama digital de China, Stephane ha ayudado a decenas de proyectos de Blockchain a poner un pie en Asia, ayudándoles en la recaudación de fondos, la creación de contenidos y la creación de comunidades.";
Language.About.Team.Member.Stephane.Inter2["中文"] = "他是一位中国数字货币专家，已经帮助数十家区块链项目进入亚洲，协助他们获得融资，内容创建和社区建设。";

Language.About.Team.Member.Calvin = {};
Language.About.Team.Member.Calvin.Name = {};
Language.About.Team.Member.Calvin.Name["English"] = "Calvin Ng";
Language.About.Team.Member.Calvin.Name["Spanish"] = "Calvin Ng";
Language.About.Team.Member.Calvin.Name["中文"] = "Calvin Ng";
Language.About.Team.Member.Calvin.Major = {};
Language.About.Team.Member.Calvin.Major["English"] = "PRESIDENT/CO-FOUNDER";
Language.About.Team.Member.Calvin.Major["Spanish"] = "Presidente / Cofundador";
Language.About.Team.Member.Calvin.Major["中文"] = "主席/联合创始人";
Language.About.Team.Member.Calvin.Inter1 = {};
Language.About.Team.Member.Calvin.Inter1["English"] = "Following a highly successful career in the gaming industry which saw him bring major franchises such as Dayonta USA and World of Warcraft in Asia, Calvin decided to join the Blockchain landscape in 2016. ";
Language.About.Team.Member.Calvin.Inter1["Spanish"] = "Tras una carrera de gran éxito en la industria del juego que le llevó a llevar grandes franquicias como Dayonta USA y World of Warcraft en Asia, Calvin decidió unirse al panorama de Blockchain en 2016.";
Language.About.Team.Member.Calvin.Inter1["中文"] = "继在游戏业的卓越成就，即把Dayonta美国和魔兽世界等游戏的主要专营权引入亚洲后，Calvin在2016年加入区块链行业。";
Language.About.Team.Member.Calvin.Inter2 = {};
Language.About.Team.Member.Calvin.Inter2["English"] = "In addition to his involvement in Rio, Calvin is also managing Director at PlutusVC , a $200 Million fund focused on internet companies.";
Language.About.Team.Member.Calvin.Inter2["Spanish"] = "Además de su participación en Rio, Calvin es también director general de PlutusVC, un fondo de 200 millones de dólares centrado en empresas de Internet.";
Language.About.Team.Member.Calvin.Inter2["中文"] = "除了RioDeFi的投入，Calvin Ng也是PlutusVC的董事总经理，一家专注于投资互联网公司的2亿美元基金公司。";

Language.About.Team.Member.Ekaterina = {};
Language.About.Team.Member.Ekaterina.Name = {};
Language.About.Team.Member.Ekaterina.Name["English"] = "Ekaterina Volkova";
Language.About.Team.Member.Ekaterina.Name["Spanish"] = "Ekaterina Volkova";
Language.About.Team.Member.Ekaterina.Name["中文"] = "Ekaterina Volkova";
Language.About.Team.Member.Ekaterina.Major = {};
Language.About.Team.Member.Ekaterina.Major["English"] = "VP/Co-Founder";
Language.About.Team.Member.Ekaterina.Major["Spanish"] = "Vicepresidenta / Cofundadora";
Language.About.Team.Member.Ekaterina.Major["中文"] = "VP/Co-Founder";
Language.About.Team.Member.Ekaterina.Inter1 = {};

Language.About.Team.Member.Ekaterina.Inter1["English"] = "Having obtained three Master Degrees in business areas in Germany, France and Russia, Katerina launched two startups. From 2016 she became a seasoned Strategic Development and Business consultant. Katerina’s former clients include SBI Group, Montold Capital Germany, Priority Token London, Incheon Capital, and more. In addition she advised many DeFi, VR & blockchain impact projects.";
Language.About.Team.Member.Ekaterina.Inter1["Spanish"] = "Katerina es una experimentada consultora de negocios, y entre sus anteriores empresas se encuentra Priority Token Group, donde ejerció de vicepresidenta. Katerina ha ayudado a estructurar una amplia gama de empresas.";
Language.About.Team.Member.Ekaterina.Inter1["中文"] = "Ekaterina是一位经验丰富的业务顾问，Ekaterina的前合资企业包括Priority Token Group，担任副总裁。";
Language.About.Team.Member.Ekaterina.Inter2 = {};
Language.About.Team.Member.Ekaterina.Inter2["English"] = "Katerina has helped structure a broad range of enterprises. Her expertise includes fundraising, strategic management, event organization, and community building.";
Language.About.Team.Member.Ekaterina.Inter2["Spanish"] = "Su experiencia incluye la recaudación de fondos, la gestión estratégica y la organización de eventos.";
Language.About.Team.Member.Ekaterina.Inter2["中文"] = "Ekaterina有丰富的不同企业经验，致力于帮助企业融资，战略管理和活动组织。";

Language.About.Team.Member.Gino = {};
Language.About.Team.Member.Gino.Name = {};
Language.About.Team.Member.Gino.Name["English"] = "Dr. Gino Yu";
Language.About.Team.Member.Gino.Name["Spanish"] = "Dr. Gino Yu";
Language.About.Team.Member.Gino.Name["中文"] = "Dr. Gino Yu";
Language.About.Team.Member.Gino.Major = {};
Language.About.Team.Member.Gino.Major["English"] = "ADVISOR";
Language.About.Team.Member.Gino.Major["Spanish"] = "Asesor";
Language.About.Team.Member.Gino.Major["中文"] = "顾问";
Language.About.Team.Member.Gino.Inter1 = {};
Language.About.Team.Member.Gino.Inter1["English"] = "Dr. Yu is Chairman and co-founder of the Hong Kong Digital Entertainment Association. His research spans Design Automation, Computer Animation and  Video Games.";
Language.About.Team.Member.Gino.Inter1["Spanish"] = "El Dr. Yu es presidente y cofundador de la Asociación de Entretenimiento Digital de Hong Kong. Su investigación abarca la automatización del diseño, la animación por ordenador y los videojuegos. El Dr. Yu es un ponente habitual en eventos de innovación y concienciación. ";
Language.About.Team.Member.Gino.Inter1["中文"] = "Dr. Gino Yu是香港数码娱乐协会的主席及创始人之一。他的研究跨度涉及设计自动化，电脑动画和视频游戏。";
Language.About.Team.Member.Gino.Inter2 = {};
Language.About.Team.Member.Gino.Inter2["English"] = "Dr. Yu is a regular speaker at innovation and consciousness events. He received his PhD in Electrical Engineering and Computer Science at the University of California at Berkeley in 1993.";
Language.About.Team.Member.Gino.Inter2["Spanish"] = "Se doctoró en Ingeniería Eléctrica e Informática en la Universidad de California en Berkeley en 1993.";
Language.About.Team.Member.Gino.Inter2["中文"] = "Dr. Gino Yu经常在各大创新意识活动上发表演讲。1993年，他在美国加州大学伯克利分校获得了电子工程和计算机科学博士学位。";

Language.About.Team.Member.Harrison = {};
Language.About.Team.Member.Harrison.Name = {};
Language.About.Team.Member.Harrison.Name["English"] = "Harrison Cheung";
Language.About.Team.Member.Harrison.Name["Spanish"] = "Harrison Cheung";
Language.About.Team.Member.Harrison.Name["中文"] = "Harrison Cheung";
Language.About.Team.Member.Harrison.Major = {};
Language.About.Team.Member.Harrison.Major["English"] = "BD DIRECTOR";
Language.About.Team.Member.Harrison.Major["Spanish"] = "Director de Desarrollo Comercial ";
Language.About.Team.Member.Harrison.Major["中文"] = "BD DIRECTOR";
Language.About.Team.Member.Harrison.Inter1 = {};
Language.About.Team.Member.Harrison.Inter1["English"] = "Harrison formerly served as the person in charge of the operations of the CITEX exchange and the AEX exchange, mining and incubating multiple projects to lead online. He was also the organizer of Startup Salad, responsible for the planning and implementation of multiple events. Harrison focuses on community building, event coordination, and strategic positioning. ";
Language.About.Team.Member.Harrison.Inter1["Spanish"] = "Harrison fue anteriormente el responsable de las operaciones de la bolsa CITEX y de la bolsa AEX, extrayendo e incubando múltiples proyectos para dirigirlos en línea. También fue el organizador de Startup Salad, responsable de la planificación y ejecución de múltiples eventos. Harrison se centra en la creación de comunidades, la coordinación de eventos y el posicionamiento estratégico.";
Language.About.Team.Member.Harrison.Inter1["中文"] = "张启业现任RIODEFI BDM，作为区块链领域从业者。加入RIODEFI之前，曾任职于CITEX交易所运营、AEX交易所运营负责人，挖掘并孵化多个项目牵头上线和退出，同时也是Startup Salad创业沙拉组织者，负责多场活动策划及执行落地。擅长品牌建立、活动统筹、战略定位和丰富的二级市场运营经验。";

// Language.About.Team.Member.Billy = {};
// Language.About.Team.Member.Billy.Name = {};
// Language.About.Team.Member.Billy.Name["English"] = "Billy Magbanua";
// Language.About.Team.Member.Billy.Name["Spanish"] = "Billy Magbanua";
// Language.About.Team.Member.Billy.Name["中文"] = "Billy Magbanua";
// Language.About.Team.Member.Billy.Major = {};
// Language.About.Team.Member.Billy.Major["English"] = "PRODUCT MANAGER";
// Language.About.Team.Member.Billy.Major["Spanish"] = "Director de Producto ";
// Language.About.Team.Member.Billy.Major["中文"] = "PRODUCT MANAGER";
// Language.About.Team.Member.Billy.Inter1 = {};
// Language.About.Team.Member.Billy.Inter1["English"] = "Billy is a seasoned digital product manager whose 10 years of experience span projects as diverse as games, e-commerce and trading platforms.He is the co-founder of KQJ, a Blockchain ecommerce platform.";
// Language.About.Team.Member.Billy.Inter1["Spanish"] = "Billy es un experimentado gestor de productos digitales cuyos 10 años de experiencia abarcan proyectos tan diversos como juegos, comercio electrónico y plataformas comerciales. Es cofundador de KQJ, una plataforma de comercio electrónico Blockchain";
// Language.About.Team.Member.Billy.Inter1["中文"] = "Billy 是一名富有经验的电子产品经理，他在游戏、电子商务等领域有着10年的经验。他是区块链商务平台KQJ的联合创办者。";


Language.About.Team.Member.Argu = {};
Language.About.Team.Member.Argu.Name = {};
Language.About.Team.Member.Argu.Name["English"] = "Argu Lin";
Language.About.Team.Member.Argu.Name["Spanish"] = "Argu Lin";
Language.About.Team.Member.Argu.Name["中文"] = "Argu Lin";
Language.About.Team.Member.Argu.Major = {};
Language.About.Team.Member.Argu.Major["English"] = "TECHNICAL DIRECTOR";
Language.About.Team.Member.Argu.Major["Spanish"] = "Director técnico";
Language.About.Team.Member.Argu.Major["中文"] = "TECHNICAL DIRECTOR";
Language.About.Team.Member.Argu.Inter1 = {};
Language.About.Team.Member.Argu.Inter1["English"] = "Argu Lin has been in the online gaming industry for 17 years and has had successful projects in client games, browser games, and mobile games and has participated in many live video projects. He has many years of development experience in the field of P2P video communication.";
Language.About.Team.Member.Argu.Inter1["Spanish"] = "Argu Lin lleva 17 años en el sector de los juegos en línea y ha tenido proyectos exitosos en juegos de cliente, juegos de navegador y juegos para móviles, y ha participado en muchos proyectos de vídeo en directo. Tiene muchos años de experiencia en el desarrollo en el campo de la comunicación de vídeo P2P.";
Language.About.Team.Member.Argu.Inter1["中文"] = "Argu Lin 从事在线游戏行业十七年，不论是端游、页游、还是手游都有成功的项目经验。参与的项目月流水曾达到2000万人民币，曾参与多款视频直播项目，P2P视频通信领域有多年开发经验。";
Language.About.Team.Member.Argu.Inter2 = {};
Language.About.Team.Member.Argu.Inter2["English"] = "The average monthly turnover of the projects he has participated in has reached 20 million RMB. Argu Lin was an early player in blockchain when he started mining Bitcoin in 2009. He is well experienced with blockchain technology and has been involved in the development of a number of blockchain wallets and exchanges.";
Language.About.Team.Member.Argu.Inter2["Spanish"] = "La facturación media mensual de los proyectos en los que ha participado ha alcanzado los 20 millones de RMB. Argu Lin fue uno de los primeros actores de la cadena de bloques cuando empezó a minar Bitcoin en 2009. Tiene mucha experiencia con la tecnología blockchain y ha participado en el desarrollo de varios monederos e intercambios de blockchain.";
Language.About.Team.Member.Argu.Inter2["中文"] = "Argu Lin 2009年开始挖比特币是区块链的早期玩家，对区块链相关技术非常了解，曾参与开发了多款区块链钱包和交易所。";


Language.About.Team.Member.Shahen = {};
Language.About.Team.Member.Shahen.Name = {};
Language.About.Team.Member.Shahen.Name["English"] = "Shahen Markarian";
Language.About.Team.Member.Shahen.Name["Spanish"] = "Shahen Markarian";
Language.About.Team.Member.Shahen.Name["中文"] = "Shahen Markarian";
Language.About.Team.Member.Shahen.Major = {};
Language.About.Team.Member.Shahen.Major["English"] = "PRODUCT DESIGNER";
Language.About.Team.Member.Shahen.Major["Spanish"] = "Diseñador de productos";
Language.About.Team.Member.Shahen.Major["中文"] = "PRODUCT DESIGNER";
Language.About.Team.Member.Shahen.Inter1 = {};
Language.About.Team.Member.Shahen.Inter1["English"] = "Marc is a UI / UX Designer who's passionate about working in startups and designing beautiful enterprise prducts. For the past 5 years, he's been creating wireframes, storyboards, and product designs for companies across Europe and Asia.";
Language.About.Team.Member.Shahen.Inter1["Spanish"] = "Marc es un diseñador de UI / UX al que le apasiona trabajar en startups y diseñar hermosos productos empresariales. Durante los últimos 5 años, ha estado creando wireframes, storyboards y diseños de productos para empresas de Europa y Asia.";
Language.About.Team.Member.Shahen.Inter1["中文"] = "Marc是一位UI / UX设计师，他热衷于在初创公司工作并设计精美的企业产品。 在过去的五年中，他一直在为欧洲和亚洲的公司创建网站架构，故事板和产品设计。";

Language.About.Team.Member.Sunny = {};
Language.About.Team.Member.Sunny.Name = {};
Language.About.Team.Member.Sunny.Name["English"] = "Sunny Cheung";
Language.About.Team.Member.Sunny.Name["Spanish"] = "Sunny Cheung";
Language.About.Team.Member.Sunny.Name["中文"] = "Sunny Cheung";
Language.About.Team.Member.Sunny.Major = {};
Language.About.Team.Member.Sunny.Major["English"] = "ADVISOR";
Language.About.Team.Member.Sunny.Major["Spanish"] = "Asesor";
Language.About.Team.Member.Sunny.Major["中文"] = "顾问";
Language.About.Team.Member.Sunny.Inter1 = {};
Language.About.Team.Member.Sunny.Inter1["English"] = `Sunny Cheung has nearly 20 years of experience in the TMT space and over 10 years of investment experience. He was one of the early builders of the Internet in China. He was founder of CHINANET's first website "Yi Wang Qing Shen" and the BBS in China. He is a senior venture capitalist and technology, media, and telecom (TMT) industry expert. He established Plutus VC with Calvin Ng in Hong Kong in 2018. `;
Language.About.Team.Member.Sunny.Inter1["Spanish"] = `Sunny Cheung tiene casi 20 años de experiencia en el espacio de las TMT y más de 10 años de experiencia en inversiones. Fue uno de los primeros constructores de Internet en China. Fue fundador del primer sitio web de CHINANET "Yi Wang Qing Shen" y de la BBS en China. Es un experto en capital riesgo y en el sector de la tecnología, los medios de comunicación y las telecomunicaciones (TMT). Estableció Plutus VC con Calvin Ng en Hong Kong en 2018.`;
Language.About.Team.Member.Sunny.Inter1["中文"] = "拥有近20年TMT行业与10年投资行业经验，张春晖为中国互联网早期建设者之一，CHINANET第一个网站“深圳之窗”与第一个BBS“一网情深”的创办者，是国内资深的风险投资人与TMT专家。2018年起在香港成立美元基金PLUTUS VC。";

Language.About.Team.Member.Stacie = {};
Language.About.Team.Member.Stacie.Name = {};
Language.About.Team.Member.Stacie.Name["English"] = "Stacie Meng";
Language.About.Team.Member.Stacie.Name["Spanish"] = "Stacie Meng";
Language.About.Team.Member.Stacie.Name["中文"] = "Stacie Meng";
Language.About.Team.Member.Stacie.Major = {};
Language.About.Team.Member.Stacie.Major["English"] = "Partnerships Director";
Language.About.Team.Member.Stacie.Major["Spanish"] = "Directora de Asociaciones";
Language.About.Team.Member.Stacie.Major["中文"] = "Partnerships Director";
Language.About.Team.Member.Stacie.Inter1 = {};
Language.About.Team.Member.Stacie.Inter1["English"] = `Stacie is a Bitcoin investor and cryptocurrencies advocate. She started her career in the VC industry before co-founding KQJ, a Blockchain ecommerce platform. Stacie is also an advisor to various high profile DeFi projects.`;
Language.About.Team.Member.Stacie.Inter1["Spanish"] = `Stacie es una inversora en Bitcoin y defensora de las criptomonedas. Comenzó su carrera en la industria de VC antes de cofundar KQJ, una plataforma de comercio electrónico Blockchain. Stacie también es asesora de varios proyectos DeFi de alto perfil.`;
Language.About.Team.Member.Stacie.Inter1["中文"] = "Stacie是一名比特币投资者和加密货币支持者。她在VC行业起步，然后合伙创办了区块链交易平台KQJ。她也是众多明星DeFi项目的顾问。";

Language.About.Team.Member.Phyrex = {};
Language.About.Team.Member.Phyrex.Name = {};
Language.About.Team.Member.Phyrex.Name["English"] = "Phyrex Ni";
Language.About.Team.Member.Phyrex.Name["Spanish"] = "Phyrex Ni";
Language.About.Team.Member.Phyrex.Name["中文"] = "倪森";
Language.About.Team.Member.Phyrex.Major = {};
Language.About.Team.Member.Phyrex.Major["English"] = "Managing director China";
Language.About.Team.Member.Phyrex.Major["Spanish"] = "Director General de China";
Language.About.Team.Member.Phyrex.Major["中文"] = "Managing director China";
Language.About.Team.Member.Phyrex.Inter1 = {};
Language.About.Team.Member.Phyrex.Inter1["English"] = `A serial entrepreneur with more than 15 years of experience, Phyrex’s expertise lies in digital entertainment and Blockchain technology. A fervent proponent of decentralized finance, his latest ventures have spurred the growth of asset-backed tokens and stablecoins.`;
Language.About.Team.Member.Phyrex.Inter1["Spanish"] = `Emprendedor en serie con más de 15 años de experiencia, Phyrex es experto en entretenimiento digital y tecnología Blockchain. Ferviente defensor de las finanzas descentralizadas, sus últimas empresas han impulsado el crecimiento de tokens respaldados por activos y stablecoins`;
Language.About.Team.Member.Phyrex.Inter1["中文"] = "在中国互联网和去中心化应用有工作研究超过15年，在数字货币,区块链和跨链领域有着丰富经验，尤其是在稳定货币和去中心化金融方面有着出色的认知。设计并运作超过三种不同资产背书的稳定货币。";

Language.About.Team.Member.Will = {};
Language.About.Team.Member.Will.Name = {};
Language.About.Team.Member.Will.Name["English"] = "Will Corkin";
Language.About.Team.Member.Will.Name["Spanish"] = "Will Corkin";
Language.About.Team.Member.Will.Name["中文"] = "Will Corkin";
Language.About.Team.Member.Will.Major = {};
Language.About.Team.Member.Will.Major["English"] = "ADVISOR";
Language.About.Team.Member.Will.Major["Spanish"] = "Asesor";
Language.About.Team.Member.Will.Major["中文"] = "顾问";
Language.About.Team.Member.Will.Inter1 = {};
Language.About.Team.Member.Will.Inter1["English"] = `Will is a blockchain & fintech entrepreneur in both the crypto and tokenized securities markets. Helping to structure and launch over 20 blockchain companies while running the consulting and advisory arm of trade.io, Will more recently headed business development for the only US broker-dealer currently licensed to issue and distribute security tokens. Prior to his involvement in blockchain, he was a portfolio manager at ZX Ventures.`;
Language.About.Team.Member.Will.Inter1["Spanish"] = `Will es un emprendedor de blockchain y fintech en los mercados de criptomonedas y valores tokenizados. Ayudó a estructurar y lanzar más de 20 empresas de blockchain mientras dirigía la rama de consultoría y asesoramiento de trade.io, y más recientemente dirigió el desarrollo de negocios para el único corredor de bolsa estadounidense que actualmente tiene licencia para emitir y distribuir tokens de seguridad. Antes de dedicarse a la cadena de bloques, fue gestor de carteras en ZX Ventures.`;
Language.About.Team.Member.Will.Inter1["中文"] = "Will是一位区块链和金融科技企业家，在加密和通证化证券市场。帮助建立和启动超过20个区块链公司，同时管理trade.io的咨询和咨询部门。Will近期领导与负责美国唯一一家目前持有执照的证券型代币业务开发。在加入区块链之前，他是ZX Ventures的投资组合经理。";

Language.About.Team.Member.John = {};
Language.About.Team.Member.John.Name = {};
Language.About.Team.Member.John.Name["English"] = "John Patrick Mullin";
Language.About.Team.Member.John.Name["Spanish"] = "John Patrick Mullin";
Language.About.Team.Member.John.Name["中文"] = "John Patrick Mullin";
Language.About.Team.Member.John.Major = {};
Language.About.Team.Member.John.Major["English"] = "ADVISOR";
Language.About.Team.Member.John.Major["Spanish"] = "Asesor";
Language.About.Team.Member.John.Major["中文"] = "顾问";
Language.About.Team.Member.John.Inter1 = {};
Language.About.Team.Member.John.Inter1["English"] = `John Patrick Mullin is a FinTech entrepreneur, tokenization expert, and educator living in Hong Kong. He started his career in investment banking having worked at Guotai Junan Securities in Shanghai, China. He then was a founding team member and Managing Director of trade.io a cryptocurrency exchange and advisory firm based in Hong Kong. As an educator, he has spoken at leading universities across the world, including Harvard, London Business School, and Peking University. Additionally, John is a mentor for Entrepreneur First, a leading, deep-tech accelerator and LongHash, a global blockchain accelerator.`;
Language.About.Team.Member.John.Inter1["Spanish"] = `John Patrick Mullin es un empresario de FinTech, experto en tokenización y educador que vive en Hong Kong. Comenzó su carrera en la banca de inversión habiendo trabajado en Guotai Junan Securities en Shanghai, China. Después fue miembro del equipo fundador y director general de trade.io, una empresa de intercambio de criptomonedas y de asesoramiento con sede en Hong Kong. Como educador, ha dado conferencias en las principales universidades del mundo, como Harvard, la London Business School y la Universidad de Pekín. Además, John es mentor de Entrepreneur First, una aceleradora líder de tecnología profunda, y de LongHash, una aceleradora global de blockchain.`;
Language.About.Team.Member.John.Inter1["中文"] = "John Patrick Mullin是一位香港金融科技企业家、代币通证化专家和教育家。他的职业生涯始于中国上海国泰君安证券(Guotai Junan Securities)的投资银行业务，是trade.io的创始团队成员和董事总经理，一家位于香港的加密货币交易所和咨询公司。John是LongHash全球区块链加速器和Entrepreneur First领先的深度科技加速器的导师。";

Language.About.Careers = {};
Language.About.Careers.Title = {};
Language.About.Careers.Title["English"] = "Careers";
Language.About.Careers.Title["Spanish"] = "Carreras";
Language.About.Careers.Title["中文"] = "招聘";

Language.About.Careers.Content = {};
Language.About.Careers.Content["English"] = "We are constantly looking for talented people who want to grow the DeFi ecosystem.";
Language.About.Careers.Content["Spanish"] = "Buscamos constantemente personas con talento que quieran hacer crecer el ecosistema DeFi.";
Language.About.Careers.Content["中文"] = "我们一直在寻找想要帮助开放金融变为现实的人才";

Language.About.Careers.Btn = {};
Language.About.Careers.Btn["English"] = "See our Job Openings";
Language.About.Careers.Btn["Spanish"] = "Ver nuestras ofertas de empleo >>";
Language.About.Careers.Btn["中文"] = "请查看我们的开放职位";

Language.Wallet = {};
Language.Wallet.Title = {};
Language.Wallet.Title2 = {};
Language.Wallet.Title["English"] = "Access RioChain’s";
Language.Wallet.Title2["English"] = "digital assets and dApps";
Language.Wallet.Title["Spanish"] = "Acceda a la página web de RioChain";
Language.Wallet.Title2["Spanish"] = "activos digitales y dApps";
Language.Wallet.Title["中文"] = "访问 Rio 链的数字资产以及dApps";
Language.Wallet.Title2["中文"] = "";

Language.Wallet.SubTitle = {};
Language.Wallet.SubTitle["English"] = "Download Rio Mobile Wallet or create a wallet right in your browser using Rio Web Wallet";
Language.Wallet.SubTitle["Spanish"] = "Descargue Rio Mobile Wallet o cree un monedero directamente en su navegador utilizando Rio Web Wallet";
Language.Wallet.SubTitle["中文"] = "下载Rio手机钱包或使用Rio网页钱包在网页中创建钱包";

Language.Wallet.DownLoad = {};
Language.Wallet.DownLoad.IOS = {};
Language.Wallet.DownLoad.IOS["English"] = "Download for iOS";
Language.Wallet.DownLoad.IOS["Spanish"] = "iOS: Próximamente";
Language.Wallet.DownLoad.IOS["中文"] = "下载iOS版";

Language.Wallet.DownLoad.Android = {};
Language.Wallet.DownLoad.Android["English"] = "Download for Android";
Language.Wallet.DownLoad.Android["Spanish"] = "Android: Próximamente";
Language.Wallet.DownLoad.Android["中文"] = "下载Android版";

Language.Wallet.DownLoad.Web = {};
Language.Wallet.DownLoad.Web["English"] = "RioDeFi Web";
Language.Wallet.DownLoad.Web["Spanish"] = "Google: RioWallet";
Language.Wallet.DownLoad.Web["中文"] = "RioDeFi网站";


Language.Mobile = {};
Language.Mobile.About = {};
Language.Mobile.About.Momber = {};
Language.Mobile.About.Momber["English"] = [
    {
        icon: "james",
        name: "James Anderson",
        major: "CEO/CO-FOUNDER",
        inter1: "James Anderson is a Blockchain advocate and serial entrepreneur whose involvement in Blockchain goes back to early 2013. ",
        inter2: "Upon  contributing to the Ethereum ICO, James embarked on a journey into social entrepreneurship. Most notably, James co-founded Social Evolution, a non-profit organization whose aim is to raise awareness about the potential of digital currencies and the benefits of decentralized finance.",
    },
    {
        icon: "calvin",
        name: "Calvin Ng",
        major: "PRESIDENT/CO-FOUNDER",
        inter1: "Following a highly successful career in the gaming industry, which saw him bring major franchises such as Dayonta USA and World of Warcraft to Asia, Calvin decided to join the Blockchain landscape in 2016.",
        inter2: "In addition to his involvement in Rio, Calvin is also managing Director at PlutusVC, a $200 Million fund focused on internet and blockchain companies.",
    },
    {
        icon: "stephane",
        name: "Stephane Laurent Villedieu",
        major: "CMO/CO-FOUNDER",
        inter1: "Stephane is a full-stack marketer whose career spans 12 years and a broad array of corporations, agencies and startups. ",
        inter2: "An expert of China digital landscape, Stephane has helped dozens of Blockchain projects set foot in Asia, assisting them with fundraising, content creation and community building.",
    },
    {
        icon: "ekaterina",
        name: "Ekaterina Volkova",
        major: "VP/Co-Founder",
        inter1: "Having obtained three Master Degrees in business areas in Germany, France and Russia, Katerina launched two startups. From 2016 she became a seasoned Strategic Development and Business consultant. Katerina’s former clients include SBI Group, Montold Capital Germany, Priority Token London, Incheon Capital, and more. In addition she advised many DeFi, VR & blockchain impact projects.",
        inter2: "Katerina has helped structure a broad range of enterprises. Her expertise includes fundraising, strategic management, event organization, and community building. ",
    },
    {
        icon: "argu",
        name: "Argu Lin",
        major: "TECHNICAL DIRECTOR",
        inter1: "Argu Lin has been in the online gaming industry for 17 years and has had successful projects in client games, browser games, and mobile games and has participated in many live video projects. He has many years of development experience in the field of P2P video communication.",
        inter2: "The average monthly turnover of the projects he has participated in has reached 20 million RMB. Argu Lin was an early player in blockchain when he started mining Bitcoin in 2009. He is well experienced with blockchain technology and has been involved in the development of a number of blockchain wallets and exchanges.",
    },
    {
        icon: "phyrex",
        name: "Phyrex Ni",
        major: "Managing director China",
        inter1: 'A serial entrepreneur with more than 15 years of experience, Phyrex’s expertise lies in digital entertainment and Blockchain technology. A fervent proponent of decentralized finance, his latest ventures have spurred the growth of asset-backed tokens and stablecoins.'
    },
    {
        icon: "harrison",
        name: "Harrison Cheung",
        major: "BD DIRECTOR",
        inter1: "Harrison formerly served as the person in charge of the operations of the CITEX exchange and the AEX exchange, mining and incubating multiple projects to lead online. He was also the organizer of Startup Salad, responsible for the planning and implementation of multiple events. Harrison focuses on community building, event coordination, and strategic positioning. ",
    },
    {
        icon: "stacie",
        name: "Stacie Meng",
        major: "Partnerships Director",
        inter1: 'Stacie is a Bitcoin investor and cryptocurrencies advocate. She started her career in the VC industry before co-founding KQJ, a Blockchain ecommerce platform. Stacie is also an advisor to various high profile DeFi projects.'
    },
    {
        icon: "shahen",
        name: "Shahen Markarian",
        major: "PRODUCT MANAGER",
        inter1: "Marc is a UI / UX Designer who's passionate about working in startups and designing beautiful enterprise prducts. For the past 5 years, he's been creating wireframes, storyboards, and product designs for companies across Europe and Asia.",
    },
    // {
    //     icon: "billy",
    //     name: "Billy Magbanua",
    //     major: "PRODUCT MANAGER",
    //     inter1: "Billy is a seasoned digital product manager whose 10 years of experience span projects as diverse as games, e-commerce and trading platforms.He is the co-founder of KQJ, a Blockchain ecommerce platform.",
    // },
];

Language.Mobile.About.Momber["Spanish"] = [
    {
        icon: "james",
        name: "James Anderson",
        major: "director general / cofundador",
        inter1: "James Anderson es un defensor de Blockchain y emprendedor en serie cuya participación en Blockchain se remonta a principios de 2013.",
        inter2: "Tras contribuir a la ICO de Ethereum, James se embarcó en un viaje hacia el emprendimiento social. En particular, James cofundó Social Evolution, una organización sin ánimo de lucro cuyo objetivo es concienciar sobre el potencial de las monedas digitales y los beneficios de las finanzas descentralizadas.",
    },
    {
        icon: "calvin",
        name: "Calvin Ng",
        major: "Presidente / Cofundador",
        inter1: "Tras una carrera de gran éxito en la industria del juego que le llevó a llevar grandes franquicias como Dayonta USA y World of Warcraft en Asia, Calvin decidió unirse al panorama de Blockchain en 2016.",
        inter2: "Además de su participación en Rio, Calvin es también director general de PlutusVC, un fondo de 200 millones de dólares centrado en empresas de Internet.",
    },
    {
        icon: "stephane",
        name: "Stephane Laurent Villedieu",
        major: "CMO/CO-FOUNDER",
        inter1: "Stephane es un comercializador completo cuya carrera abarca 12 años y una amplia gama de corporaciones, agencias y startups.",
        inter2: "Experto en el panorama digital de China, Stephane ha ayudado a decenas de proyectos de Blockchain a poner un pie en Asia, ayudándoles en la recaudación de fondos, la creación de contenidos y la creación de comunidades.",
    },
    {
        icon: "ekaterina",
        name: "Ekaterina Volkova",
        major: "Vicepresidenta / Cofundadora",
        inter1: "Katerina es una experimentada consultora de negocios, y entre sus anteriores empresas se encuentra Priority Token Group, donde ejerció de vicepresidenta. Katerina ha ayudado a estructurar una amplia gama de empresas.",
        inter2: "Su experiencia incluye la recaudación de fondos, la gestión estratégica y la organización de eventos.",
    },
    {
        icon: "argu",
        name: "Argu Lin",
        major: "Director técnico",
        inter1: "Argu Lin lleva 17 años en el sector de los juegos en línea y ha tenido proyectos exitosos en juegos de cliente, juegos de navegador y juegos para móviles, y ha participado en muchos proyectos de vídeo en directo. Tiene muchos años de experiencia en el desarrollo en el campo de la comunicación de vídeo P2P.",
        inter2: "La facturación media mensual de los proyectos en los que ha participado ha alcanzado los 20 millones de RMB. Argu Lin fue uno de los primeros actores de la cadena de bloques cuando empezó a minar Bitcoin en 2009. Tiene mucha experiencia con la tecnología blockchain y ha participado en el desarrollo de varios monederos e intercambios de blockchain.",
    },
    {
        icon: "phyrex",
        name: "Phyrex Ni",
        major: "Director General de China",
        inter1: 'Emprendedor en serie con más de 15 años de experiencia, Phyrex es experto en entretenimiento digital y tecnología Blockchain. Ferviente defensor de las finanzas descentralizadas, sus últimas empresas han impulsado el crecimiento de tokens respaldados por activos y stablecoins'
    },
    {
        icon: "harrison",
        name: "Harrison Cheung",
        major: "Director de Desarrollo Comercial",
        inter1: "Harrison fue anteriormente el responsable de las operaciones de la bolsa CITEX y de la bolsa AEX, extrayendo e incubando múltiples proyectos para dirigirlos en línea. También fue el organizador de Startup Salad, responsable de la planificación y ejecución de múltiples eventos. Harrison se centra en la creación de comunidades, la coordinación de eventos y el posicionamiento estratégico.",
    },
    {
        icon: "stacie",
        name: "Stacie Meng",
        major: "Directora de Asociaciones",
        inter1: 'Stacie es una inversora en Bitcoin y defensora de las criptomonedas. Comenzó su carrera en la industria de VC antes de cofundar KQJ, una plataforma de comercio electrónico Blockchain. Stacie también es asesora de varios proyectos DeFi de alto perfil.'
    },
    {
        icon: "shahen",
        name: "Shahen Markarian",
        major: "Diseñador de productos",
        inter1: "Marc es un diseñador de UI / UX al que le apasiona trabajar en startups y diseñar hermosos productos empresariales. Durante los últimos 5 años, ha estado creando wireframes, storyboards y diseños de productos para empresas de Europa y Asia.",
    },
    // {
    //     icon: "billy",
    //     name: "Billy Magbanua",
    //     major: "Director de Producto",
    //     inter1: "Billy es un experimentado gestor de productos digitales cuyos 10 años de experiencia abarcan proyectos tan diversos como juegos, comercio electrónico y plataformas comerciales. Es cofundador de KQJ, una plataforma de comercio electrónico Blockchain",
    // },
];

Language.Mobile.About.Momber["中文"] = [
    {
        icon: "james",
        name: "James Anderson",
        major: "CEO/联合创始人",
        inter1: "James Anderson是一位区块链倡导者和连续创业者，2013年初进入区块链行业。",
        inter2: "在致力于以太坊ICO过程中，James踏上了弘扬社会企业家精神的旅程。最值得注意的是，James共同创立的名为社会演进的一家非盈利组织，其宗旨就是促进数字货币和去中心化金融的发展。",
    },
    {
        icon: "calvin",
        name: "Calvin Ng",
        major: "主席/联合创始人",
        inter1: "继在游戏业的卓越成就，即把Dayonta美国和魔兽世界等游戏的主要专营权引入亚洲后，Calvin在2016年加入区块链行业。",
        inter2: "除了RioDeFi的投入，Calvin Ng也是PlutusVC的董事总经理，一家专注于投资互联网公司的2亿美元基金公司。",
    },
    {
        icon: "stephane",
        name: "Stephane Laurent Villedieu",
        major: "CMO/联合创始人",
        inter1: "Stephane是一位有12年经验的全栈营销人员。",
        inter2: "他是一位中国数字货币专家，已经帮助数十家区块链项目进入亚洲，协助他们获得融资，内容创建和社区建设。",
    },

    {
        icon: "ekaterina",
        name: "Ekaterina Volkova",
        major: "VP/Co-Founder",
        inter1: "Ekaterina是一位经验丰富的业务顾问，Ekaterina的前合资企业包括Priority Token Group，担任副总裁。",
        inter2: "Ekaterina有丰富的不同企业经验，致力于帮助企业融资，战略管理和活动组织。",
    },
    {
        icon: "argu",
        name: "Argu Lin",
        major: "TECHNICAL DIRECTOR",
        inter1: "Argu Lin 从事在线游戏行业十七年，不论是端游、页游、还是手游都有成功的项目经验。参与的项目月流水曾达到2000万人民币，曾参与多款视频直播项目，P2P视频通信领域有多年开发经验。",
        inter2: "Argu Lin 2009年开始挖比特币是区块链的早期玩家，对区块链相关技术非常了解，曾参与开发了多款区块链钱包和交易所。",
    },
    {
        icon: "phyrex",
        name: "Phyrex Ni",
        major: "Managing director China",
        inter1: "在中国互联网和去中心化应用有工作研究超过15年，在数字货币,区块链和跨链领域有着丰富经验，尤其是在稳定货币和去中心化金融方面有着出色的认知。设计并运作超过三种不同资产背书的稳定货币。",
    },
    {
        icon: "harrison",
        name: "Harrison Cheung",
        major: "BD MANAGER",
        inter1: "张启业现任RIODEFI BDM，作为区块链领域从业者。加入RIODEFI之前，曾任职于CITEX交易所运营、AEX交易所运营负责人，挖掘并孵化多个项目牵头上线和退出，同时也是Startup Salad创业沙拉组织者，负责多场活动策划及执行落地。擅长品牌建立、活动统筹、战略定位和丰富的二级市场运营经验。",
    },
    {
        icon: "stacie",
        name: "Stacie Meng",
        major: "Partnerships Director",
        inter1: 'Stacie是一名比特币投资者和加密货币支持者。她在VC行业起步，然后合伙创办了区块链交易平台KQJ。她也是众多明星DeFi项目的顾问。'
    },
    {
        icon: "shahen",
        name: "Shahen Markarian",
        major: "PRODUCT DESIGNER",
        inter1: "Marc是一位UI / UX设计师，他热衷于在初创公司工作并设计精美的企业产品。 在过去的五年中，他一直在为欧洲和亚洲的公司创建网站架构，故事板和产品设计。",
    },
    // {
    //     icon: "billy",
    //     name: "Billy Magbanua",
    //     major: "PRODUCT MANAGER",
    //     inter1: "Billy 是一名富有经验的电子产品经理，他在游戏、电子商务等领域有着10年的经验。他是区块链商务平台KQJ的联合创办者。",
    // },
];

Language.Mobile.About.Advisors = {};
Language.Mobile.About.Advisors["English"] = [
    {
        icon: "gino",
        name: "Dr. Gino Yu",
        major: "ADVISOR",
        inter1: "Dr. Yu is Chairman and co-founder of the Hong Kong Digital Entertainment Association. His research spans Design Automation, Computer Animation and  Video Games.",
        inter2: "Dr. Yu is a regular speaker at innovation and consciousness events. He received his PhD in Electrical Engineering and Computer Science at the University of California at Berkeley in 1993.",
    },
    {
        icon: "sunny",
        name: "Sunny Cheung",
        major: "ADVISOR",
        inter1: `Sunny Cheung has nearly 20 years of experience in the TMT space and over 10 years of investment experience. He was one of the early builders of the Internet in China. He was founder of CHINANET's first website "Yi Wang Qing Shen" and the BBS in China. He is a senior venture capitalist and technology, media, and telecom (TMT) industry expert. He established Plutus VC with Calvin Ng in Hong Kong in 2018. `,
    },
    {
        icon: "will",
        name: "Will Corkin",
        major: "ADVISOR",
        inter1: 'Will is a blockchain & fintech entrepreneur in both the crypto and tokenized securities markets. Helping to structure and launch over 20 blockchain companies while running the consulting and advisory arm of trade.io, Will more recently headed business development for the only US broker-dealer currently licensed to issue and distribute security tokens. Prior to his involvement in blockchain, he was a portfolio manager at ZX Ventures.'
    },
    {
        icon: "john",
        name: "John Patrick Mullin",
        major: "ADVISOR",
        inter1: 'John Patrick Mullin is a FinTech entrepreneur, tokenization expert, and educator living in Hong Kong. He started his career in investment banking having worked at Guotai Junan Securities in Shanghai, China. He then was a founding team member and Managing Director of trade.io a cryptocurrency exchange and advisory firm based in Hong Kong. As an educator, he has spoken at leading universities across the world, including Harvard, London Business School, and Peking University. Additionally, John is a mentor for Entrepreneur First, a leading, deep-tech accelerator and LongHash, a global blockchain accelerator.'
    },
];

Language.Mobile.About.Advisors["Spanish"] = [
    {
        icon: "gino",
        name: "Dr. Gino Yu",
        major: "Asesor",
        inter1: "El Dr. Yu es presidente y cofundador de la Asociación de Entretenimiento Digital de Hong Kong. Su investigación abarca la automatización del diseño, la animación por ordenador y los videojuegos. El Dr. Yu es un ponente habitual en eventos de innovación y concienciación. ",
        inter2: "Se doctoró en Ingeniería Eléctrica e Informática en la Universidad de California en Berkeley en 1993.",
    },
    {
        icon: "sunny",
        name: "Sunny Cheung",
        major: "Asesor",
        inter1: `Sunny Cheung tiene casi 20 años de experiencia en el espacio de las TMT y más de 10 años de experiencia en inversiones. Fue uno de los primeros constructores de Internet en China. Fue fundador del primer sitio web de CHINANET "Yi Wang Qing Shen" y de la BBS en China. Es un experto en capital riesgo y en el sector de la tecnología, los medios de comunicación y las telecomunicaciones (TMT). Estableció Plutus VC con Calvin Ng en Hong Kong en 2018.`,
    },
    {
        icon: "will",
        name: "Will Corkin",
        major: "Asesor",
        inter1: 'Will es un emprendedor de blockchain y fintech en los mercados de criptomonedas y valores tokenizados. Ayudó a estructurar y lanzar más de 20 empresas de blockchain mientras dirigía la rama de consultoría y asesoramiento de trade.io, y más recientemente dirigió el desarrollo de negocios para el único corredor de bolsa estadounidense que actualmente tiene licencia para emitir y distribuir tokens de seguridad. Antes de dedicarse a la cadena de bloques, fue gestor de carteras en ZX Ventures.'
    },
    {
        icon: "john",
        name: "John Patrick Mullin",
        major: "Asesor",
        inter1: 'John Patrick Mullin es un empresario de FinTech, experto en tokenización y educador que vive en Hong Kong. Comenzó su carrera en la banca de inversión habiendo trabajado en Guotai Junan Securities en Shanghai, China. Después fue miembro del equipo fundador y director general de trade.io, una empresa de intercambio de criptomonedas y de asesoramiento con sede en Hong Kong. Como educador, ha dado conferencias en las principales universidades del mundo, como Harvard, la London Business School y la Universidad de Pekín. Además, John es mentor de Entrepreneur First, una aceleradora líder de tecnología profunda, y de LongHash, una aceleradora global de blockchain.'
    },
];

Language.Mobile.About.Advisors["中文"] = [
    {
        icon: "gino",
        name: "Dr. Gino Yu",
        major: "顾问",
        inter1: "Dr. Gino Yu是香港数码娱乐协会的主席及创始人之一。他的研究跨度涉及设计自动化，电脑动画和视频游戏。",
        inter2: "Dr. Gino Yu经常在各大创新意识活动上发表演讲。1993年，他在美国加州大学伯克利分校获得了电子工程和计算机科学博士学位。",
    },
    {
        icon: "sunny",
        name: "Sunny Cheung",
        major: "顾问",
        inter1: `拥有近20年TMT行业与10年投资行业经验，张春晖为中国互联网早期建设者之一，CHINANET第一个网站“深圳之窗”与第一个BBS“一网情深”的创办者，是国内资深的风险投资人与TMT专家。2018年起在香港成立美元基金PLUTUS VC。`,
    },
    {
        icon: "will",
        name: "Will Corkin",
        major: "顾问",
        inter1: 'Will是一位区块链和金融科技企业家，在加密和通证化证券市场。帮助建立和启动超过20个区块链公司，同时管理trade.io的咨询和咨询部门。Will近期领导与负责美国唯一一家目前持有执照的证券型代币业务开发。在加入区块链之前，他是ZX Ventures的投资组合经理。'
    },
    {
        icon: "john",
        name: "John Patrick Mullin",
        major: "顾问",
        inter1: 'John Patrick Mullin是一位香港金融科技企业家、代币通证化专家和教育家。他的职业生涯始于中国上海国泰君安证券(Guotai Junan Securities)的投资银行业务，是trade.io的创始团队成员和董事总经理，一家位于香港的加密货币交易所和咨询公司。John是LongHash全球区块链加速器和Entrepreneur First领先的深度科技加速器的导师。'
    },
];


Language.Recruitment = {};
Language.Recruitment.Job = {};
Language.Recruitment.Job["English"] = [
    {
        job: 'Test Engineer',
        detail: [
            {
                title: 'Responsibilities',
                detail: [
                    "1. Daily project test case design and execution",
                    "2. Track down and locate project problems follow up and promote the solution of problems",
                    "3. Follow-up on user feedback",
                    "4. Automated testing and safety testing"
                ]
            },
            {
                title: 'Qualifications',
                detail: [
                    "1. Bachelors Degree in Computer Science",
                    "2. Able to independently complete test case writing use case execution bug tracking and updates",
                    "3. Experience in testing blockchain projects",
                    "4. Familiar with common tools/commands wireshark fiddler ADB commands database etc",
                    "4. Familiar with common tools/commands wireshark fiddler ADB commands database etc"
                ]
            }
        ]
    },
    {
        job: 'Operations Engineer',
        detail: [
            {
                title: 'Responsibilities',
                detail: [
                    "1. Responsible for installation deployment and daily maintenance of related servers of the company",
                    "2. Responsible for making and implementing data backup plans",
                    "3. Responsible for formulating and implementing the server installation strategy",
                    "4. Responsible for monitoring the relevant server logs to ensure stable server operations"
                ]
            },
            {
                title: 'Qualifications',
                detail: [
                    "1. Bachelors Degree or above in Computer Science",
                    "2. Experience with Linux (ubuntu or centos) and shell commands",
                    "3. Familiar with the installation and configuration of Nginx Jenkins docker and other software",
                    "4. Familiar with server-related log monitoring and analysis software",
                    "5. Familiar with network communication technology firewall routing and other configurations",
                    "6. Blockchain related working experience is preferred"
                ]
            }
        ]
    }
];

Language.Recruitment.Job["Spanish"] = [
    {
        job: 'Test Engineer',
        detail: [
            {
                title: 'Responsibilities',
                detail: [
                    "1. Daily project test case design and execution",
                    "2. Track down and locate project problems follow up and promote the solution of problems",
                    "3. Follow-up on user feedback",
                    "4. Automated testing and safety testing"
                ]
            },
            {
                title: 'Qualifications',
                detail: [
                    "1. Bachelors Degree in Computer Science",
                    "2. Able to independently complete test case writing use case execution bug tracking and updates",
                    "3. Experience in testing blockchain projects",
                    "4. Familiar with common tools/commands wireshark fiddler ADB commands database etc",
                    "4. Familiar with common tools/commands wireshark fiddler ADB commands database etc"
                ]
            }
        ]
    },
    {
        job: 'Operations Engineer',
        detail: [
            {
                title: 'Responsibilities',
                detail: [
                    "1. Responsible for installation deployment and daily maintenance of related servers of the company",
                    "2. Responsible for making and implementing data backup plans",
                    "3. Responsible for formulating and implementing the server installation strategy",
                    "4. Responsible for monitoring the relevant server logs to ensure stable server operations"
                ]
            },
            {
                title: 'Qualifications',
                detail: [
                    "1. Bachelors Degree or above in Computer Science",
                    "2. Experience with Linux (ubuntu or centos) and shell commands",
                    "3. Familiar with the installation and configuration of Nginx Jenkins docker and other software",
                    "4. Familiar with server-related log monitoring and analysis software",
                    "5. Familiar with network communication technology firewall routing and other configurations",
                    "6. Blockchain related working experience is preferred"
                ]
            }
        ]
    }
];

Language.Recruitment.Job["中文"] = [
    {
        job: '测试工程师',
        detail: [
            {
                title: '职责',
                detail: [
                    "1. 日常测试用例设计及执行",
                    "2. 跟踪并定位项目问题，跟进并促进问题解决",
                    "3. 跟进用户反馈收集",
                    "4. 自动化及安全性测试"
                ]
            },
            {
                title: '职位要求',
                detail: [
                    "1. 计算机科学与技术学士学位",
                    "2. 能够独立完成编写测试案例及用户操作用例，执行BUG追踪并更新",
                    "3. 拥有区块链项目的测试经历",
                    "4. 熟悉常见的工具/命令，Wireshark, Fiddler, ADB命令、数据库等",
                    "4. 对于日志检查分析和简单的定位"
                ]
            }
        ]
    },
    {
        job: '运营工程师',
        detail: [
            {
                title: '职责',
                detail: [
                    "1. 负责安装部署以及公司服务器的日常维护",
                    "2. 负责制定及实施数据备份计划",
                    "3. 负责制定及实施服务器的安装策略",
                    "4. 确保监控服务器日志来确保服务器的稳定运营"
                ]
            },
            {
                title: '职位要求',
                detail: [
                    "1. 计算机科学与技术学士及以上学位",
                    "2. 熟悉Linux（Ubuntu或Centos）经验和Shell命令",
                    "3. 熟悉Nginx Jenkins容器及其他软件的安装及配置",
                    "4. 熟悉服务器相关的日志监控及分析软件",
                    "5. 熟悉网络通信防火墙线路技术及其他配置",
                    "6. 有区块链相关的工作经验优先录用"
                ]
            }
        ]
    }
];

Language.Recruitment.Resume = {};
Language.Recruitment.Resume["English"] = "Submit resume";
Language.Recruitment.Resume["Spanish"] = "Submit resume";
Language.Recruitment.Resume["中文"] = "提交简历";


Language.ProductFaq = {};
Language.ProductFaq.Title = {};
Language.ProductFaq.Title["English"] = "Frequently Asked Questions";
Language.ProductFaq.Title["Spanish"] = "Frequently Asked Questions";
Language.ProductFaq.Title["中文"] = "常问问题解答";

Language.ProductFaq.GeneralTitle = {};
Language.ProductFaq.GeneralTitle['English'] = 'General';
Language.ProductFaq.GeneralTitle['Spanish'] = 'General';
Language.ProductFaq.GeneralTitle['中文'] = '常见问题';

Language.ProductFaq.RioChain = {};
Language.ProductFaq.RioChain["English"] = [
    {
        title: 'What is RioChain?',
        content: 'RioChain is a next generation blockchain that’s built on Parity Substrate, enabling cross-chain interoperability. This means RioChain can interact with assets of other blockchains, such as Bitcoin and Ethereum. Using a Proof of Authority consensus model of trusted nodes, RioChain is designed for security, speed (2s/block), efficiency (low transaction cost and electricity usage), and scalability (3,000+ transactions per second).',
    },
    {
        title: 'What is Rio Wallet?',
        content: 'Rio Wallet is a cryptocurrency wallet that’s available on both mobile phones and web browsers. It allows users to interact with RioChain to send and receive cryptocurrencies, as well as view their public and private keys. The cryptocurrencies are stored in a distributed manner and maintained in publicly available ledgers called blockchains. It also features a decentralized application (dApp) section for users to explore various dApps built on RioChain.',
    },
    {
        title: 'What Cryptocurrencies can Rio Wallet hold?',
        content: 'Rio Wallet can hold any cryptocurrency on the RioChain. Due to our cross-chain interoperability, it can also hold assets from other blockchains. Currently, Rio Wallet can hold Bitcoin (BTC), Tether (USDT), Rio Fuel (RFUEL), and BIT Token (BIT).'
    },
    {
        title: 'What is Rio Fuel (RFUEL)?',
        content: 'Rio Fuel (RFUEL) is RioChain’s native cryptocurrency. It is a form of digital money that is used to pay for all transactions on RioChain and power its applications. '
    },
    {
        title: 'How much Rio Fuel is there and how is it distributed?',
        content: `
        1 billion Rio Fuel (RFUEL) tokens will be created through a token generation event (TGE): 70% of these tokens are being distributed as rewards based on the model below, 20% will be sold via community crowdsales and private sales, and 10% will be maintained as reserves that will slowly release over 5 years. <br/><br/>
        Promotional (locked) RFUEL will be distributed as follows:  <br/><br/>
        · <strong>New User Incentives*</strong>: 50 million RFUEL (5%) will be distributed for free to new Rio Wallets at a rate of 10 RFUEL per wallet for the first 5 million unique mobile wallets (based on device IDs). Note that these promotional RFUEL tokens will be locked, meaning that they can only be used to pay for transaction fees, cannot be transferred to other users, and will only be valid until January 31, 2026. After that time, all promotional tokens will automatically be burnt. <br/><br/>
        Unlocked RFUEL will be distributed as follows:<br/><br/>
        · <strong>Staking Rewards</strong>: 350 million RFUEL (35%) will be distributed to those staking RFUEL and validating transactions on the network. The APR generated through staking will vary depending on the staking year and the total % of staked RFUEL.<br/><br/>
        · <strong>Referrals, Airdrops, and Bounties</strong>: 50 million RFUEL (5%) will be released for Rio Wallet Mobile App referrals, exchange & community airdrops, and bounty programs. Rio Wallet users who share the Rio Wallet mobile app with their friends will receive 5 free RFUEL, and their friends who download the Rio Wallet mobile app via referral codes will also receive 5 free RFUEL (note: the refer-a-friend RFUEL will be distributed as promotional locked RFUEL subject to the same terms as the new user incentives above). <br/><br/>
        · <strong>RioDeFi Team & Advisors</strong>: 150 million RFUEL (15%) will be distributed to the RioDeFi team & advisors. There is a 2-year vesting schedule with a 6 month cliff, meaning that the team & advisors will receive no tokens for 6 months and then will receive 1/18 of the total amount per month over the following 18 months.<br/><br/>
        · <strong>Rio Ecosystem Fund</strong>: 100 million RFUEL (10%) will be distributed as grants to fund projects built within the Rio Chain ecosystem. Grant proposals will be submitted to Rio Technologies Foundation and voted on by its counsellors. These grants will be for dApps, chain runtime modules, templatized smart contracts, etc. All Rio Ecosystem Fund tokens will be locked until grant approval. <br/><br/>
        · <strong>Reserves</strong>: 100 million RFUEL (10%) will be maintained as reserves. 10 million (10%) of these reserve RFUEL tokens will be used for market liquidity provisions at the outset of TGE and are expected to remain with Rio Technologies Inc. for future re-allocation. The remaining 90% will be unlocked on a rolling basis at a linear release rate starting day 366 since TGE such that 100% of reserve tokens will be unlocked by the end of the 5th year since TGE (roughly 1,826 days since TGE). <br/><br/>
        · <strong>Private Sales</strong>: 150 million RFUEL (15%) will be distributed to Private Sale purchasers. The majority of these RFUEL tokens will vest over 151 days, with 1/6 of the tokens being distributed at Token Generation Event (TGE) and on days 31, 61, 91, 121, and 151 after TGE. The remainder of these tokens will be unlocked at TGE. <br/><br/>
        · <strong>Community Crowdsales</strong>: 50 million RFUEL (5%) will be distributed to those who purchase RFUEL during the community crowdsales.<br/><br/>
        <table class="faq-table">
            <thead>
                <tr>
                    <th>RFUEL Distribution</th>
                    <th>Token Amount</th>
                    <th>Percentage (%)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>New User Incentives (locked)</td>
                    <td>50,000,000</td>
                    <td>5%</td>
                </tr>
                <tr>
                    <td>Staking & Validation Rewards</td>
                    <td>350,000,000</td>
                    <td>35%</td>
                </tr>
                <tr>
                    <td>Referrals, Airdrops, and Bounties</td>
                    <td>50,000,000</td>
                    <td>5%</td>
                </tr>
                <tr>
                    <td>RioDeFi Team & Advisors</td>
                    <td>150,000,000</td>
                    <td>15%</td>
                </tr>
                <tr>
                    <td>Rio Ecosystem Fund</td>
                    <td>100,000,000</td>
                    <td>10%</td>
                </tr>
                <tr>
                    <td>Reserves</td>
                    <td>100,000,000</td>
                    <td>10%</td>
                </tr>
                <tr>
                    <td>Private Sales</td>
                    <td>150,000,000</td>
                    <td>15%</td>
                </tr>
                <tr>
                    <td>Community Crowdsales</td>
                    <td>50,000,000</td>
                    <td>5%</td>
                </tr>
              
                <tr>
                    <td><strong>Total</strong></td>
                    <td><strong>1,000,000,000</strong></td>
                    <td><strong>100%</strong></td>
                </tr>
            </tbody>
        </table><br/><br/>
        <strong>Staking & Validation Rewards Distribution:</strong><br/><br/>
        <table class="faq-table">
        <thead>
            <tr>
                <th>Staking Year</th>
                <th>Circulating Supply Est.</th>
                <th>Rewards Given</th>
                <th>Avg. APR (%)</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>2020 - 2021</td>
                <td>170,000,000</td>
                <td>60,000,000</td>
                <td>60%</td>
            </tr>
            <tr>
                <td>2022</td>
                <td>370,000,000</td>
                <td>100,000,000</td>
                <td>54%</td>
            </tr>
            <tr>
                <td>2023</td>
                <td>600,000,000</td>
                <td>110,000,000</td>
                <td>37%</td>
            </tr>
            <tr>
                <td>2024</td>
                <td>730,000,000</td>
                <td>50,000,000</td>
                <td>14%</td>
            </tr>
            <tr>
                <td>2025</td>
                <td>890,000,000</td>
                <td>30,000,000</td>
                <td>7%</td>
            </tr>
            <tr>
                <td>2026</td>
                <td>950,000,000</td>
                <td>0</td>
                <td>0%</td>
            </tr>
        </tbody>
    </table><br/><br/>
    <strong>Estimation Notes / Assumptions:</strong><br/><br/>
    · Circulating supply est. is the estimated amount at the beginning of each year <br/><br/>
    · Avg. APR is estimated based on a 50% staking rate of the circulating supply
    `
    },
    {
        title: 'Can I purchase more Rio Fuel when I run out?',
        content: 'Yes, Rio Fuel (RFUEL) may be purchased within RioChain’s participating decentralized applications (dApps). The starting price of RFUEL is USD 0.14. In the near future, RFUEL will also be available for purchase on select exchanges and over-the-counter (OTC) services.  '
    },
    {
        title: 'What is Rio Block Explorer?',
        content: 'Rio Block Explorer allows anyone to monitor the network and view transaction receipts. You can enter a Rio Wallet address to view its contents and see all its transactions within the network. Rio Block Explorer gives information on the fee paid per transaction, the block height the transaction was included in, how many confirmations took place, and more.'
    },
    {
        title: 'Why develop an application on RioChain?',
        content: 'RioChain is a blockchain built for business. It’s secure, fast, scalable, and interoperable with other blockchains. With block times of approximately 2 seconds, transaction throughput of up to 3,000 transactions per second, and the ability to process native RioChain tokens, Bitcoin, Tether, and more, RioChain is well-equipped for any decentralized application to achieve mass adoption. '
    },
    {
        title: 'What types of projects are building on RioChain?',
        content: 'A variety of applications are currently being developed on RioChain, from finance to gaming, e-commerce, education, and more. '
    },
    {
        title: 'Create a RioWallet',
        content: `
            <div>1. Go to <a href="https://www.riodefi.com/wallet">www.riodefi.com/wallet</a> and click on the button “RioWallet” at the bottom left of the page.</div>
            <img class="faq-img" src="/wallet-faq/1-1.png"/><br/><br/>
            <div>2. You will be directed to a new webpage, click on “create a wallet” on the top right of the page and then double confirm by clicking again “create wallet’ in the pop up banner that appears on your screen.</div>
            <img class="faq-img" src="/wallet-faq/1-2.png"/><br/><br/>
            <div>3. Tick the box if you agree with RoDeFi’s ‘Terms of Services’ and ‘Privacy Policy’ after having read them, and click “next”.</div>
            <img class="faq-img" src="/wallet-faq/1-3.png"/><br/><br/>
            <div>4. Give a name to your wallet, choose a password, reconfirm it and click “next”. </div>
            <img class="faq-img" src="/wallet-faq/1-4.png"/><br/><br/>
            <div>5. In this new window, you will be asked to write down a list of words. This will be your ‘mnemonic phrase’ in case you lose the password to your wallet / keystore file backup (.JSON). In no case can RioDefi access this mnemonic phrase nor could send you a recovery password if you lose it. We recommend you to write it down in a non-digital format and save it in a safe place. Once it is done, click ‘next’.</div>
            <img class="faq-img" src="/wallet-faq/1-5.png"/><br/><br/>
            <div>6. Then reorder the mnemonic phrase in the order previously displayed. Get help from the notes you took at the previous step and then click ‘verify the mnemonic phrase’.</div>
            <img class="faq-img" src="/wallet-faq/1-6.png"/><br/><br/>
            <div>7. Next, please click on the button ‘Download KeyStore file’. Be sure to store this file in a secure place, as anyone who has this file plus your chosen password can access your RioWallet and all its funds.</div>
            <img class="faq-img" src="/wallet-faq/1-7.png"/><br/><br/>
            <div>8. Congratulations! You have just created a RioWallet! This is how your screen should appear.  </div>
            <img class="faq-img" src="/wallet-faq/1-8.png"/><br/><br/>
        `
    },
    {
        title: 'How to generate a deposit address',
        content: `
        <div>First time using a RioWallet? If so, before being able to deposit on it you must use our faucet to receive 1 FREE locked RFUEL to generate a deposit address. Locked RFUEL can only be used to pay for transaction fees; it cannot be transferred or traded.</div><br/><br/>

        <div>1. Click to “receive” on the top left of the window located at the right side of your screen and copy your RioWallet address:</div>
        <img class="faq-img" src="/wallet-faq/2-1.png"/><br/><br/>
        <div>2. Then click to “deposit” on the top of the window located at the right side of your screen.  </div>
        <img class="faq-img" src="/wallet-faq/2-2.png"/><br/><br/>
        <div>3. You must use our faucet to receive 1 FREE locked RFUEL to generate a deposit address (which requires 0.1 RFUEL). To do so, click on “To get RFUEL for generating the Deposit Address, click here”.</div>
        <img class="faq-img" src="/wallet-faq/2-3.png"/><br/><br/>
        <div>4. You will be redirected on a new page where you will be asked to paste your RioWallet address (which you previously copied) . Once done, click ‘Send me locked RFUEL”</div>
        <img class="faq-img" src="/wallet-faq/2-4.png"/><br/><br/>
        <div>5. Return to your RioWallet, you should see 1 RFUEL appearing under your balance and the same RFUEL appearing under Locked.</div>
        <img class="faq-img" src="/wallet-faq/2-5.png"/><br/><br/>
        <div>6. Now click on “Generic Deposit Address” and enter your password and click ‘confirm”. </div>
        <img class="faq-img" src="/wallet-faq/2-6.png"/><br/><br/>
        <div>7. You will then see that 0.1 RFUEL has been deducted from your balance.</div>
        <img class="faq-img" src="/wallet-faq/2-7.png"/><br/><br/>
        <div>Congratulations! You have generated your deposit address. Use this address to deposit RFUEL and/or other supported digital assets.</div>
        `
    },
    {
        title: 'RioWallet Tutorial | How to Deposit on RioWallet',
        content: `
        <div>Deposit is the act of transferring RFUEL from external ERC-20 wallets on Ethereum or from crypto exchanges to your RioWallet.  </div><br/><br/>
        <div>To deposit on your RioWallet, you must first generate a deposit address (please refer to the previous tutorial).  </div><br/><br/>
        <div>1. Go to deposit and copy your deposit address in order to send via your current ERC-20 compatible wallet or exchange account.  </div>
        <img class="faq-img" src="/wallet-faq/3-1.png"/><br/><br/>
        <div>2. Using your ERC-20 wallet (e.g. Metamask), make sure you select the cryptocurrency (match the one you want to send), then enter the amount you want to deposit, select the speed of your transaction, the associated fee, and confirm. </div>
        <img class="faq-img" src="/wallet-faq/3-2.png"/><br/><br/>
        <div>3. It may take a few minutes after confirmation on the native blockchain network before receiving the funds on your RioWallet.  </div>
        `
    },
    {
        title: 'RioWallet Tutorial | How to Withdraw from RioWallet',
        content: `
        <div>Withdrawing is the act of withdrawing RFUEL from RioWallet and the RioChain network back to the Ethereum network to an external ERC-20 cryptocurrency wallet, such as Metamask or Trust Wallet.</div><br/><br/>
        <div>1. Go to your RioWallet, click on “Withdraw”, paste the ERC-20 address you want to send the funds to as well as the amount you wish to withdraw, then click “withdraw”.</div>
        <img class="faq-img" src="/wallet-faq/4-1.png"/><br/><br/>
        <div>2. Enter your password and confirm</div>
        <img class="faq-img" src="/wallet-faq/4-2.png"/><br/><br/>
        <div>3. Once the withdrawal is recorded on RioChain, a pop up green window will appear at the top right side of your screen.</div>
        <img class="faq-img" src="/wallet-faq/4-3.png"/><br/><br/>
        <div>4. Because of the custodial nature of the cross-chain bridge, it may take up to 24 hours before you see the funds appearing in your ERC-20 address.  </div>
        `
    }
];

Language.ProductFaq.RioChain["Spanish"] = [
    {
        title: 'Qué es RioChain?',
        content: 'RioChain es una cadena de bloques de nueva generación construida sobre el sustrato de paridad, que permite la interoperabilidad entre cadenas. Esto significa que RioChain puede interactuar con activos de otras cadenas de bloques, como Bitcoin y Ethereum. Utilizando un modelo de consenso de prueba de autoridad de nodos de confianza, RioChain está diseñado para la seguridad, la velocidad (2s / bloque), la eficiencia (bajo costo de transacción y el uso de la electricidad), y la escalabilidad (3.000 + transacciones por segundo). '
    },
    {
        title: 'Qué es RioWallet?',
        content: 'Rio Wallet es un monedero de criptomonedas que está disponible tanto en teléfonos móviles como en navegadores web. Permite a los usuarios interactuar con RioChain para enviar y recibir criptodivisas, así como ver sus claves públicas y privadas. Las criptomonedas se almacenan de forma distribuida y se mantienen en libros de contabilidad de acceso público llamados blockchains. También cuenta con una sección de aplicaciones descentralizadas (dApp) para que los usuarios exploren varias dApps construidas sobre RioChain.'
    },
    {
        title: '¿Qué criptomonedas puede almacenar RioWallet?',
        content: 'Rio Wallet puede contener cualquier criptomoneda en RioChain. Debido a nuestra interoperabilidad entre cadenas, también puede contener activos de otras cadenas de bloques. Actualmente, Rio Wallet puede contener Bitcoin (BTC), Tether (USDT), Rio Fuel (RFUEL) y BIT Token (BIT).'
    },
    {
        title: '¿Qué es Rio Fuel (RFUEL)?',
        content: 'Rio Fuel (RFUEL) es la criptomoneda nativa de RioChain. Es una forma de dinero digital que se utiliza para pagar todas las transacciones en RioChain y alimentar sus aplicaciones.'
    },
    {
        title: '¿Cuántos Rio Fuel hay y cómo se distribuyen?',
        content: `
        Se crearán 1.000 millones de tokens Rio Fuel (RFUEL) a través de un evento de generación de tokens (TGE): el 70% de estos tokens se distribuirán como recompensas según el modelo que se indica a continuación, el 20% se venderá a través de preventas comunitarias y ventas privadas, y el 10% se mantendrá como reservas que se liberarán lentamente a lo largo de 5 años. <br/><br/>
        La RFUEL promocional (bloqueada) se distribuirá de la siguiente manera:  <br/><br/>
        · <strong>Incentivos para nuevos usuarios*</strong>: Se distribuirán 50 millones de RFUEL (5%) de forma gratuita a las nuevas Rio Wallets a razón de 10 RFUEL por cartera para los primeros 5 millones de carteras móviles únicas (basadas en los ID de los dispositivos). Tenga en cuenta que estos tokens promocionales de RFUEL estarán bloqueados, lo que significa que sólo pueden utilizarse para pagar las tasas de transacción, no pueden transferirse a otros usuarios y sólo serán válidos hasta el 31 de enero de 2026. Después de esa fecha, todos los tokens promocionales se quemarán automáticamente.<br/><br/>
        La RFUEL desbloqueada se distribuirá de la siguiente manera:<br/><br/>
        · <strong>Recompensas por Staking</strong>: Se distribuirán 350 millones de RFUEL (35%) a quienes apuesten RFUEL y validen transacciones en la red. La TAE generada a través del staking variará en función del año de staking y del % total de RFUEL apostado.<br/><br/>
        · <strong>Referidos, Airdrops y Recompensas</strong>: Se liberarán 50 millones de RFUEL (5%) por las referencias de la aplicación móvil Rio Wallet, los airdrops de intercambio y de la comunidad, y los programas de recompensas. Los usuarios de Rio Wallet que compartan la aplicación móvil Rio Wallet con sus amigos recibirán 5 RFUEL gratis, y sus amigos que descarguen la aplicación móvil Rio Wallet a través de códigos de recomendación también recibirán 5 RFUEL gratis (nota: los RFUEL por recomendación de un amigo se distribuirán como RFUEL bloqueados promocionales sujetos a los mismos términos que los incentivos para nuevos usuarios mencionados anteriormente). <br/><br/>
        · <strong>Equipo y asesores de RioDeFi</strong>: Se distribuirán 150 millones de RFUEL (15%) entre el equipo y los asesores de RioDeFi. Hay un calendario de adquisición de derechos de 2 años con un límite de 6 meses, lo que significa que el equipo y los asesores no recibirán tokens durante 6 meses y luego recibirán 1/18 de la cantidad total por mes durante los siguientes 18 meses.<br/><br/>
        · <strong>Fondo del Ecosistema de Río</strong>: Se distribuirán 100 millones de RFUEL (10%) en forma de subvenciones para financiar proyectos construidos dentro del ecosistema de la Cadena de Río. Las propuestas de subvención se presentarán a la Fundación Rio Technologies y serán votadas por sus consejeros. Estas subvenciones serán para dApps, módulos de tiempo de ejecución de la cadena, contratos inteligentes templados, etc. Todos los tokens del Fondo del Ecosistema de Río estarán bloqueados hasta la aprobación de la subvención.  <br/><br/>
        · <strong>Reservas</strong>: Se mantendrán 100 millones de RFUEL (10%) como reservas. 10 millones (10%) de estos tokens RFUEL de reserva se utilizarán para las provisiones de liquidez del mercado al inicio del TGE y se espera que permanezcan en Rio Technologies Inc. para su futura reasignación. El 90% restante se desbloqueará de forma continua a un ritmo de liberación lineal a partir del día 366 desde el TGE, de forma que el 100% de los tokens de reserva se desbloquearán al final del quinto año desde el TGE (aproximadamente 1.826 días desde el TGE).  <br/><br/>
        · <strong>Ventas privadas</strong>: se distribuirán 150 millones de RFUEL (15%) a los compradores de las ventas privadas. La mayoría de estos tokens de RFUEL se liberarán en 151 días, con 1/6 de los tokens distribuidos en el Evento de Generación de Tokens (TGE) y en los días 31, 61, 91, 121 y 151 después del TGE. El resto de estos tokens se desbloqueará en el TGE. <br/><br/>
        · <strong>Venta masiva comunitaria</strong>: se distribuirán 50 millones de RFUEL (5%) entre quienes compren RFUEL durante la venta masiva comunitaria.<br/><br/>
        <table class="faq-table">
            <thead>
                <tr>
                    <th>Distribución de RFUEL</th>
                    <th>Cantidad de fichas</th>
                    <th>Porcentaje (%)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Incentivos para nuevos usuarios (bloqueados)</td>
                    <td>50,000,000</td>
                    <td>5%</td>
                </tr>
                <tr>
                    <td>Recompensas de estacionamiento y validación</td>
                    <td>350,000,000</td>
                    <td>35%</td>
                </tr>
                <tr>
                    <td>Referencias, Airdrops y recompensas</td>
                    <td>50,000,000</td>
                    <td>5%</td>
                </tr>
                <tr>
                    <td>Equipo y asesores de RioDeFi</td>
                    <td>150,000,000</td>
                    <td>15%</td>
                </tr>
                <tr>
                    <td>Fondo del Ecosistema de Rio</td>
                    <td>100,000,000</td>
                    <td>10%</td>
                </tr>
                <tr>
                    <td>Reservas</td>
                    <td>100,000,000</td>
                    <td>10%</td>
                </tr>
                <tr>
                    <td>Ventas privadas</td>
                    <td>150,000,000</td>
                    <td>15%</td>
                </tr>
                <tr>
                    <td>Ventas colectivas comunitarias</td>
                    <td>50,000,000</td>
                    <td>5%</td>
                </tr>
              
                <tr>
                    <td><strong>Total</strong></td>
                    <td><strong>1,000,000,000</strong></td>
                    <td><strong>100%</strong></td>
                </tr>
            </tbody>
        </table><br/><br/>
        <strong>Distribución de las recompensas por staking y validación:</strong><br/><br/>
        <table class="faq-table">
        <thead>
            <tr>
                <th>Año de estacionamiento</th>
                <th>Oferta en circulación Est.</th>
                <th>Recompensas entregadas</th>
                <th>TAE media (%)</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>2020 - 2021</td>
                <td>170,000,000</td>
                <td>60,000,000</td>
                <td>60%</td>
            </tr>
            <tr>
                <td>2022</td>
                <td>370,000,000</td>
                <td>100,000,000</td>
                <td>54%</td>
            </tr>
            <tr>
                <td>2023</td>
                <td>600,000,000</td>
                <td>110,000,000</td>
                <td>37%</td>
            </tr>
            <tr>
                <td>2024</td>
                <td>730,000,000</td>
                <td>50,000,000</td>
                <td>14%</td>
            </tr>
            <tr>
                <td>2025</td>
                <td>890,000,000</td>
                <td>30,000,000</td>
                <td>7%</td>
            </tr>
            <tr>
                <td>2026</td>
                <td>950,000,000</td>
                <td>0</td>
                <td>0%</td>
            </tr>
        </tbody>
    </table><br/><br/>
    <strong>Notas de estimación / Supuestos:</strong><br/><br/>
    · El suministro circulante est. es la cantidad estimada al principio de cada año  <br/><br/>
    · La TAE media. La TAE se estima sobre la base de una tasa de estacionamiento del 50% de la oferta circulante
    `
    },
    {
        title: '¿Puedo comprar más Rio Fuel cuando se me acabe?',
        content: 'Sí, Rio Fuel (RFUEL) se puede comprar dentro de las aplicaciones descentralizadas (dApps) participantes de RioChain. El precio inicial de RFUEL es de 0,14 USD. En un futuro próximo, RFUEL también estará disponible para su compra en determinadas bolsas y servicios extrabursátiles (OTC).'
    },
    {
        title: '¿Qué es el explorador de bloques de Río?',
        content: 'El Explorador de Bloques de Río permite a cualquier persona supervisar la red y ver los recibos de las transacciones. Puede introducir una dirección de Rio Wallet para ver su contenido y ver todas sus transacciones dentro de la red. Rio Block Explorer ofrece información sobre la comisión pagada por transacción, la altura del bloque en el que se incluyó la transacción, cuántas confirmaciones se produjeron y mucho más.',
    },
    {
        title: '¿Por qué desarrollar una aplicación en RioChain?',
        content: 'RioChain es una cadena de bloques construida para los negocios. Es segura, rápida, escalable e interoperable con otras cadenas de bloques. Con tiempos de bloqueo de aproximadamente 2 segundos, rendimiento de transacciones de hasta 3.000 transacciones por segundo, y la capacidad de procesar tokens nativos de RioChain, Bitcoin, Tether, y más, RioChain está bien equipada para que cualquier aplicación descentralizada logre una adopción masiva.'
    },
    {
        title: '¿Qué tipo de proyectos se están construyendo en RioChain?',
        content: 'Actualmente se están desarrollando diversas aplicaciones en RioChain, desde las finanzas hasta los juegos, el comercio electrónico y la educación, entre otras.',
    },
    {
        title: 'Crear una RioWallet',
        content: `
            <div>1. Acceda <a href="https://www.riodefi.com/wallet">www.riodefi.com/wallet</a> y haga clic en el botón "RioWallet" situado en la parte inferior izquierda de la página.</div>
            <img class="faq-img" src="/wallet-faq/1-1.png"/><br/><br/>
            <div>2. Serás dirigido a una nueva página web, haz clic en "crear un monedero" en la parte superior derecha de la página y luego confirma doblemente haciendo clic de nuevo en "crear monedero' en el banner emergente que aparece en tu pantalla.</div>
            <img class="faq-img" src="/wallet-faq/1-2.png"/><br/><br/>
            <div>3. Marque la casilla si está de acuerdo con las "Condiciones de servicio" y la "Política de privacidad" de RioDeFi después de haberlas leído, y haga clic en "siguiente".</div>
            <img class="faq-img" src="/wallet-faq/1-3.png"/><br/><br/>
            <div>4. Dé un nombre a su monedero, elija una contraseña, reconfírmela y haga clic en "siguiente". </div>
            <img class="faq-img" src="/wallet-faq/1-4.png"/><br/><br/>
            <div>5. En esta nueva ventana, se le pedirá que escriba una lista de palabras. Esta será su "frase de memoria" en caso de que pierda la contraseña de su cartera / copia de seguridad del archivo keystore (.JSON). En ningún caso RioDefi podrá acceder a esta frase mnemónica ni podrá enviarte una contraseña de recuperación si la pierdes. Te recomendamos que la escribas en un formato no digital y la guardes en un lugar seguro. Una vez hecho esto, haz clic en 'siguiente'.</div>
            <img class="faq-img" src="/wallet-faq/1-5.png"/><br/><br/>
            <div>6. A continuación, reordena la frase memónica en el orden que aparece anteriormente. Ayúdate de las notas que tomaste en el paso anterior y luego haz clic en 'verificar la frase de memoria'.</div>
            <img class="faq-img" src="/wallet-faq/1-6.png"/><br/><br/>
            <div>7. A continuación, haga clic en el botón 'Descargar archivo KeyStore'. Asegúrese de guardar este archivo en un lugar seguro, ya que cualquiera que tenga este archivo más la contraseña elegida podrá acceder a su RioWallet y a todos sus fondos.</div>
            <img class="faq-img" src="/wallet-faq/1-7.png"/><br/><br/>
            <div>8. Felicidades! Acaba de crear un RioWallet. Así es como debería aparecer su pantalla. </div>
            <img class="faq-img" src="/wallet-faq/1-8.png"/><br/><br/>
        `
    },
    {
        title: '¿Cómo generar una dirección de depósito?',
        content: `
        <div>Es la primera vez que utiliza una RioWallet? Si es así, antes de poder depositar en ella debe utilizar nuestro grifo para recibir 1 RFUEL bloqueada GRATIS para generar una dirección de depósito. La RFUEL bloqueada sólo se puede utilizar para pagar las tasas de transacción; no se puede transferir ni comerciar con ella. </div><br/><br/>
        <div>1. Haga clic en "recibir" en la parte superior izquierda de la ventana situada en la parte derecha de su pantalla y copie su dirección de RioWallet:</div>
        <img class="faq-img" src="/wallet-faq/2-1.png"/><br/><br/>
        <div>2. A continuación, haga clic en "depositar" en la parte superior de la ventana situada en el lado derecho de su pantalla.</div>
        <img class="faq-img" src="/wallet-faq/2-2.png"/><br/><br/>
        <div>3. Debe utilizar nuestro grifo para recibir 1 RFUEL bloqueado GRATIS para generar una dirección de depósito (que requiere 0,1 RFUEL). Para ello, haga clic en "Para obtener RFUEL para generar la dirección de depósito, haga clic aquí".</div>
        <img class="faq-img" src="/wallet-faq/2-3.png"/><br/><br/>
        <div>4. Se le redirigirá a una nueva página en la que se le pedirá que pegue su dirección RioWallet (que ha copiado previamente) . Una vez hecho esto, haga clic en "Enviarme la RFUEL bloqueada"</div>
        <img class="faq-img" src="/wallet-faq/2-4.png"/><br/><br/>
        <div>5. Vuelve a tu RioWallet, deberías ver que aparece 1 RFUEL en tu saldo y la misma RFUEL en Bloqueado.</div>
        <img class="faq-img" src="/wallet-faq/2-5.png"/><br/><br/>
        <div>6. Ahora haga clic en "Dirección de depósito genérico" e introduzca su contraseña y haga clic en 'confirmar'.</div>
        <img class="faq-img" src="/wallet-faq/2-6.png"/><br/><br/>
        <div>7. A continuación, verá que se ha deducido 0,1 RFUEL de su saldo.</div>
        <img class="faq-img" src="/wallet-faq/2-7.png"/><br/><br/>
        <div>¡Enhorabuena! Ha generado su dirección de depósito. Utilice esta dirección para depositar RFUEL y/o otros activos digitales compatibles.</div>
        `
    },
    {
        title: 'Tutorial de RioWallet ¿Cómo depositar?',
        content: `
        <div>El depósito es el acto de transferir RFUEL desde carteras ERC-20 externas en Ethereum o desde bolsas de criptomonedas a su RioWallet. </div><br/><br/>
        <div>Para depositar en su RioWallet, primero debe generar una dirección de depósito (consulte el tutorial anterior). </div><br/><br/>
        <div>1. Vaya a depositar y copie su dirección de depósito para enviar a través de su actual cartera compatible con ERC-20 o cuenta de intercambio. </div>
        <img class="faq-img" src="/wallet-faq/3-1.png"/><br/><br/>
        <div>2. Utilizando su monedero ERC-20 (por ejemplo, Metamask), asegúrese de seleccionar la criptodivisa (que coincida con la que desea enviar), luego introduzca la cantidad que desea depositar, seleccione la velocidad de su transacción, la tarifa asociada y confirme.</div>
        <img class="faq-img" src="/wallet-faq/3-2.png"/><br/><br/>
        <div>3. Pueden pasar unos minutos después de la confirmación en la red nativa de blockchain antes de recibir los fondos en su RioWallet. </div>
        `
    },
    {
        title: 'Tutorial de RioWallet ¿Cómo retirar dinero de RioWallet?',
        content: `
        <div>Retirar es el acto de retirar RFUEL de RioWallet y de la red RioChain de vuelta a la red Ethereum a una cartera de criptodivisas ERC-20 externa, como Metamask o Trust Wallet.</div><br/><br/>
        <div>1. Vaya a su RioWallet, haga clic en "Retirar", pegue la dirección ERC-20 a la que desea enviar los fondos, así como la cantidad que desea retirar, y luego haga clic en "retirar".</div>
        <img class="faq-img" src="/wallet-faq/4-1.png"/><br/><br/>
        <div>2. Introduzca su contraseña y confirme</div>
        <img class="faq-img" src="/wallet-faq/4-2.png"/><br/><br/>
        <div>3. Una vez que el retiro se registra en RioChain, una ventana verde emergente aparecerá en la parte superior derecha de su pantalla.</div>
        <img class="faq-img" src="/wallet-faq/4-3.png"/><br/><br/>
        <div>4. Debido a la naturaleza de la custodia del puente de la cadena cruzada, pueden pasar hasta 24 horas antes de que vea los fondos aparecer en su dirección ERC-20. </div>
        `
    }
];

Language.ProductFaq.RioChain["中文"] = [
    {
        title: '什么是Rio链？',
        content: "Rio链是基于Parity Substrate的下一代区块链，启用可操作的交叉链。这意味着Rio链可以进行跨链资产交互，比如比特币，以太币等。使用可信任权威节点授权模型。Rio链被设计为安全的，高速的、高效率的、可扩展的区块链"
    },
    {
        title: '什么是Rio钱包？',
        content: "Rio钱包是一个可以运行在手机和浏览器上的加密货币钱包。它允许用户在Rio链上进行加密货币收发的交互操作。以及可以查看自己的公私钥。这个加密货币以分布式方式存储并保存在公共账本上。它还提供了一个去中心化的(dapp)部分，供用户在Rio链上浏览各种dapps。"
    },
    {
        title: 'Rio钱包可以存放哪些加密货币？',
        content: "Rio钱包可以存放在Rio链上的任何加密货币，由于可跨链交互的特性，他一样能够存放其它区块链的货币。当前，Rio钱包可以存放BitCoin(BTC),Tether(USDT),Rio Fuel(Rfuel)和Bit Token(BIT)."
    },
    {
        title: '什么是Rio Fuel(RFUEL)？',
        content: "Rio Fuel(RFUEL)是Rio链原生的加密货币。它是一个可以用于支付所有交易在Rio链上apps的手续费的数字货币。"
    },
    {
        title: '有多少里Rio燃料，它是如何分布的？',
        content: `
        10亿个Rio Fuel（RFUEL）代币是预发行的。其中75%的代币是根据下面的提纲作为奖励免费分发的。其中20%将通过社区来筹集资金，5%将预留给RioDeFi种子投资者。<br/><br/>
        RFUEL分发方式如下：<br/><br/>
        · <strong>Rio移动钱包下载</strong>: 1亿RFUEL（10%）将被免费分配给新的Rio钱包，前100万按100个RFUEL/钱包进行按比例分配（基于独立设备ID）。 <br/><br/>
        · <strong>推荐</strong>: 1亿RFUEL（10%）将被释放于Rio移动钱包推荐计划。Rio钱包用户可以分享Rio钱包给他们的朋友，将会获得50个免费的RFUEL，和他们的朋友通过推荐码下载Rio钱包后也会获得50个免费的RFUEL。 <br/><br/>
        注:这些奖励的RFUEL代币将被锁定，这意味着它们只能用于支付交易费用，不能转让给其他用户，有效期仅至2026年1月31日。<br/><br/>
        · <strong>质押奖励</strong>: 3.5亿RFUEL（35%）将被用于分发给质押RFUEL代币。通过质押产生的年利率，将根据质押年限和总质押RFUEL百分比而变化。<br/><br/>
        · <strong>社群众筹</strong>: 2亿RFUEL（20%）将被分配于社区众筹期间购买RFUEL的人。<br/><br/>
        · <strong>RioDeFi种子投资者</strong>: 5000万 RFUEL (5%) 将分布给RioDeFi种子投资人. 这些RFUEL将锁定6个月，每月解锁1/6的代币。<br/><br/>
        · <strong>Rio链节点运营者</strong>: 2亿RFUEL（20%）将被分配运营Rio链节点，作为保护网络安全，处理交易和所有dApps托管。1亿将在RFUEL 代币上市时发放，其余1亿将在一年后发放。<br/><br/>
        RFUEL代币通过质押，社区众筹产生，节点运营一旦发布将会被完全解锁。<br/><br/>
        <table class="faq-table">
            <thead>
                <tr>
                    <th>RFUEL 分配</th>
                    <th>代币数量</th>
                    <th>百分比 (%)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Rio钱包移动APP下载</td>
                    <td>100,000,000</td>
                    <td>10%</td>
                </tr>
                <tr>
                    <td>Rio钱包推荐</td>
                    <td>100,000,000</td>
                    <td>10%</td>
                </tr>
                <tr>
                    <td>质押回报</td>
                    <td>350,000,000</td>
                    <td>35%</td>
                </tr>
                <tr>
                    <td>社区众筹</td>
                    <td>200,000,000</td>
                    <td>20%</td>
                </tr>
                <tr>
                    <td>RioDeFi种子投资人</td>
                    <td>50,000,000</td>
                    <td>5%</td>
                </tr>
                <tr>
                    <td>Rio链节点运营者</td>
                    <td>200,000,000</td>
                    <td>20%</td>
                </tr>
                <tr>
                    <td><strong>总计</strong></td>
                    <td><strong>1,000,000,000</strong></td>
                    <td><strong>100%</strong></td>
                </tr>
            </tbody>
        </table><br/><br/>
        注：解锁和购买RFUEL代币没有固定期限（通过dApps或者二级市场）。所有未分配的RFUEL将被重新分配回RFUEL池，以作为未来再分配使用。<br/><br/>
        未来的分配: 剩下的XX百万RFUEL余额，包括所有未分发或失效的推广RFUEL，将会基于一个推广公式免费分配给RIO钱包用户，由RIO链节点运营者决定和投票（那些确保网络安全并在Rio链上提供算力，存储空间以及交易和智能合约验证的人员）。<br/><br/>
        <table class="faq-table">
        <thead>
            <tr>
                <th>质押年限</th>
                <th>供应量估算</th>
                <th>奖励分配</th>
                <th>年利率 (%)</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>2021</td>
                <td>300,000,000</td>
                <td>100,000,000</td>
                <td>67%</td>
            </tr>
            <tr>
                <td>2022</td>
                <td>510,000,000</td>
                <td>90,000,000</td>
                <td>30%</td>
            </tr>
            <tr>
                <td>2023</td>
                <td>660,000,000</td>
                <td>80,000,000</td>
                <td>24%</td>
            </tr>
            <tr>
                <td>2024</td>
                <td>800,000,000</td>
                <td>50,000,000</td>
                <td>12%</td>
            </tr>
            <tr>
                <td>2025</td>
                <td>910,000,000</td>
                <td>30,000,000</td>
                <td>6%</td>
            </tr>
            <tr>
                <td>2026</td>
                <td>1,000,000,000</td>
                <td>0</td>
                <td>0%</td>
            </tr>
        </tbody>
    </table><br/><br/>
    估算说明/假设： <br/><br/>
    · 供应量估算指每年年初的估计数量。<br/><br/>
    · 钱包下载和推荐估计线性为每年30万(每次3000万个RFUEL代币，每年6000万个)。<br/><br/>
    · 平均 APR是根据流通量供应的50％质押率估算的。
    `
    },
    {
        title: '当我用完Rio Fuel后我可以购买更多吗？',
        content: "是的，Rio燃料(RFUEL)可以在Rio链参与的分散应用程序(dApps)中购买。RFUEL的起价为0.14美元。在不久的将来，RFUEL币也可以在特定的交易所和场外交易(OTC)服务上购买。 "
    },
    {
        title: '什么是Rio区块浏览器？',
        content: "Rio区块浏览器允许任何人监视网络和查看交易收据。您可以输入一个Rio钱包地址来查看它的内容并查看它在网络中的所有交易。Rio块资源管理器提供了关于每个事务所支付的费用、事务所包含的块高度、发生了多少确认，以及更多的信息。"
    },
    {
        title: '为什么要在Rio链上开发应用程序？',
        content: "Rio连锁店是为商用而建的区块链。它是安全的，快速的，可扩展的，并与其他区块链互操作。由于块时间大约为2秒，每秒的事务吞吐量最高可达3000个事务，并且能够处理本地Rio链令牌、比特币、Tether等，Rio链为任何去中心化应用程序实现大规模采用提供了良好的装备。"
    },
    {
        title: '在Rio链上构建了哪些类型的项目？',
        content: "目前Rio链上正在开发各种应用程序，从金融到游戏、电子商务、教育等等。"
    },
    {
        title: 'Create a RioWallet',
        content: `
            <div>1. Go to <a href="https://www.riodefi.com/wallet">www.riodefi.com/wallet</a> and click on the button “RioWallet” at the bottom left of the page.</div>
            <img class="faq-img" src="/wallet-faq/1-1.png"/><br/><br/>
            <div>2. You will be directed to a new webpage, click on “create a wallet” on the top right of the page and then double confirm by clicking again “create wallet’ in the pop up banner that appears on your screen.</div>
            <img class="faq-img" src="/wallet-faq/1-2.png"/><br/><br/>
            <div>3. Tick the box if you agree with RoDeFi’s ‘Terms of Services’ and ‘Privacy Policy’ after having read them, and click “next”.</div>
            <img class="faq-img" src="/wallet-faq/1-3.png"/><br/><br/>
            <div>4. Give a name to your wallet, choose a password, reconfirm it and click “next”. </div>
            <img class="faq-img" src="/wallet-faq/1-4.png"/><br/><br/>
            <div>5. In this new window, you will be asked to write down a list of words. This will be your ‘mnemonic phrase’ in case you lose the password to your wallet / keystore file backup (.JSON). In no case can RioDefi access this mnemonic phrase nor could send you a recovery password if you lose it. We recommend you to write it down in a non-digital format and save it in a safe place. Once it is done, click ‘next’.</div>
            <img class="faq-img" src="/wallet-faq/1-5.png"/><br/><br/>
            <div>6. Then reorder the mnemonic phrase in the order previously displayed. Get help from the notes you took at the previous step and then click ‘verify the mnemonic phrase’.</div>
            <img class="faq-img" src="/wallet-faq/1-6.png"/><br/><br/>
            <div>7. Next, please click on the button ‘Download KeyStore file’. Be sure to store this file in a secure place, as anyone who has this file plus your chosen password can access your RioWallet and all its funds.</div>
            <img class="faq-img" src="/wallet-faq/1-7.png"/><br/><br/>
            <div>8. Congratulations! You have just created a RioWallet! This is how your screen should appear.  </div>
            <img class="faq-img" src="/wallet-faq/1-8.png"/><br/><br/>
        `
    },
    {
        title: 'How to generate a deposit address',
        content: `
        <div>First time using a RioWallet? If so, before being able to deposit on it you must use our faucet to receive 1 FREE locked RFUEL to generate a deposit address. Locked RFUEL can only be used to pay for transaction fees; it cannot be transferred or traded.</div><br/><br/>

        <div>1. Click to “receive” on the top left of the window located at the right side of your screen and copy your RioWallet address:</div>
        <img class="faq-img" src="/wallet-faq/2-1.png"/><br/><br/>
        <div>2. Then click to “deposit” on the top of the window located at the right side of your screen.  </div>
        <img class="faq-img" src="/wallet-faq/2-2.png"/><br/><br/>
        <div>3. You must use our faucet to receive 1 FREE locked RFUEL to generate a deposit address (which requires 0.1 RFUEL). To do so, click on “To get RFUEL for generating the Deposit Address, click here”.</div>
        <img class="faq-img" src="/wallet-faq/2-3.png"/><br/><br/>
        <div>4. You will be redirected on a new page where you will be asked to paste your RioWallet address (which you previously copied) . Once done, click ‘Send me locked RFUEL”</div>
        <img class="faq-img" src="/wallet-faq/2-4.png"/><br/><br/>
        <div>5. Return to your RioWallet, you should see 1 RFUEL appearing under your balance and the same RFUEL appearing under Locked.</div>
        <img class="faq-img" src="/wallet-faq/2-5.png"/><br/><br/>
        <div>6. Now click on “Generic Deposit Address” and enter your password and click ‘confirm”. </div>
        <img class="faq-img" src="/wallet-faq/2-6.png"/><br/><br/>
        <div>7. You will then see that 0.1 RFUEL has been deducted from your balance.</div>
        <img class="faq-img" src="/wallet-faq/2-7.png"/><br/><br/>
        <div>Congratulations! You have generated your deposit address. Use this address to deposit RFUEL and/or other supported digital assets.</div>
        `
    },
    {
        title: 'RioWallet Tutorial | How to Deposit on RioWallet',
        content: `
        <div>Deposit is the act of transferring RFUEL from external ERC-20 wallets on Ethereum or from crypto exchanges to your RioWallet.  </div><br/><br/>
        <div>To deposit on your RioWallet, you must first generate a deposit address (please refer to the previous tutorial).  </div><br/><br/>
        <div>1. Go to deposit and copy your deposit address in order to send via your current ERC-20 compatible wallet or exchange account.  </div>
        <img class="faq-img" src="/wallet-faq/3-1.png"/><br/><br/>
        <div>2. Using your ERC-20 wallet (e.g. Metamask), make sure you select the cryptocurrency (match the one you want to send), then enter the amount you want to deposit, select the speed of your transaction, the associated fee, and confirm. </div>
        <img class="faq-img" src="/wallet-faq/3-2.png"/><br/><br/>
        <div>3. It may take a few minutes after confirmation on the native blockchain network before receiving the funds on your RioWallet.  </div>
        `
    },
    {
        title: 'RioWallet Tutorial | How to Withdraw from RioWallet',
        content: `
        <div>Withdrawing is the act of withdrawing RFUEL from RioWallet and the RioChain network back to the Ethereum network to an external ERC-20 cryptocurrency wallet, such as Metamask or Trust Wallet.</div><br/><br/>
        <div>1. Go to your RioWallet, click on “Withdraw”, paste the ERC-20 address you want to send the funds to as well as the amount you wish to withdraw, then click “withdraw”.</div>
        <img class="faq-img" src="/wallet-faq/4-1.png"/><br/><br/>
        <div>2. Enter your password and confirm</div>
        <img class="faq-img" src="/wallet-faq/4-2.png"/><br/><br/>
        <div>3. Once the withdrawal is recorded on RioChain, a pop up green window will appear at the top right side of your screen.</div>
        <img class="faq-img" src="/wallet-faq/4-3.png"/><br/><br/>
        <div>4. Because of the custodial nature of the cross-chain bridge, it may take up to 24 hours before you see the funds appearing in your ERC-20 address.  </div>
        `
    }
];
Language.ProductFaq.General = {};
Language.ProductFaq.General['English'] = [
    {
        title: 'What is a Blockchain?',
        content: 'A blockchain is an open, distributed ledger that can record transactions between two parties efficiently and in a verifiable, permanent way. Each block contains a cryptographic hash of the previous block, a timestamp, and transaction data.'
    },
    {
        title: 'What is a Cryptocurrency?',
        content: 'A cryptocurrency is a digital asset designed to work as a medium of exchange that uses strong cryptography to secure financial transactions, control the creation of additional units, and verify the transfer of assets. Most cryptocurrencies are controlled in a decentralized manner, which is different from the traditional financial system comprised of centralized fiat currencies and banking systems. The decentralized control of each cryptocurrency works through distributed ledger technology, typically a blockchain, that serves as a public financial transaction database.',
    },
    {
        title: 'What is a Smart Contract?',
        content: 'A blockchain-based smart contract is a computerized transaction protocol that executes the terms of a contract, automating the execution of specific terms of agreement between parties in an objective way. '
    }
]

Language.ProductFaq.General['Spanish'] = [
    {
        title: '¿Qué es la cadena de bloques?',
        content: 'Una cadena de bloques es un libro de contabilidad abierto y distribuido que puede registrar las transacciones entre dos partes de forma eficiente, verificable y permanente. Cada bloque contiene un código criptográfico del bloque anterior, una marca de tiempo y los datos de la transacción.'
    },
    {
        title: '¿Qué es una criptomoneda?',
        content: 'Una criptomoneda es un activo digital diseñado para funcionar como medio de intercambio que utiliza una criptografía fuerte para asegurar las transacciones financieras, controlar la creación de unidades adicionales y verificar la transferencia de activos. La mayoría de las criptomonedas se controlan de forma descentralizada, lo que difiere del sistema financiero tradicional compuesto por monedas fiduciarias y sistemas bancarios centralizados. El control descentralizado de cada criptodivisa funciona a través de la tecnología de libro mayor distribuido, normalmente una cadena de bloques, que sirve como base de datos pública de transacciones financieras.',
    },
    {
        title: '¿Qué es un contrato inteligente?',
        content: 'Un contrato inteligente basado en blockchain es un protocolo de transacción computarizado que ejecuta los términos de un contrato, automatizando la ejecución de términos específicos de acuerdo entre las partes de una manera objetiva.'
    }
]
Language.ProductFaq.General['中文'] = [
    {
        title: '什么是区块链？',
        content: "区块链是一个开放的、分布式的账本，它可以以一种可验证的、永久的方式有效地记录双方之间的交易。每个块包含前一个块的加密散列、时间戳和事务数据。"
    },
    {
        title: '什么是加密货币?',
        content: "加密货币是一种数字资产，其设计用途是作为一种交换媒介，使用强大的加密技术来确保金融交易的安全，控制额外单元的创建，并验证资产的转移。大多数加密货币是以去中心化方式控制的，这不同于中心化的法定货币和银行系统组成的传统金融系统。对每一种加密货币的分散控制是通过分布式账本技术实现的，通常是作为公共金融交易数据库的区块链。"
    },
    {
        title: '什么是智能合约？',
        content: '基于区块链的智能合约是一种执行合同条款的计算机化交易协议，以客观的方式自动执行各方之间的特定协议条款。'
    }
]


export default Language;
