import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import ProductAbout from './modules/views/ProductAbout';
import Ourteam from './modules/views/Ourteam';
import AppAppBar from './modules/views/AppAppBar';
import Careers from './modules/views/Careers';
import AppFooter from './modules/views/AppFooter';
import MAbout from "./mobile/views/About";


// function Index() {
//   return (
//     <React.Fragment>
//       <AppAppBar about="1"/>
//       <ProductAbout />
//       <Ourteam />
//       <Careers />
//       <AppFooter />
//     </React.Fragment>
//   );
// }

class Index extends React.Component {
  IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod"
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  render() {
    return (
      true === this.IsPC() ? (
        <React.Fragment>
          <AppAppBar about="1" />
          <ProductAbout />
          <Ourteam />
          <Careers />
          <AppFooter />
        </React.Fragment>
      ) : (
          <MAbout />
        )
    )
  }
}

export default withRoot(Index);
