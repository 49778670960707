import React, { useState } from "react";
import { Modal, Input, Select } from "antd";
import axios from "axios";
import SelectSuffix from "../../../assets/img/select-suffix.svg";
import ArrowRight from "../../../assets/img/card/arrow-right.svg";
import constants from "../../../constants";
import style from "./style.module.scss";

export default function WhitelistModal({ onCancel }) {
  const [step, setStep] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState(null);
  const [agree, setAgree] = useState(false);
  const [email, setEmail] = useState("");
  const [errObj, setErrObj] = useState({});
  const [loading, setLoading] = useState(false);

  const checkParams = () => {
    let flag = true;
    setErrObj({});

    if (!firstName) {
      setErrObj((prev) => ({
        ...prev,
        firstName: "Please enter your first name",
      }));
      flag = false;
    }
    if (!lastName) {
      setErrObj((prev) => ({
        ...prev,
        lastName: "Please enter your last name",
      }));
      flag = false;
    }
    if (!country) {
      setErrObj((prev) => ({
        ...prev,
        country: "Please select your country",
      }));
      flag = false;
    }
    if (
      !email ||
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)
    ) {
      setErrObj((prev) => ({
        ...prev,
        email: "Please enter valid e-mail address",
      }));
      flag = false;
    }

    if (!agree) {
      setErrObj((prev) => ({
        ...prev,
        agree: "Please agree terms of use and privacy policy",
      }));
      flag = false;
    }

    if (flag) {
      return true;
    } else {
      return false;
    }
  };

  const doSubscribe = async () => {
    if (checkParams()) {
      setLoading(true);
      const result = await axios.post(`${constants.collectApi}/record/create`, {
        firstName,
        lastName,
        country,
        email,
      });
      setLoading(false);
      if (result.data.status) {
        setStep(1);
      }
    }
  };

  return (
    <Modal onCancel={onCancel} footer={null} width={720} visible={true}>
      <div className={style.whitelistModal}>
        {step === 0 && (
          <>
            <div className={style.title}>
              Rio<span className={style.highlight}>Card</span> is coming soon
            </div>
            <div className={style.desc}>
              Apply now before the wait list is filled
            </div>
            <div className={style.form}>
              <div className={style.nameWrapper}>
                <div className={style.formItem}>
                  <div className={style.label}>First name*</div>
                  <Input
                    className={style.formInput}
                    placeholder="First name*"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  {errObj.firstName && (
                    <div className={style.errMsg}>{errObj.firstName}</div>
                  )}
                </div>

                <div className={style.formItem}>
                  <div className={style.label}>Last name*</div>
                  <Input
                    className={style.formInput}
                    placeholder="Last name*"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {errObj.lastName && (
                    <div className={style.errMsg}>{errObj.lastName}</div>
                  )}
                </div>
              </div>

              <div className={style.formItem}>
                <div className={style.label}>Country*</div>
                <Select
                  value={country}
                  suffixIcon={<img src={SelectSuffix} />}
                  onChange={(e) => setCountry(e)}
                  className={[style.formSelect, "country-select"]}
                  placeholder="Please select your country"
                  dropdownClassName="country-select-dropdown"
                >
                  {constants.countries.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
                {errObj.country && (
                  <div className={style.errMsg}>{errObj.country}</div>
                )}
              </div>

              <div className={style.formItem}>
                <div className={style.label}>Email*</div>
                <Input
                  value={email}
                  className={style.formInput}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errObj.email && (
                  <div className={style.errMsg}>{errObj.email}</div>
                )}
              </div>

              <div
                className={style.agree}
                onClick={() => setAgree((prev) => !prev)}
              >
                <div
                  className={`${style.checkbox} ${agree && style.checked}`}
                />
                I agree to RioDefi's terms of use and privacy policy.
              </div>
              {errObj.agree && (
                <div className={style.errMsg}>{errObj.agree}</div>
              )}
            </div>

            <a
              className={`${style.btnBlue} ${(!firstName ||
                !lastName ||
                !country ||
                !email ||
                !agree) &&
                style.btnDisabled}`}
              onClick={doSubscribe}
            >
              {loading ? (
                "Submitting..."
              ) : (
                <>
                  Submit <img src={ArrowRight} width="12" />
                </>
              )}
            </a>
          </>
        )}
        {step === 1 && (
          <div className={style.success}>
            <div className={style.title}>
              Thank you for registering, we will be in{" "}
              <span className={style.highlight}>touch</span> shortly.
            </div>
            {/* <a className={style.btnBlue} onClick={onCancel}>
              Done <img src={ArrowRight} width="12" />
            </a> */}
          </div>
        )}
      </div>
    </Modal>
  );
}
