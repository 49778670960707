// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";

class Index extends React.Component {
  state = {};

  componentDidMount() {}

  render() {
    const lang = Language.CurLanguage();

    return (
      <div className={"column center"} style={{ backgroundColor: "#fff", margin: '64px auto 32px' }}>
        <span
          dangerouslySetInnerHTML={{__html: Language.Home.Ecosystem.Title[lang]}}
          style={{
            fontSize: apx(36),
            color: '#002060',
            padding: '8px 18px',
            fontWeight: 600,
          }}
        >
        </span>
        {/* <span
          style={{
            fontSize: apx(22),
            color: "#5A5A5A",
            lineHeight: apx(30),
            width: apx(600),
            textAlign: "center",
            marginTop: apx(40)
          }}
        >
          {Language.Home.Ecosystem.Content1[lang]}
        </span> */}

        {/* <img
          src={
            lang === "English"
              ? require("../assets/ecos_en.png")
              : require("../assets/ecos_zh.png")
          }
          alt=""
          style={{
            width: apx(710),
            height: apx(322),
            marginTop: apx(90),
            marginBottom: apx(100)
          }}
        /> */}
      </div>
    );
  }
}

export default Index;
