import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppAppBar from './modules/views/AppAppBar';
import Divider from '@material-ui/core/Divider';
import AppFooter from './modules/views/AppFooter';
import MMoonpay from "./mobile/views/MMoonpay";
// function Index() {
//   return (
//     <React.Fragment>
//       <AppAppBar wallet="1"/>
//       <Divider />
//       <ProductWallet />
//       <AppFooter />
//     </React.Fragment>
//   );
// }

const iframeWrapperStyle = {
  background: "url('/moonpay-bg.png') no-repeat",
  backgroundSize: '100% 100%',
  padding: '88px 0',
  textAlign: 'center'
}

const iframeStyle = {
  width: '500px',
  height: '700px',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  border: 0,
  overflow: 'hidden',
  background: '#fff'
}

class Index extends React.Component {
  IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod"
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  render() {
    return ((true === this.IsPC() ? (
      <React.Fragment>
        <AppAppBar moonpay="1" />
        <Divider />
        <div style={iframeWrapperStyle}>
        <iframe
          style={iframeStyle}
          allow="accelerometer; autoplay; camera; gyroscope; payment"
          scrolling="no"
          frameborder="0"
          src="https://buy.moonpay.io/?apiKey=pk_live_sPRMDnKpUTez87jURbtB6GtTvROEuCA"
        >
        </iframe>
        </div>
        <AppFooter />
      </React.Fragment>
    ) : (
        <MMoonpay />
      )
    )
    );
  }
}
export default withRoot(Index);
