// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";
import { Link } from "@material-ui/core";

class Index extends React.Component {
  state = {};

  componentDidMount() { }

  render() {
    const lang = Language.CurLanguage();
    const whitePaperLink = lang !== '中文' ? '/Rio_DeFi_Whitepaper_English.pdf' : '/Rio_DeFi_Whitepaper_Chinese.pdf'
    const tosLink = '/Rio_DeFi_User_Agreement_English.pdf'
    const privacyLink = '/Rio_DeFi_Privacy_Policy_English.pdf'
    // const pitchDeckLink = 'http://riochain-media.oss-cn-hongkong.aliyuncs.com/Rio_DeFi_Pitchdeck_English.pdf'
    return (
      <div
        className={"column"}
        style={{ backgroundColor: "#0B103C", paddingLeft: apx(33) }}
      >
        <div
          className={"row"}
          style={{
            marginTop: apx(80),
            marginBottom: apx(100 - 19)
          }}
        >
          <div className={"column"} style={{ width: apx(236) }}>
            <span
              style={{
                fontSize: apx(26),
                color: "#fff",
                fontWeight: 500,
                marginBottom: apx(39)
              }}
            >
              {Language.Home.Footer.Products.Title[lang]}
            </span>
            {[
              {
                name: Language.Home.Footer.Products.Mobile[lang],
                link: '/wallet'
              }, {
                name: Language.Home.Footer.Products.Web[lang],
                link: '/wallet'
              }, {
                name: Language.Home.Footer.Products.White[lang],
                link: whitePaperLink
              }
            ].map((item, index, arr) => (
              <Link
                key={index}
                target="_blank" rel="noreferrer noopener nofollow"
                underline="none"
                href={item.link}
              >
                <span
                  style={{
                    fontSize: apx(22),
                    color: "#666B9A",
                    marginBottom: apx(19)
                  }}
                >
                  {item.name}
                </span>
              </Link>
            ))}
          </div>

          <div className={"column"} style={{ width: apx(159) }}>
            <span
              style={{
                fontSize: apx(26),
                color: "#fff",
                fontWeight: 500,
                marginBottom: apx(39)
              }}
            >
              {Language.Home.Footer.Company.Title[lang]}
            </span>
            {[
              {
                link: "/about",
                name: Language.Home.Footer.Company.About[lang]
              },
              {
                link: "/about#ourteam",
                name: Language.Home.Footer.Company.Team[lang]
              }
              // },
              // {
              //   link:"#sendmessage",
              //   name: Language.Home.Footer.Company.Contact[lang]
              // }
            ].map(item => (
              <Link
                underline="none"
                href={item.link}
              >
                <span
                  style={{
                    fontSize: apx(22),
                    color: "#666B9A",
                    marginBottom: apx(19)
                  }}
                >
                  {item.name}
                </span>
              </Link>

            ))}
          </div>

          <div className={"column"} style={{ width: apx(190) }}>
            <span
              style={{
                fontSize: apx(26),
                color: "#fff",
                fontWeight: 500,
                marginBottom: apx(39)
              }}
            >
              {Language.Home.Footer.Legal.Title[lang]}
            </span>
            {[
              <Link
                target="_blank" rel="noreferrer noopener nofollow"
                underline="none"
                color="inherit"
                href={tosLink}
              >
                {Language.Home.Footer.Legal.Term[lang]}
              </Link>,
              <Link
                target="_blank" rel="noreferrer noopener nofollow"
                color="inherit"
                underline="none"
                href={privacyLink}
              >
                {Language.Home.Footer.Legal.Policy[lang]}
              </Link>
            ].map(text => (
              <span
                style={{
                  fontSize: apx(22),
                  color: "#666B9A",
                  marginBottom: apx(19)
                }}
              >
                {text}
              </span>
            ))}
          </div>

          <div className={"column"} style={{ width: apx(135) }}>

            <span
              style={{
                fontSize: apx(26),
                color: "#fff",
                fontWeight: 500,
                marginBottom: apx(39)
              }}
            >
              {Language.Home.Footer.Support.Title[lang]}
            </span>
            {[Language.Home.Footer.Support.FAQ[lang]].map(text => (
              <Link underline="none"
                href="/faq">
                <span
                  style={{
                    fontSize: apx(22),
                    color: "#666B9A",
                    marginBottom: apx(19)
                  }}
                >
                  {text}
                </span>
              </Link>
            ))}
          </div>
        </div>

        <span
          style={{
            fontSize: apx(46),
            color: "#fff",
            fontWeight: "bolder",
            marginBottom: apx(40)
          }}
        >
          RioDeFi
        </span>

        <div className={"row"}>
          {[
            {
              icon: require("../assets/github.svg"),
              url: "https://github.com/riodefi"
            },
            {
              icon: require("../assets/link_facebook.svg"),
              url: "https://www.facebook.com/riodefiofficial/"
            },
            {
              icon: require("../assets/link_instagram.svg"),
              url: "https://www.instagram.com/riodefiofficial/"
            },
            {
              icon: require("../assets/link_twitter.svg"),
              url: "https://twitter.com/riodefiofficial"
            },
            {
              icon: require("../assets/link_linkedin.svg"),
              url:
                "https://www.linkedin.com/company/riodefiofficial/"
            },
            {
              icon: require("../assets/link_telephone.svg"),
              url: "https://t.me/Riodefiofficial"
            },
            {
              icon: require("../assets/medium.svg"),
              url: "https://medium.com/@riodefiofficial"
            },
            {
              icon: require("../assets/youtube.svg"),
              url: "https://www.youtube.com/channel/UCJjaFCkXVeqdrNG9QGxSegQ"
            },
            // {
            //   icon: require("../assets/link_wechat.svg"),
            //   url: "wechat"
            // }
          ].map(({ icon, url }, index) => {
            if (url === "wechat") {
              return (
                <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href={url}>
                  <img
                    src={icon}
                    style={{
                      width: apx(26),
                      height: apx(26)
                    }}
                    alt=""
                  />
                </Link>
              );
            } else {
              return (
                <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href={url}>
                  <img
                    src={icon}
                    style={{
                      width: apx(26),
                      height: apx(26),
                      marginRight: apx(30)
                    }}
                    alt=""
                  />
                </Link>
              );
            }
          })}
        </div>

        {/* <span
          style={{
            fontSize: apx(22),
            color: "#fff",
            marginTop: apx(80)
          }}
        >
          {Language.Home.Footer.Address[lang]}
        </span> */}

        <div className={"row"} style={{ marginTop: apx(20) }}>
          <span
            style={{
              fontSize: apx(22),
              color: "#fff"
            }}
          >
            {Language.Home.Footer.Email[lang] + " "}
            <Link underline="none" href="mailto:contact@riodefi.com" target="_blank" rel="noreferrer noopener nofollow">
              <span
                style={{
                  color: "#666B9A"
                }}
              >
                &nbsp; contact@riodefi.com
              </span>
            </Link>
          </span>
        </div>

        <span
          style={{
            fontSize: apx(22),
            color: "#666B9A",
            marginTop: apx(160),
            marginBottom: apx(20),
            width: apx(690),
            textAlign: "center"
          }}
        >
          Copyright @RioDeFi. All rights reserved.
        </span>
      </div>
    );
  }
}

export default Index;
