import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { message as AntMessage } from 'antd'

import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import TextField from '@material-ui/core/TextField';
import Language from '../common/Language';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

AntMessage.config({
    maxCount: 1
})

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(249,249,249,1)',
    },
    container: {
        marginTop: '100px',
        marginBottom: '100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '930px',
    },

    title: {
        marginTop: theme.spacing(3),
        fontSize: '36px',
        fontWeight: 400,
        width: '503px',
        color: 'rgba(11,16,60,1)',
        // fontFamily: 'PingFangSC-Regular,PingFang SC;',
    },
    _email: {
        marginTop: '30px',
        width: '580px',
        padiing: '30px',
        backgroundColor: '#ffffff',
    },
    send_btn: {
        marginTop: '30px',
        width: '300px',
        marginLeft: '50px',
        fontSize: '26px',
        fontWeight: 400,
        // fontFamily: 'PingFangSC-Regular,PingFang SC;',
        boxShadow: '0px 20px 60px -10px rgba(11,16,60,0.5);',
        height: '60px',
        borderRadius: '10px',
        backgroundColor: 'rgba(11,16,60,1)',
        textAlign: 'center',
        color: 'white',
        textTransform: "none",
        '&:hover': {
            backgroundColor: 'rgba(11,16,60,1)',
        }
    }
});

const SubscribeForm = ({ status, message, onValidated, props }) => {
    const { classes } = props;
    const lang = Language.CurLanguage();
    const [email, setEmail] = useState(null);

    useEffect(() => {
        if (status === 'sending') {
            AntMessage.info(Language.Home.Subscribing.Btn[lang] + '...');
        }
        if (status === 'success') {
            AntMessage.success(Language.Home.Subscribe.Success[lang]);
        }
        if (status === 'error') {
            AntMessage.error(Language.Home.Subscribe.Error[lang]);
        }
    }, [status, lang])

    const submit = () => {
        if (!email || (email && email.indexOf('@') === -1)) {
            AntMessage.error('Email address not valid!');
            return false;
        }
        onValidated({
            EMAIL: email
        })
    }

    return (
        <div className={classes.item}>
            <TextField className={classes._email} id="outlined-basic"
                label={Language.Home.Subscribe.Name[lang]} variant="outlined" onChange={event => { setEmail(event.target.value) }} />
            <Button
                onClick={submit}
                className={['highlight', classes.send_btn]}>
                {Language.Home.Subscribe.Btn[lang]}
            </Button>
        </div>
    )
}

function Subscribe(props) {
    const { classes } = props;
    const lang = Language.CurLanguage();
    const subscribeUrl = '//riochain.us19.list-manage.com/subscribe/post?u=ad224be546fae87258d90ca8a&amp;id=62db27dbbc'

    return (
        <section className={classes.root}>
            <Container className={classes.container}>
                <div className={classes.item}>
                    <div>
                        <Typography className={classes.title}>
                            {Language.Home.Subscribe.Title[lang]}
                        </Typography>
                    </div>
                </div>
                <MailchimpSubscribe url={subscribeUrl} render={({ subscribe, status, message }) => (
                    <SubscribeForm
                        status={status}
                        message={message}
                        props={props}
                        onValidated={formData => subscribe(formData)}
                    />
                )} />
            </Container>
        </section>
    );
}

Subscribe.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Subscribe);
