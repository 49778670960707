import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography } from "@material-ui/core";
import { apx } from "../../themes/util";
import { Link } from '@material-ui/core'
import Language from '../../modules/common/Language';
import GhostContentApi from '@tryghost/content-api'

const GhostApi = new GhostContentApi({
    url: 'https://jamesanderson.ghost.io',
    key: 'd0f65e85d6126ffa2cec366c05',
    version: 'v3'
})

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: 'rgba(255,255,255,1)',
    },
    // container: {
    //     marginTop: '90px',
    //     marginBottom: '90px',
    //     display: 'flex',
    //     position: 'relative',
    //     flexDirection: 'column',
    // },
    item1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // height: '438px',
        padding: '0 20px',
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '438px',
        padding: theme.spacing(0, 5),
    },
    title: {
        fontSize: '66px',
        fontWeight: 600,
        color: 'rgba(11,16,60,1)',
        lineHeight: '79px',
        marginBottom: '14px',
        // fontFamily: 'PingFangSC-Semibold,PingFang SC;'
    },
    avatarWrapper: {
        width: '100%',
        overflow: 'hidden',
        marginBottom: '12px',
    },
    newsAvatar: {
        width: '100%',
        transition: 'all .6s',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    newsTitle: {
        color: 'rgba(11,16,60,1)',
        fontSize: '16px',
        fontWeight: '800',
        marginBottom: '4px',
        transition: 'all .3s',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    newsDate: {
        color: '#717F99'
    }
});


function LatestNews(props) {
    const { classes } = props;
    const lang = Language.CurLanguage();
    const [newsList, setNewsList] = useState([])
    const [moreLink] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        //fetch latest medium posts here
        async function getNews() {
            try {
                GhostApi.posts.browse({ limit: 8, filter: 'primary_author:rio' }).then(posts => {
                    let finalPosts = posts
                    finalPosts.splice(3, 1, {
                        id: '33fadsfadsf',
                        title: 'DeFi on Polkadot: An alt chain with interoperability on the horizon',
                        published_at: '2020-11-04T09:31:44.000+00:00',
                        feature_image: '/img/defi-on-polkadot.jpg',
                        link: 'https://cointelegraph.com/news/defi-on-polkadot-an-alt-chain-with-interoperability-on-the-horizon'
                    })
                    setLoading(false)
                    setNewsList(posts)
                })
            } catch{
                setLoading(false)
            }
        }
        getNews()
    }, [])



    return (
        <section id="latestNews" className={classes.root}>
            <Container className={classes.container}>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={12}>
                        <div className={classes.item1}>
                            <span
                                dangerouslySetInnerHTML={{__html: Language.Home.LatestNews.Title[lang]}}
                                style={{
                                    fontSize: apx(36),
                                    color: '#002060',
                                    padding: '8px 18px',
                                    fontWeight: 600,
                                    // marginTop: apx(100),
                                    marginBottom: apx(40),
                                }}
                            >
                            </span>
                            {newsList.length === 0 && !loading && (
                                <span>{Language.Common.Empty[lang]}</span>
                            )}
                            {loading && (
                                <span>{Language.Common.Loading[lang]}</span>
                            )}
                            {newsList.length > 0 && (
                                <Grid container spacing={3}>
                                    {newsList.map(item => (
                                        <Grid item xs={12} key={item.id}>
                                            <Link href={'/article/' + item.slug} target="_blank" rel="noreferrer noopener nofollow" underline="none">
                                                <div className={classes.avatarWrapper}>
                                                    <img src={item.feature_image} alt={item.title} className={classes.newsAvatar} />
                                                </div>
                                                <Typography className={classes.newsTitle}>{item.title}</Typography>
                                                <Typography className={classes.newsDate}>{new Date(item.published_at).toLocaleString()}</Typography>
                                            </Link>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}

                            {moreLink && (
                                <Link
                                    href={moreLink}
                                    target="_blank" rel="noreferrer noopener nofollow"
                                >
                                    <div
                                        className={"row"}
                                        style={{
                                            marginTop: apx(30),
                                            paddingLeft: apx(12),
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            width: lang === "English" ? apx(160) : apx(160),
                                            height: apx(56),
                                            backgroundColor: 'rgba(52,78,154,1)',
                                            borderRadius: apx(39),
                                        }}
                                    >

                                        <span style={{ marginLeft: apx(24), width: apx(60), fontSize: apx(22), color: "#fff" }}>
                                            {Language.Home.LatestNews.Link[lang]}
                                        </span>
                                        <div
                                            className={"column center"}
                                            style={{
                                                width: apx(36),
                                                height: apx(36),
                                            }}
                                        >
                                            <img
                                                src={require("../assets/more_btn.svg")}
                                                style={{
                                                    width: apx(36),
                                                    height: apx(36)
                                                }}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

LatestNews.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LatestNews);
