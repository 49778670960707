// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";
import { Link } from "@material-ui/core";
import AppleIcon from "../../assets/img/apple_download_en.svg";
import AndroidIcon from "../../assets/img/android_download_en.svg";
// import GoogleIcon from "../../assets/img/google_coming_en.svg";
import AppleIconCn from "../../assets/img/apple_download_cn.svg";
import AndroidIconCn from "../../assets/img/android_download_cn.svg";
import GoogleIcon from "../../assets/img/google_riowallet.svg";
import AppstoreIcon from "../../assets/img/download_appstore.svg";
import GoogleplayIcon from "../../assets/img/download_googleplay.svg";

class Index extends React.Component {
  state = {};

  componentDidMount() {}

  render() {
    const lang = Language.CurLanguage();

    return (
      <div
        id="white_paper"
        className={"column"}
        style={{ backgroundColor: "#fff" }}
      >
        <div className={"column center"}>
          <span
            style={{
              fontSize: apx(46),
              color: "#0B103C",
              fontWeight: "500",
              marginTop: apx(100),
              width: apx(574),
              textAlign: "center",
            }}
          >
            {Language.Wallet.Title[lang]}
          </span>
          <span
            style={{
              width: apx(574),
              fontSize: apx(26),
              color: "#5A5A5A",
              marginTop: apx(44),
              lineHeight: apx(37),
              textAlign: "center",
            }}
            mwallet
          >
            {Language.Wallet.SubTitle[lang]}
          </span>
        </div>

        <div
          className={"column center"}
          style={{
            marginTop: apx(60),
          }}
        >
          <Link
            href="https://wallet.riochain.io/wallet"
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            <img
              style={{ height: apx(90) }}
              src={GoogleIcon}
              alt=""
            />

            {/* {
              "English" === lang ? (
                <img style={{ height: apx(76), width: apx(220) }} src={GoogleIcon} alt="" />
              ) : null
            } */}

            {/* {
              "中文" === lang ? (
                <img style={{ height: apx(76), width: apx(220) }} src={GoogleIconCn} alt="" />
              ) : null
            } */}
          </Link>
        </div>

        <div
          className={"column center"}
          style={{
            marginTop: apx(60),
          }}
        >
          <Link target="_blank" href="/riowallet_android.apk">
            {"English" === lang ? (
              <img
                style={{ height: apx(90) }}
                src={AndroidIcon}
                alt=""
              />
            ) : null}

            {"中文" === lang ? (
              <img
                style={{ height: apx(90) }}
                src={AndroidIconCn}
                alt=""
              />
            ) : null}
          </Link>
        </div>

        <div
          className={"column center"}
          style={{
            marginTop: apx(60),
          }}
        >
          <Link
            href="https://play.google.com/store/apps/details?id=com.riodefi.store"
            target="_blank"
          >
            <img
              style={{ height: apx(90) }}
              src={GoogleplayIcon}
              alt=""
            />
          </Link>
        </div>

        <div
          className={"column center"}
          style={{
            marginTop: apx(60),
          }}
        >
          <Link
            href="https://apps.apple.com/us/app/riodefi-wallet/id1560789648"
            target="_blank"
          >
            <img
              style={{ height: apx(90) }}
              src={AppstoreIcon}
              alt=""
            />
          </Link>
        </div>

        <div
          style={{
            height: apx(128),
          }}
        ></div>
      </div>
    );
  }
}

export default Index;
