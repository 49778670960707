import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Link, Tooltip } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import GithubIcon from "../../assets/img/github.svg";
import FacebookIcon from "../../assets/img/facebook_icon.png";
import CarmeraIcon from "../../assets/img/carmera_icon.png";
import InIcon from "../../assets/img/in_icon.png";
import TelIcon from "../../assets/img/tel_icon.svg";
import WechatIcon from "../../assets/img/wechat_icon.png";
import MediumIcon from "../../assets/img/medium.svg";
import YoutubeIcon from "../../assets/img/youtube.svg";
import TwiterIcon from "../../assets/img/twiter_icon.png";
import WechatCode from "../../assets/img/wechat_code.jpg";
import WechatOfficial from "../../assets/img/wechat_official.png";
import WechatPublicIcon from "../../assets/img/wechat_public_icon.png";
import Language from '../common/Language';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    color: '#ffffff',
    minWidth: '1280px',
    backgroundColor: 'rgba(11,16,60,1)',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    fontSize: '40px',
    fontWeight: 600,
    color: 'rgba(255,255,255,1)',
    // fontFamily: 'PingFangSC-Regular,PingFang SC;'
  },
  version: {
    fontSize: '26px',
    // fontFamily: 'PingFangSC-Regular,PingFang SC;',
    marginTop: theme.spacing(5),
    color: 'rgba(255,255,255,1)',
    fontWeight: 400,
  },
  subtitle: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
    fontSize: '36px',
    fontWeight: 400,
    color: 'rgba(255,255,255,1)',
    // fontFamily: 'PingFangSC-Regular,PingFang SC;'
  },
  room: {
    marginTop: '44px',
    fontSize: '16px',
    fontWeight: 400,
    width: '373px',
    color: 'rgba(255,255,255,1)',
    // fontFamily: 'PingFangSC-Regular,PingFang SC;'
  },
  boot_body_font: {
    marginTop: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'row',
    color: 'rgba(102,107,154,1)',
    // fontFamily: 'PingFangSC-Regular,PingFang SC;'
  }
});

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    // fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function AppFooter(props) {
  const { classes } = props;
  const lang = Language.CurLanguage();
  const whitePaperLink = lang !== '中文' ? '/Rio_DeFi_Whitepaper_English.pdf' : '/Rio_DeFi_Whitepaper_Chinese.pdf'
  const tosLink = '/Rio_DeFi_User_Agreement_English.pdf'
  const privacyLink = '/Rio_DeFi_Privacy_Policy_English.pdf'
  // const pitchDeckLink = 'http://riochain-media.oss-cn-hongkong.aliyuncs.com/Rio_DeFi_Pitchdeck_English.pdf'
  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.item}>
          <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
            <Grid item xs={12} md={5}>
              <Typography className={classes.title}>
                {'RioDeFi'}
              </Typography>
              <Grid container justify="flex-start" wrap="nowrap" spacing={2} style={{
                marginTop: '8px',
              }}>
                <Grid item xs={12} md={1}>
                  <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://github.com/riodefi">
                    <img src={GithubIcon} alt="" />
                  </Link>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://www.facebook.com/riodefiofficial/">
                    <img src={FacebookIcon} alt="" />
                  </Link>
                </Grid>

                <Grid item xs={12} md={1}>
                  <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://www.instagram.com/riodefiofficial/">
                    <img src={CarmeraIcon} alt="" />
                  </Link>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://twitter.com/riodefiofficial">
                    <img src={TwiterIcon} alt="" />
                  </Link>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://www.linkedin.com/company/riodefiofficial/">
                    <img src={InIcon} alt="" />
                  </Link>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://t.me/Riodefiofficial">
                    <img src={TelIcon} alt="" />
                  </Link>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://medium.com/@riodefiofficial">
                    <img src={MediumIcon} alt="" />
                  </Link>
                </Grid>
                <Grid item xs={12} md={1}>
                  <Link underline="none" target="_blank" rel="noreferrer noopener nofollow" href="https://www.youtube.com/channel/UCJjaFCkXVeqdrNG9QGxSegQ">
                    <img src={YoutubeIcon} alt="" />
                  </Link>
                </Grid>
                <Grid item xs={12} md={1}>
                  <HtmlTooltip
                    placement="top-start"
                    title={
                      <React.Fragment>
                        <Typography><img style={{width: '120px'}} src={WechatOfficial} alt="" /></Typography>
                      </React.Fragment>
                    }
                  >
                    <img src={WechatIcon} alt="" />
                  </HtmlTooltip>
                </Grid>
                {/* <Grid item xs={12} md={1}>
                  <HtmlTooltip
                    placement="top-start"
                    title={
                      <React.Fragment>
                        <Typography><img style={{width: '120px'}} src={} alt="" /></Typography>
                      </React.Fragment>
                    }
                  >
                    <img src={WechatPublicIcon} alt="" />
                  </HtmlTooltip>
                </Grid> */}
              </Grid>

              {/* <Typography className={classes.room}>
                {Language.Home.Footer.Address[lang]}
              </Typography> */}
              <Typography className={classes.boot_body_font}>
                <span style={{ color: 'white' }}>
                  {Language.Home.Footer.Email[lang]}
                </span>
                <Link
                  underline="none"
                  color="inherit"
                  alt=""
                  target="_blank" rel="noreferrer noopener nofollow"
                  href="mailto:contact@riodefi.com"
                >
                  &nbsp;{' contact@riodefi.com'}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={7} >
              <Grid container justify="flex-start" wrap="nowrap" spacing={5}>
                <Grid item xs={12} md={3} >
                  <div style={{
                    position: 'absolute',
                    height: '240px',
                    width: '1px',
                    marginLeft: '-60px',
                    backgroundColor: 'rgba(140,157,205,1)'
                  }}>

                  </div>
                  <Typography className={classes.subtitle}>
                    {Language.Home.Footer.Products.Title[lang]}
                  </Typography>
                  <Typography className={classes.boot_body_font}>
                    <Link
                      underline="none"
                      color="inherit"
                      alt=""
                      href="/wallet">
                      {Language.Home.Footer.Products.Mobile[lang]}
                    </Link>
                  </Typography>
                  <Typography className={classes.boot_body_font}>
                    <Link
                      underline="none"
                      color="inherit"
                      alt=""
                      href="/wallet">
                      {Language.Home.Footer.Products.Web[lang]}
                    </Link>
                  </Typography>
                  <Typography className={classes.boot_body_font}>
                    <Link
                      underline="none"
                      color="inherit"
                      alt=""
                      target="_blank" rel="noreferrer noopener nofollow"
                      href={whitePaperLink}>
                      {Language.Home.Footer.Products.White[lang]}
                    </Link>
                  </Typography>
                  {/* <Typography className={classes.boot_body_font}>
                    <Link
                      underline="none"
                      color="inherit"
                      alt=""
                      target="_blank" rel="noreferrer noopener nofollow"
                      href={pitchDeckLink}>
                      {Language.Home.Footer.Products.Pitch[lang]}
                    </Link>
                  </Typography> */}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className={classes.subtitle}>
                    {Language.Home.Footer.Company.Title[lang]}
                  </Typography>
                  <Typography className={classes.boot_body_font}>
                    <Link
                      underline="none"
                      color="inherit"
                      alt=""
                      href="/about">
                      {Language.Home.Footer.Company.About[lang]}
                    </Link>
                  </Typography>
                  <Typography className={classes.boot_body_font}>
                    <Link
                      underline="none"
                      color="inherit"
                      alt=""
                      href="/about#ourteam">
                      {Language.Home.Footer.Company.Team[lang]}
                    </Link>
                  </Typography>
                  {/* <Typography className={classes.boot_body_font}>
                    <Link
                      underline="none"
                      color="inherit"
                      alt=""
                      href="#sendmessage">
                      {Language.Home.Footer.Company.Contact[lang]}
                    </Link>
                  </Typography> */}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className={classes.subtitle}>
                    {Language.Home.Footer.Legal.Title[lang]}
                  </Typography>
                  <Link href={tosLink} underline="none"
                      color="inherit"
                      alt=""
                      target="_blank" rel="noreferrer noopener nofollow">
                    <Typography className={classes.boot_body_font}>
                      {Language.Home.Footer.Legal.Term[lang]}
                    </Typography>
                  </Link>
                  <Link href={privacyLink} underline="none"
                      color="inherit"
                      alt=""
                      target="_blank" rel="noreferrer noopener nofollow">
                    <Typography className={classes.boot_body_font}>
                      {Language.Home.Footer.Legal.Policy[lang]}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography className={classes.subtitle}>
                    {Language.Home.Footer.Support.Title[lang]}
                  </Typography>
                  <Link underline="none"
                    href="/faq">
                    <Typography className={classes.boot_body_font}>

                      {Language.Home.Footer.Support.FAQ[lang]}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Typography style={{
          marginTop: '100px',
          marginBottom: '20px',
          width: '100%',
          textAlign: 'center',
          fontSize: '16px',
          color: 'rgba(102,107,154,1)',
          lineHeight: '22px',
          letterSpacing: '1px',
        }}>
          <div style={{
            height: '1px',
            width: '1200px',
            marginBottom: '30px',
            backgroundColor: 'rgba(140,157,205,1)'
          }}>
          </div>
          {'Copyright @RioDeFi. All rights reserved.'}
        </Typography>
      </Container>
    </section>
  );
}

AppFooter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppFooter);
