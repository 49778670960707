import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Link} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import CareersImg from "../../assets/img/career_bg.svg";
// import CareersImg from "../../assets/img/careers.png";
import Language from '../common/Language';

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
    },
    container: {
        marginTop: '200px',
        marginBottom: '188px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // padding: theme.spacing(0, 5),
    },
    title: {
        fontSize: '66px',
        fontWeight: 500,
        color: 'rgba(255,255,255,1)',
        lineHeight: '92px',
        textAlign: 'center',
        // fontFamily: 'PingFangSC-Medium,PingFang SC;'
    },
    question_text: {
        marginTop: '54px',
        width: '362px',
        fontSize: '16px',
        fontWeight: 500,
        color: 'rgba(255,255,255,1)',
        lineHeight: '22px',
        textAlign: 'center',
        // fontFamily: 'PingFangSC-Medium,PingFang SC;',
    },
    download: {
        borderRadius: '60px',
        marginTop: theme.spacing(5),
        width: '260px',
        height: '88px',
        lineHeight: '88px',
        textAlign: 'center',
        fontSize: '16px',
        // fontFamily: 'PingFangSC-Medium,PingFang SC;',
        fontWeight: 500,
        color: '#ffffff',
        border:'1px solid rgba(255,255,255,1)'
    }
});

function Careers(props) {
    const { classes } = props;
    const lang =Language.CurLanguage();

    return (
        <section className={classes.root}>
            <div style={{
                width: '100%',
                height: '100%',
                background: `url(${CareersImg})`,
            }}>
            <Container className={classes.container}>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                        <h2 className={classes.item}>
                            <Typography className={classes.title}>
                                {Language.About.Careers.Title[lang]}
                            </Typography>
                            <Typography className={classes.question_text}>
                                {Language.About.Careers.Content[lang]}
                            </Typography>
                            <Link
                                underline="none"
                                href="/recruit"
                                className={['highlight', classes.download]}>
                                {Language.About.Careers.Btn[lang]}
                            </Link>
                        </h2>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.item}>
                            {/* <img src={CareersImg} alt="" /> */}
                        </div>
                    </Grid>
                </Grid>
            </Container>
            </div>
        </section>
    );
}

Careers.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Careers);
