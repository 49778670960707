// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";
import { Typography } from "@material-ui/core";
import ReactPlayer from "react-player";
import VideoThumb2 from "../../assets/img/video-thumb-2.png";

class Index extends React.Component {
  state = {};

  componentDidMount() {}

  render() {
    const lang = Language.CurLanguage();

    return (
      <div
        className={"column"}
        style={{ alignItems: "center", backgroundColor: "rgba(255,255,255,1)" }}
      >
        <img
          src={require("../assets/about_header.png")}
          style={{ width: apx(750), height: apx(560) }}
          alt=""
        />
        <div
          className={"column"}
          style={{ position: "absolute", top: apx(40), left: apx(30) }}
        >
          <div>
            <Typography
              style={{
                width: apx(290),
                color: "#0B103C",
                fontSize: apx(36),
                lineHeight: apx(65),
                fontWeight: "600",
              }}
            >
              {Language.About.Mission.Title[lang]}
            </Typography>
            <Typography
              style={{
                width: apx(394),
                color: "#5a5a5a",
                fontSize: apx(16),
                lineHeight: apx(22),
                fontWeight: "500",
                marginTop: apx(40),
              }}
            >
              {Language.About.Mission.Content1[lang]}
              {Language.About.Mission.Content2[lang]}
            </Typography>
            <Typography
              style={{
                width: apx(394),
                color: "#5a5a5a",
                fontSize: apx(16),
                lineHeight: apx(22),
                fontWeight: "500",
                marginTop: apx(20),
              }}
            >
              {Language.About.Mission.Content3[lang]}
              {Language.About.Mission.Content4[lang]}
            </Typography>
          </div>
        </div>
        <ReactPlayer
          light={VideoThumb2}
          style={{
            marginTop: "32px",
          }}
          width="100%"
          height="100%"
          url={
            lang === "Spanish"
              ? "https://media.riochain.io/rio-defi-intro-spanish.mov"
              : "https://media.riochain.io/rio-defi-intro.mov"
          }
          controls
        />
      </div>
    );
  }
}

export default Index;
