import React, { useState } from "react";
import PropTypes from "prop-types";
import ArrowRight from "../../../assets/img/arrow-right.svg";
import ReactPlayer from "react-player";

import WhitelistModal from "../../modals/WhitelistModal";
import styles from "./styles.module.scss";

function CardIntro(props) {
  const [whitelistModalVisible, setWhitelistModalVisible] = useState(false);

  return (
    <section id="CardIntro" className={styles.wrapper}>
      <ReactPlayer
        loop={true}
        playing={true}
        width="100%"
        height="auto"
        muted={true}
        className={styles.video}
        url="/RioCard_video.webm"
      />
      <div className={styles.content}>
        <div className={styles.title}>
          RioDeFi
          <br /> Introduces the Rio
          <span className={styles.highlight}>Card</span>
        </div>
        <a
          className={styles.btn}
          onClick={() => setWhitelistModalVisible(true)}
        >
          Get Whitelisted <img src={ArrowRight} className={styles.arrowRight} />{" "}
        </a>
      </div>

      {whitelistModalVisible && (
        <WhitelistModal onCancel={() => setWhitelistModalVisible(false)} />
      )}
    </section>
  );
}

CardIntro.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default CardIntro;
