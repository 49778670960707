import withRoot from "../../modules/withRoot";
// --- Post bootstrap -----
import React, { useEffect, useState } from "react";
import AppAppBar from "../../modules/views/AppAppBar";
import Divider from "@material-ui/core/Divider";
import AppFooter from "../../modules/views/AppFooter";
import { Form, Input, Button, Modal, Upload, Row, Col } from "antd";
import UploadPdfImg from "../../assets/img/upload-pdf.svg";
import "./style.scss";

// const layout = {
//   // labelCol: { span: 8 },
//   wrapperCol: { span: 24 },
// };

export default withRoot(function ContactPage() {
  const [currentKey, setCurrentKey] = useState("information");
  const [feedbackMsg, setFeedbackMsg] = useState("");
  const [amaCurrentStep, setAmaCurrentStep] = useState(0);

  const sendInformation = () => {
    setFeedbackMsg(
      "Thank you for your interest in RioDeFi. We have received your requesty and will get in touch with you shortly."
    );
  };

  const sendAma = () => {
    setFeedbackMsg(
      "Thank you for your interest in hosting an AMA with RioDeFi. We will review your information and contact you back should we want to partner with you."
    );
  };

  const sendBusiness = () => {
    setFeedbackMsg(
      "Thank you for your interest in partnering with RioDeFi. We will revert back to you shortly."
    );
  };

  const sendReport = () => {
    setFeedbackMsg(
      "Thank you for reporting issue(s) you have encountered. A team member will get in touch with you shortly."
    );
  };

  const UploadDoc = (props) => (
    <Upload className="upload-area">
      <Button className="btn-trans">{props.name}</Button>
      <img src={UploadPdfImg} />
    </Upload>
  );

  return (
    <React.Fragment>
      <AppAppBar contact="1" />
      <Divider />
      <div className="contact-page">
        <div className="tabs">
          <div
            onClick={() => {
              setCurrentKey("information");
            }}
            className={`tab ${currentKey === "information" ? "active" : ""}`}
          >
            Information
          </div>
          <div
            onClick={() => {
              setCurrentKey("ama");
            }}
            className={`tab ${currentKey === "ama" ? "active" : ""}`}
          >
            AMA
          </div>
          <div
            onClick={() => {
              setCurrentKey("business");
            }}
            className={`tab ${currentKey === "business" ? "active" : ""}`}
          >
            Business
          </div>
          <div
            onClick={() => {
              setCurrentKey("report");
            }}
            className={`tab ${currentKey === "report" ? "active" : ""}`}
          >
            Report issues
          </div>
        </div>

        {currentKey === "information" && (
          <Form onFinish={sendInformation}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="country"
              rules={[{ required: true, message: "Country is required" }]}
            >
              <Input placeholder="Country" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Email is required" }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="subject"
              rules={[{ required: true, message: "Subject is required" }]}
            >
              <Input placeholder="Subject" />
            </Form.Item>
            <Form.Item
              name="content"
              rules={[{ required: true, message: "Content is required" }]}
            >
              <Input.TextArea autoSize={{ minRows: 4 }} placeholder="Content" />
            </Form.Item>

            <div className="handle-area">
              <Button className="btn-trans" type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        )}

        {currentKey === "ama" && (
          <>
            {/* <div className="step-area">
              <div className="step-circle">1</div>
              <div className="step-circle">2</div>
            </div> */}
            <Form onFinish={sendAma}>
              <div className={amaCurrentStep !== 0 ? "hidden" : ""}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                  name="telegram"
                  rules={[
                    {
                      required: true,
                      message: "Telegram's username is required",
                    },
                  ]}
                >
                  <Input placeholder="Telegram's username" />
                </Form.Item>
                <Form.Item
                  name="groupName"
                  rules={[
                    { required: true, message: "EAMA group's nameis required" },
                  ]}
                >
                  <Input placeholder="AMA group's name" />
                </Form.Item>
                <Row gutter={20}>
                  <Col md={16}>
                    <Form.Item
                      name="tgChannel"
                      rules={[
                        {
                          required: true,
                          message: "AMA TG channel is required",
                        },
                      ]}
                    >
                      <Input placeholder="AMA TG channel" />
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item
                      name="tgChannelFollowers"
                      rules={[
                        {
                          required: true,
                          message: "Number of followers is required",
                        },
                      ]}
                    >
                      <Input placeholder="Number of followers" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col md={16}>
                    <Form.Item
                      name="twitterChannel"
                      rules={[
                        {
                          required: true,
                          message: "Twitter channel is required",
                        },
                      ]}
                    >
                      <Input placeholder="Twitter channel" />
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item
                      name="twitterChannelFollowers"
                      rules={[
                        {
                          required: true,
                          message: "Number of followers is required",
                        },
                      ]}
                    >
                      <Input placeholder="Number of followers" />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="youtubeChannel"
                  rules={[
                    { required: true, message: "Youtube channel is required" },
                  ]}
                >
                  <Input placeholder="Youtube channel" />
                </Form.Item>

                <Form.Item
                  name="mediumChannel"
                  rules={[
                    { required: true, message: "Medium channel is required" },
                  ]}
                >
                  <Input placeholder="Medium channel" />
                </Form.Item>

                <Form.Item
                  name="website"
                  rules={[{ required: true, message: "Website is required" }]}
                >
                  <Input placeholder="Website" />
                </Form.Item>
              </div>

              <div className={amaCurrentStep !== 1 ? "hidden" : ""}>
                <div className="question-block">
                  <div className="title">Tell us more about your community</div>
                  <Form.Item
                    name="audience"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input placeholder="In which country of continent is your audience based?" />
                  </Form.Item>
                  <Form.Item
                    name="language"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input placeholder="What language does your audience speak?" />
                  </Form.Item>
                  <Form.Item
                    name="last3"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input placeholder="List the 3 last projects you had an AMA with?" />
                  </Form.Item>
                </div>

                <div className="question-block">
                  <div className="title">Fee &amp; Rewards</div>
                  <Form.Item
                    name="fees"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input placeholder="What fees do you charge?" />
                  </Form.Item>
                  <Form.Item
                    name="rewardType"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input placeholder="What are the rewards?" />
                  </Form.Item>
                </div>

                <div className="question-block">
                  <div className="title">Proposal</div>
                  <UploadDoc name="Add pdf proposal" />
                </div>
              </div>

              <div className="handle-area">
                {amaCurrentStep === 0 && (
                  <Button
                    className="btn-trans"
                    onClick={() => {
                      setAmaCurrentStep((prev) => prev + 1);
                    }}
                  >
                    Next
                  </Button>
                )}

                {amaCurrentStep === 1 && (
                  <Button
                    className="btn-primary"
                    onClick={() => {
                      setAmaCurrentStep((prev) => prev - 1);
                    }}
                  >
                    Back
                  </Button>
                )}

                {amaCurrentStep === 1 && (
                  <Button className="btn-trans" htmlType="submit">
                    Send
                  </Button>
                )}
              </div>
            </Form>
          </>
        )}

        {currentKey === "business" && (
          <Form onFinish={sendBusiness}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="company"
              rules={[{ required: true, message: "Company is required" }]}
            >
              <Input placeholder="Company" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Email is required" }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="subject"
              rules={[{ required: true, message: "Subject is required" }]}
            >
              <Input placeholder="Subject" />
            </Form.Item>
            <Form.Item
              name="content"
              rules={[{ required: true, message: "Content is required" }]}
            >
              <Input.TextArea autoSize={{ minRows: 4 }} placeholder="Content" />
            </Form.Item>

            <UploadDoc name="Add pdf doc" />

            <div className="handle-area">
              <Button className="btn-trans" type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        )}

        {currentKey === "report" && (
          <Form onFinish={sendReport}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="country"
              rules={[{ required: true, message: "Country is required" }]}
            >
              <Input placeholder="Country" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Email is required" }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="type"
              rules={[{ required: true, message: "Type of issue is required" }]}
            >
              <Input placeholder="Type of issue" />
            </Form.Item>
            <Form.Item
              name="content"
              rules={[{ required: true, message: "Content is required" }]}
            >
              <Input.TextArea autoSize={{ minRows: 4 }} placeholder="Content" />
            </Form.Item>

            <UploadDoc name="Add screen shot" />

            <div className="handle-area">
              <Button className="btn-trans" type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        )}
      </div>
      <AppFooter />
      {feedbackMsg && (
        <Modal
          onCancel={() => {
            setFeedbackMsg("");
          }}
          footer={null}
          visible={true}
          wrapClassName="feedback-modal"
        >
          {feedbackMsg}
        </Modal>
      )}
    </React.Fragment>
  );
});

// class Contact extends React.Component {

//   render() {
//     return (
//       <React.Fragment>
//         <AppAppBar contact="1" />
//         <Divider />
//         <div>

//         </div>
//         <AppFooter />
//       </React.Fragment>
//     )
//   }
// }
// export default withRoot(Contact);
