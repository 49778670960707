// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";

class Index extends React.Component {
    state = {};

    componentDidMount() { }

    render() {
        const lang = Language.CurLanguage();

        return (
            <div id="white_paper" className={"column"} style={{ backgroundColor: "#fff" }}>
                <div className={"column center"} style={{
                    marginBottom: '80px'
                }}>
                    <span
                        style={{
                            fontSize: apx(46),
                            color: 'rgba(0,0,0,1)',
                            fontWeight: 600,
                            marginTop: apx(100),
                            width: apx(616),
                            lineHeight: apx(65),
                            textAlign: 'center',
                            padding: '80px 0',
                        }}
                    >
                        {Language.Common.NotFoundHint[lang]}
                    </span>
                </div>
            </div>
        );
    }
}

export default Index;
