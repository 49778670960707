import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
// import TitleArrow from "../../assets/img/title-arrow.png";
import { Fade } from 'react-reveal'
import Typography from '../components/Typography';
import { Link } from '@material-ui/core'
import Language from '../common/Language';
import GhostContentApi from '@tryghost/content-api'

const GhostApi = new GhostContentApi({
    url: 'https://jamesanderson.ghost.io',
    key: 'd0f65e85d6126ffa2cec366c05',
    version: 'v3'
})

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: 'rgba(255,255,255,1)',
    },
    container: {
        // marginTop: '90px',
        marginBottom: '60px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
    },
    h2title: {
        display: 'block',
        width: '100%'
    },
    item1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // height: '438px',
        padding: theme.spacing(0, 5),
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '438px',
        padding: theme.spacing(0, 5),
    },
    title: {
        fontSize: '46px',
        fontWeight: 600,
        color: '#002060',
        padding: '8px 18px',
        marginBottom: '44px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        // fontFamily: 'PingFangSC-Semibold,PingFang SC;'
    },
    avatarWrapper: {
        width: '100%',
        overflow: 'hidden',
        marginBottom: '12px',
    },
    newsAvatar: {
        width: '100%',
        height: '180px',
        objectFit: 'contain',
        transition: 'all .6s',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    newsTitle: {
        color: 'rgba(11,16,60,1)',
        fontSize: '16px',
        fontWeight: '800',
        marginBottom: '4px',
        transition: 'all .3s',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    newsDate: {
        color: '#717F99'
    }
});


function LatestNews(props) {
    const { classes } = props;
    const lang = Language.CurLanguage();
    const [newsList, setNewsList] = useState([])
    const [moreLink] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        //fetch latest medium posts here
        async function getNews() {
            try {
                GhostApi.posts.browse({ limit: 12, filter: 'primary_author:rio' }).then(posts => {
                    //add some external link
                    let finalPosts = posts
                    // finalPosts.splice(3, 0, {
                    //     id: '33fadsfadsf',
                    //     title: 'DeFi on Polkadot: An alt chain with interoperability on the horizon',
                    //     published_at: '2020-11-04T09:31:44.000+00:00',
                    //     feature_image: '/img/defi-on-polkadot.jpg',
                    //     link: 'https://cointelegraph.com/news/defi-on-polkadot-an-alt-chain-with-interoperability-on-the-horizon'
                    // })

                    setLoading(false)
                    setNewsList(finalPosts)
                })
            } catch{
                setLoading(false)
            }
        }
        getNews()
    }, [])



    return (
        <section id="LatestNews" className={classes.root}>
            <Container className={classes.container}>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={12}>
                        <div className={classes.item1}>
                            <h2 className={classes.h2title}>
                                <Typography className={classes.title}>
                                <Fade left>

                                    {/* <img src={TitleArrow} className="title-arrow"/> */}
                                    <span dangerouslySetInnerHTML={{__html: Language.Home.LatestNews.Title[lang]}}></span>
                                    </Fade>
                                </Typography>
                            </h2>

                            {newsList.length === 0 && !loading && (
                                <span>{Language.Common.Empty[lang]}</span>
                            )}
                            {loading && (
                                <span>{Language.Common.Loading[lang]}</span>
                            )}
                            {newsList.length > 0 && (
                                <Grid container spacing={3}>
                                    {newsList.map(item => (
                                        <Grid item xs={6} md={3} key={item.id}>
                                            <Link href={item.link ? item.link : ('/article/' + item.slug)} target="_blank" rel="noreferrer noopener nofollow" underline="none">
                                                {
                                                    item.feature_image && (
                                                        <div className={classes.avatarWrapper}>
                                                            <img src={item.feature_image} alt={item.title} className={classes.newsAvatar} />
                                                        </div>
                                                    )
                                                }
                                                <Typography className={classes.newsTitle}>{item.title}</Typography>
                                                <Typography className={classes.newsDate}>{new Date(item.published_at).toLocaleString()}</Typography>
                                            </Link>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}

                            {moreLink && (
                                <Link
                                    underline="none"
                                    href={moreLink}
                                    target="_blank" rel="noreferrer noopener nofollow"
                                >
                                    <div
                                        className={"row highlight"}
                                        style={{
                                            marginTop: 36,
                                            width: 190,
                                            height: 78,
                                            borderRadius: 39,
                                            alignItems: "center",
                                            display: 'flex',
                                            flexDirection: 'row',
                                            backgroundColor: 'rgba(52,78,154,1)'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: "#fff",
                                                fontSize: 20,
                                                width: '60px',
                                                textAlgin: 'center',
                                                marginLeft: '41px'
                                            }}
                                        >
                                            {Language.Home.LatestNews.Link[lang]}
                                        </span>
                                        <div
                                            style={{
                                                width: 46,
                                                height: 46,
                                            }}
                                        >
                                            <img
                                                src={require("../../assets/img/more_btn.svg")}
                                                style={{
                                                    width: 46,
                                                    height: 46
                                                }}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

LatestNews.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LatestNews);
