import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../components/Typography';
import { apx } from "../../themes/util";
import Language from '../common/Language';


const styles = theme => ({
    root: {
        marginBottom: '80px',
    },
    container: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
    },
    heading: {
        fontSize: apx(16),
        color: '#111760',
    },
    title: {
        padding: '120px 0',
        color: 'rgba(11,16,60,1)',
        fontSize: apx(44),
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: apx(92),
    },
});

function NotFound(props) {
    const { classes } = props;
    const lang = Language.CurLanguage();

    return (
        <section className={classes.root}>
            <Typography className={classes.title}>
                {Language.Common.NotFoundHint[lang]}
            </Typography>
        </section>
    );
}

NotFound.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);
