import withRoot from "./modules/withRoot";
// --- Post bootstrap -----
import React from "react";
import Banner from "./modules/views/Banner";

// import { Carousel } from 'antd'
// import DateCountdown from 'react-date-countdown-timer'
import ProductCategories from "./modules/views/ProductCategories";
import AppFooter from "./modules/views/AppFooter";
// import ProductHome from "./modules/views/ProductHome";
import ProductValues from "./modules/views/ProductValues";
import AppAppBar from "./modules/views/AppAppBar";
import Architecture from "./modules/views/Architecture";
// import VideoRioChain from "./modules/views/VideoRioChain";
import Ecosystem from "./modules/views/Ecosystem";
import CardIntro from "./modules/views/CardIntro/index";
// import DApp from "./modules/views/DApp";
// import WhitePaper from './modules/views/WhitePaper';
import LatestNews from "./modules/views/LatestNews";
// import PeopleLove from "./modules/views/PeopleLove";
import Partners from "./modules/views/Partners";
// import MediumNews from "./modules/views/MediumNews";
import Subscribe from "./modules/views/Subscribe";
import Mobile from "./mobile/views/Mobile";
import Language from './modules/common/Language';
import Container from '@material-ui/core/Container';
// import Banner0 from './assets/img/banner/0.png'
// import Banner1En from './assets/img/banner/1.png'
// import Banner1Zh from './assets/img/banner/1.png'
// import Banner2En from './assets/img/banner/2.png'
// import Banner2Zh from './assets/img/banner/2.png'

import UniswapIcon from './assets/uniswap.svg'
import bannerImg from './assets/img/banner/Riodefi_Mantra.jpg'


// import ReactPlayer from 'react-player'

// const lang = Language.CurLanguage();


class Index extends React.Component {
  IsPC() {
    var userAgentInfo = navigator.userAgent;
    console.log(userAgentInfo);
    var Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod"
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  render() {
    return (
      true === this.IsPC() ? (
        <React.Fragment>
          <AppAppBar />
          <div style={{ padding: '8px 0', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src={UniswapIcon} style={{verticalAlign: 'middle', marginRight: '8px', width: '16px'}} />
            <span>
              Get RFuel on Uniswap:&nbsp;&nbsp;
              <a target="_blank" href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xaf9f549774ecedbd0966c52f250acc548d3f36e5"
              >0xaf9f549774ecedbd0966c52f250acc548d3f36e5</a>
            </span>
          </div>
          <Banner/>
          <ProductValues />
          {/* <Carousel autoplay={true}>
          <div>
              <a target="_blank" rel="noreferrer noopener nofollow" href="https://riodefiofficial.medium.com/riochain-launch-giveaway-campaign-winners-161a461ab43f">
                <img src={Banner0} />
              </a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer noopener nofollow" href="https://riodefiofficial.medium.com/riochain-launch-giveaway-campaign-aeecac8a830a">
                {lang === 'English' && <img src={Banner1En} />}
                {lang === '中文' && <img src={Banner1Zh} />}
              </a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer noopener nofollow" href="https://riodefiofficial.medium.com/riochain-release-roadmap-9097fc5d9757">
                {lang === 'English' && <img src={Banner2En} />}
                {lang === '中文' && <img src={Banner2Zh} />}
              </a>
            </div>
          </Carousel> */}
          {/* <div style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold', background: '#fff' }}>
            <span style={{ fontSize: '20px', marginBottom: '8px', color: 'rgb(0, 32, 96)' }}>Follow us on <a style={{color: '#4c88f6'}} target="_blank" rel="noreferrer noopener nofollow" href="https://twitter.com/riodefiofficial">Twitter</a> and <a style={{color: '#4c88f6'}} target="_blank" rel="noreferrer noopener nofollow" href="https://t.me/Riodefiofficial">Telegram</a></span>
          </div> */}
          {/* <CardIntro /> */}
          <Container>
            <img src={bannerImg} style={{verticalAlign: 'middle', marginRight: '8px', width: '100%'}} alt="banner-1"/>
          </Container>
          {/* <ReactPlayer loop={true} playing={true} width="100%" height="auto" muted={true} url="https://media.riochain.io/rio-chain-banner.mp4" /> */}
          {/* <Divider /> */}
          {/* <ProductHome /> */}
          {/* <div className="ribbon" style={{ background: '#4e87f7', padding: '12px 0', color: '#fff', textAlign: 'center', fontSize: '18px' }}>
            <span style={{ marginRight: '12px' }}>
              RioDeFi Community  Crowdsale Whitelist application form is live!
            </span>
            <Button style={{ color: '#4e87f7', border: 'none', fontWeight: 'bold' }}>
              <a target="_blank" rel="noreferrer noopener nofollow" href="https://www.cognitoforms.com/Riodefi1/RioDeFiCommunityCrowdsaleWhitelist2">
                Subscribe
              </a>
            </Button>
          </div> */}
          <Architecture />
          {/* <VideoRioChain/> */}
          <Ecosystem />
          <ProductCategories />
          {/* <Question /> */}
          {/* <DApp /> */}
          {/* <WhitePaper /> */}
          <Partners/>
          <LatestNews />
          {/* {lang !== '中文' && <PeopleLove device="pc" />} */}
          {/* <MediumNews/> */}
          <Subscribe />
          <AppFooter />
        </React.Fragment>
      ) : (
          <Mobile />
        )
    );
  }
}

export default withRoot(Index);
