// --- Post bootstrap -----
import React from "react";
// import { Carousel } from 'antd';
// import Banner from "../components/Banner";
import Value from "../components/Value";
import Banner from "./Banner";

import MobileArchitecture from "../components/MobileArchitecture";
import MobileVideoRioChain from "../components/MobileVideoRioChain";
// import DateCountdown from 'react-date-countdown-timer'
import Category from "../components/Category";
import Ecos from "../components/Ecos";
import MobileSubscribe from "../components/MobileSubscribe";
import MobileFooter from "../components/MobileFooter";
import CardIntro from "../../modules/views/CardIntro/index";
// import WhitePaper from "../components/WhitePaper";
import Layout from "../components/Layout";
import LatestNews from "../components/LatestNews";
import Partners from "../components/Partners";
// import PeopleLove from "../../modules/views/PeopleLove";
import Language from "../../modules/common/Language";
// import DApp from '../components/DApp';
// import Banner0 from '../../assets/img/banner/0.png'
// import Banner1En from '../../assets/img/banner/1.png'
// import Banner1Zh from '../../assets/img/banner/1.png'
// import Banner2En from '../../assets/img/banner/2.png'
// import Banner2Zh from '../../assets/img/banner/2.png'
// import Banner3En from '../../assets/img/banner/3-en.jpeg'
// import Banner3Zh from '../../assets/img/banner/3-zh.png'
import UniswapIcon from "../../assets/uniswap.svg";

// const lang = Language.CurLanguage();
import bannerImg from '../../assets/img/banner/Riodefi_Mantra.jpg';
class Index extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          <div
            style={{
              padding: "8px 12px",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={UniswapIcon}
              style={{
                verticalAlign: "middle",
                marginRight: "8px",
                width: "16px",
              }}
            />
            <span>
              Get RFuel on Uniswap:&nbsp;&nbsp;
              <a
                target="_blank"
                href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xaf9f549774ecedbd0966c52f250acc548d3f36e5"
              >
                0xaf9f549774ecedbd0966c52f250acc548d3f36e5
              </a>
            </span>
          </div>

          <Banner />
          <Value />
          {/* <Carousel autoplay={true}>
          <div>
              <a target="_blank" rel="noreferrer noopener nofollow" href="https://riodefiofficial.medium.com/riochain-launch-giveaway-campaign-winners-161a461ab43f">
                <img src={Banner0} />
              </a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer noopener nofollow" href="https://riodefiofficial.medium.com/riochain-launch-giveaway-campaign-aeecac8a830a">
                {lang === 'English' && <img src={Banner1En} />}
                {lang === '中文' && <img src={Banner1Zh} />}
              </a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer noopener nofollow" href="https://riodefiofficial.medium.com/riochain-release-roadmap-9097fc5d9757">
                {lang === 'English' && <img src={Banner2En} />}
                {lang === '中文' && <img src={Banner2Zh} />}
              </a>
            </div>
          </Carousel> */}
          {/* <div style={{ textAlign: 'center', padding: '24px', fontSize: '24px', fontWeight: 'bold', background: '#fff'}}>
            <span style={{ fontSize: '20px', marginBottom: '8px', color: 'rgb(0, 32, 96)' }}>Follow us on <a  style={{color: '#4c88f6'}} target="_blank" rel="noreferrer noopener nofollow" href="https://twitter.com/riodefiofficial">Twitter</a> and <a style={{color: '#4c88f6'}} target="_blank" rel="noreferrer noopener nofollow" href="https://t.me/Riodefiofficial">Telegram</a></span>
          </div> */}
          {/* <img
            src={"https://media.riochain.io/mobile-banner.gif"}
            alt="home-banner"
            style={{ width: "100%" }}
          /> */}
          {/* <Banner /> */}
          {/* <div className="ribbon" style={{ background: '#4e87f7', padding: '12px 0', color: '#fff', textAlign: 'center', fontSize: '18px' }}>
            <span style={{ display: 'inline-block', marginBottom: '12px' }}>
              RioDeFi Community  Crowdsale Whitelist application form is live!
            </span>
            <Button style={{ color: '#4e87f7', border: 'none', fontWeight: 'bold' }}>
              <a target="_blank" rel="noreferrer noopener nofollow" href="https://www.cognitoforms.com/Riodefi1/RioDeFiCommunityCrowdsaleWhitelist2">
                Subscribe
              </a>
            </Button>
          </div> */}
          <img
            src={bannerImg}
            alt="home-banner"
            style={{ width: "100%" }}
          />
          {/* <CardIntro /> */}
          <MobileArchitecture />
          <MobileVideoRioChain />
          <Ecos />
          <Category />
          {/* <DApp /> */}
          {/* <WhitePaper /> */}
          <Partners />
          <LatestNews />
          {/* {lang !== "中文" && <PeopleLove device="mobile" />} */}
          {/* <MediumNews/> */}
          <MobileSubscribe />
          <MobileFooter />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Index;
