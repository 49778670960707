// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";
import { Carousel } from "antd-mobile";
// import ReactPlayer from 'react-player'
// import VideoThumb3 from '../../assets/img/video-thumb-3.png';


let Head = {};
Head["calvin"] = require("../assets/calvin_head.svg");
Head["ekaterina"] = require("../assets/ekaterina_head.svg");
Head["gino"] = require("../assets/gino_head.svg");
Head["james"] = require("../assets/james_head.svg");
Head["stephane"] = require("../assets/stephane_head.svg");
Head["harrison"] = require("../assets/team_harrison.png");
Head["shahen"] = require("../assets/team_shahen.png");
Head["sunny"] = require("../assets/team_sunny.png");
Head["argu"] = require("../assets/team_argu.png");
Head["phyrex"] = require("../assets/team_phyrex.png");
Head["stacie"] = require("../assets/team_stacie.png");
Head["will"] = require("../assets/team_will.png");
Head["john"] = require("../assets/team_john.png");
// Head["billy"] = require("../assets/team_billy.png");


class Index extends React.Component {
    state = {};

    componentDidMount() { }

    render() {
        const lang = Language.CurLanguage();


        return (
            <div id="ourteam" className={"column center"} style={{paddingBottom: apx(117),backgroundColor: '#ffffff'}}>
                <span
                    style={{
                        fontSize: apx(36),
                        color: "#0B103C",
                        fontWeight: 600,
                        marginTop: apx(50)
                    }}
                >
                    {Language.About.Team.Title[lang]}
                </span>
                <span
                    style={{
                        fontSize: apx(22),
                        color: "#5A5A5A",
                        lineHeight: apx(30),
                        width: apx(660),
                        textAlign: "center",
                        marginTop: apx(36)
                    }}
                >
                    {Language.About.Team.Desc[lang]}
                </span>
                <div
                    style={{
                        alignItems: "center",
                        marginTop: apx(36),
                        height: apx(800),
                        width: apx(750),
                    }}
                >
                    <Carousel
                        autoplay={true}
                        infinite
                        autoplayInterval={3000}
                        dotActiveStyle={{
                            width: apx(16),
                            height: apx(16),
                            marginBottom: apx(3),
                            backgroundColor: "#0D90FD",
                            border: `1px solid #0D90FD`
                        }}
                        dotStyle={{
                            width: apx(16),
                            height: apx(16),
                            marginBottom: apx(0),
                            backgroundColor: "rgba(240,240,240,1)",
                            border: `1px solid #0B103C`
                        }}
                    >
                        {
                            Language.Mobile.About.Momber[lang].map((item, index, arr) => (
                                <div className={"column"} style={{ marginLeft: apx(35), width: apx(690),height: apx(800),borderRadius: apx(10), backgroundColor: 'rgba(249,249,249,1)' }}>
                                    <div
                                        className={"row"}
                                        style={{
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            marginTop: apx(60)
                                        }}
                                    >
                                        <img
                                            src={Head[item.icon]}
                                            style={{
                                                borderRadius: '100%',
                                                width: apx(90),
                                                height: apx(90),
                                                marginLeft: apx(55)
                                            }}
                                            alt=""
                                        />
                                        <div className={"column"} style={{marginLeft: apx(20)}}>
                                        <span
                                                style={{
                                                    width: apx(237),
                                                    fontSize: apx(30),
                                                    color: "#0B103C",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {item.name}
                                            </span>
                                            <span
                                                style={{
                                                    width: apx(237),
                                                    fontSize: apx(22),
                                                    color: "#5485E7",
                                                    fontWeight: "bold",
                                                    textTransform: 'uppercase'
                                                    
                                                }}
                                            >
                                                {item.major}
                                            </span>
                                            <span
                                                style={{
                                                    width: apx(460),
                                                    fontSize: apx(22),
                                                    color: "#5A5A5A",
                                                    lineHeight: apx(30),
                                                    marginTop: apx(30)
                                                }}
                                            >
                                                {item.inter1}
                                            </span>
                                            <span
                                                style={{
                                                    width: apx(460),
                                                    fontSize: apx(22),
                                                    color: "#5A5A5A",
                                                    lineHeight: apx(30),
                                                    marginTop: apx(20)
                                                }}
                                            >
                                                {item.inter2}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
                {/* advisors start */}
                <span
                    style={{
                        fontSize: apx(36),
                        color: "#0B103C",
                        fontWeight: 600,
                        marginTop: apx(50)
                    }}
                >
                    {Language.About.Advisors.Title[lang]}
                </span>
                <div
                    style={{
                        alignItems: "center",
                        marginTop: apx(36),
                        height: apx(720),
                        width: apx(750),
                    }}
                >
                    <Carousel
                        autoplay={true}
                        infinite
                        autoplayInterval={3000}
                        dotActiveStyle={{
                            width: apx(16),
                            height: apx(16),
                            marginBottom: apx(3),
                            backgroundColor: "#0D90FD",
                            border: `1px solid #0D90FD`
                        }}
                        dotStyle={{
                            width: apx(16),
                            height: apx(16),
                            marginBottom: apx(0),
                            backgroundColor: "rgba(240,240,240,1)",
                            border: `1px solid #0B103C`
                        }}
                    >
                        {
                            Language.Mobile.About.Advisors[lang].map((item, index, arr) => (
                                <div className={"column"} style={{ marginLeft: apx(35), width: apx(690),height: apx(720),borderRadius: apx(10), backgroundColor: 'rgba(249,249,249,1)' }}>
                                    <div
                                        className={"row"}
                                        style={{
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            marginTop: apx(60)
                                        }}
                                    >
                                        <img
                                            src={Head[item.icon]}
                                            style={{
                                                borderRadius: '100%',
                                                width: apx(90),
                                                height: apx(90),
                                                marginLeft: apx(55)
                                            }}
                                            alt=""
                                        />
                                        <div className={"column"} style={{marginLeft: apx(20)}}>
                                        <span
                                                style={{
                                                    width: apx(237),
                                                    fontSize: apx(30),
                                                    color: "#0B103C",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {item.name}
                                            </span>
                                            <span
                                                style={{
                                                    width: apx(237),
                                                    fontSize: apx(22),
                                                    color: "#5485E7",
                                                    fontWeight: "bold",
                                                    
                                                }}
                                            >
                                                {item.major}
                                            </span>
                                            <span
                                                style={{
                                                    width: apx(460),
                                                    fontSize: apx(22),
                                                    color: "#5A5A5A",
                                                    lineHeight: apx(30),
                                                    marginTop: apx(30)
                                                }}
                                            >
                                                {item.inter1}
                                            </span>
                                            <span
                                                style={{
                                                    width: apx(460),
                                                    fontSize: apx(22),
                                                    color: "#5A5A5A",
                                                    lineHeight: apx(30),
                                                    marginTop: apx(20)
                                                }}
                                            >
                                                {item.inter2}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
                {/* <ReactPlayer light={VideoThumb3} controls style={{
                    marginTop: '32px'
                }} width="100%" height="100%" url="https://media.riochain.io/why-rio.mov"/> */}
            </div>
        );
    }
}

export default Index;
