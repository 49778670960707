import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Iframe from 'react-iframe'

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
    },
});

function BlockExplorer(props) {
    const { classes } = props;
    return (
        <section className={classes.root}>
            <Iframe url="https://scan.staging.riodefi.com"
                width="100%"
                height="1980"
                scrolling="on"
                display="block"
                frameBorder="0"
                overflow="hidden"
                position="relative" />
        </section>
    );
}

BlockExplorer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BlockExplorer);
