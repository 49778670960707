// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";
import { Typography,Link } from "@material-ui/core";
import recruiment_header_en from '../assets/recruiment_header_en.png';
import recruiment_header_cn from '../assets/recruiment_header_cn.png';

class Index extends React.Component {
    state = {};

    componentDidMount() { }

    render() {
        const lang = Language.CurLanguage();

        return (
            <div id="white_paper" className={"column"} style={{ backgroundColor: "#fff",marginBottom: apx(100) }}>
                <div className={"column center"}>
                <img src={
                    "English" === lang ? recruiment_header_en : recruiment_header_cn
                } alt="" style={{
                    width: apx(660),
                    height: apx(320),
                    marginTop: apx(80),
                }} />
                {
                    Language.Recruitment.Job[lang].map((item, index, arr) => (
                        <Typography key={index} style={{
                            width: apx(670),
                            marginTop: apx(80),
                            textAlign: 'left'
                        }}>
                            <Typography style={{
                                fontSize: apx(36),
                                color: 'rgba(11,16,60,1)',
                                foneWeight: 500
                            }}>
                                {item.job}
                            </Typography>
                            {
                                item.detail.map((item, index, arr) => (
                                    <>
                                        <Typography key={index} style={{
                                            marginTop: apx(40),
                                            fontSize: apx(26),
                                            foneWeight: 500,
                                            color: 'rgba(11,16,60,1)'
                                        }}>
                                            {item.title}
                                        </Typography>
                                        {
                                            item.detail.map((item,index,arr) => (
                                                <Typography key={index} style={{
                                                    marginTop: apx(22),
                                                    fontSize: apx(26),
                                                    foneWeight: 400,
                                                    color: 'rgba(90,90,90,1)'
                                                }}>
                                                    {item}
                                                </Typography>
                                            ))
                                        }
                                    </>
                                ))
                            }
<Link
                                underline="none"
                                href="mailto:contact@riodefi.com"
                            >
                            <Typography
                            style={{
                                marginTop: apx(50),
                                width: apx(260),
                                height: apx(70),
                                lineHeight: apx(70),
                                textAlign: 'center',
                                borderRadius: apx(4),
                                fontSize: apx(26),
                                fontWeight: 500,
                                border: '1px solid rgba(11,16,60,1)'
                            }}>
                                {Language.Recruitment.Resume[lang]}
                            </Typography>
                            </Link>
                        </Typography>
                    ))
                }
                </div>

            </div>
        );
    }
}

export default Index;
