import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import Language from "../../modules/common/Language";
import cn from "classnames";

import { Row, Col } from "antd";

import Consensus from "../../assets/img/partners/consensus.svg";
import Amplifi from "../../assets/img/partners/amplifi.png";
import AU21Capital from "../../assets/img/partners/AU21_Capital.svg";
import Mantra from "../../assets/img/partners/mantra.svg";
import Brilliance from "../../assets/img/partners/brilliance.svg";
import Kenetic from "../../assets/img/partners/kenetic.png";
import ascend from "../../assets/img/partners/ascend.png";
import bitbns from "../../assets/img/partners/bitbns.png";
import Web3auth from "../../assets/img/partners/web3auth.svg";
import theforce from "../../assets/img/partners/theforce.svg";
import Xtream from "../../assets/img/partners/xtream.png";
import Mapleblock from "../../assets/img/partners/mapleblock.png";
import MasterVentures from "../../assets/img/partners/MasterVentures.png";
import MerkleCapital from "../../assets/img/partners/merkle_capital.svg";
import Moonrock from "../../assets/img/partners/Moonrock.png";
import Plutus from "../../assets/img/partners/plutus.png";
import SignalVentures from "../../assets/img/partners/SignalVentures.png";
import Vendetta from "../../assets/img/partners/vendetta.png";
import Bluzelle from "../../assets/img/partners/bluzelle.svg";
import Kylin from "../../assets/img/partners/kylin.svg";
import Aleph from "../../assets/img/partners/aleph.png";
import Labs from "../../assets/img/partners/labs.png";
import Chainlink from "../../assets/img/partners/chainlink.svg";
import Lepricon from "../../assets/img/partners/lepricon.png";
import Dragonbite from "../../assets/img/partners/dragonbite.png";
import torus from "../../assets/img/partners/torus.png";
import cinchblock from "../../assets/img/partners/cinchblock.png";
// import Skchain from "../../assets/img/partners/skchain.svg";
import Substrate from "../../assets/img/partners/substrate.png";
import Lemonode from "../../assets/img/partners/lemonode.png";
import megala from "../../assets/img/partners/megala.png";

import Bithumb from "../../assets/img/partners/bithumb.svg";
import Kucoin from "../../assets/img/partners/kucoin.png";
import Okex from "../../assets/img/partners/okx.svg";
import pancakeswap from "../../assets/img/partners/pancakeswap.png";
import Uniswap from "../../assets/img/partners/uniswap.svg";
// import Flybit from "../../assets/img/partners/flybit.png";
import Hotbit from "../../assets/img/partners/hotbit.png";
// import Biki from "../../assets/img/partners/biki.png";
import Poloniex from "../../assets/img/partners/poloniex.svg";
// import Bitmax from "../../assets/img/partners/bitmax.svg";
import Hoo from "../../assets/img/partners/hoo.svg";
import Bitmart from "../../assets/img/partners/bitmart.svg";
import Gateio from "../../assets/img/partners/gateio.svg";
import Kingswap from "../../assets/img/partners/kingswap.svg";
// import Bitbns from "../../assets/img/partners/bitbns.svg";

import Plasma from "../../assets/img/partners/plasma.svg";
import Poolz from "../../assets/img/partners/poolz.svg";
import Raze from "../../assets/img/partners/raze.svg";

import HexTrust from "../../assets/img/partners/hex-trust.svg";
// import Block72 from "../../assets/img/partners/block72.svg";
// import MarketAcross from "../../assets/img/partners/marketacross.png";
import Bcw from "../../assets/img/partners/bcw.png";
import Moonpay from "../../assets/img/partners/moonpay.svg";
import Vauld from "../../assets/img/partners/vauld.png";

import sk from "../../assets/img/partners/sk.png";
import unifarm from "../../assets/img/partners/unifarm.png";
import spiderdao from "../../assets/img/partners/spiderdao.png";
import simplex from "../../assets/img/partners/simplex.png";
import netvrk from "../../assets/img/partners/netvrk.png";
import refinable from "../../assets/img/partners/refinable.png";
import creator from "../../assets/img/partners/creator.png";
// import defina from "../../assets/img/partners/defina.png";
import luxfi from "../../assets/img/partners/luxfi.png";
import Goingape from "../../assets/img/partners/goingape.svg";
// import celestial from "../../assets/img/partners/celestial.png";
// import phatom from "../../assets/img/partners/phatom.png";
// import bagels from "../../assets/img/partners/bagels.png";
import kardia from "../../assets/img/partners/kardia.png";
import guarda from "../../assets/img/partners/guarda.png";
import bondly from "../../assets/img/partners/bondly.png";
import defina from "../../assets/img/partners/defina.png";
import phantom from "../../assets/img/partners/phantom.png";
import celestial from "../../assets/img/partners/celestial.png";
import gaurdawallet from "../../assets/img/partners/gaurdawallet.png";
import zmorph from "../../assets/img/partners/zmorph.svg";

const ecosystemList = [
  {
    img: Mantra,
    link: "https://mantradao.com/",
  },
  {
    img: Labs,
    link: "https://labsgroup.io/",
  },
  {
    img: Lepricon,
    link: "https://lepricon.io/",
  },

  {
    img: Goingape,
    link: "https://www.goingape.io/",
  },

  {
    img: luxfi,
    link: "https://www.luxfi.io/",
  },
  {
    img: Poolz,
    link: "https://www.poolz.finance/",
  },
  {
    img: Chainlink,
    link: "https://chain.link/",
  },
  // {
  //   img: Dragonbite,
  //   link: "https://lepricon.io/",
  // },

  {
    img: Moonpay,
    link: "https://www.moonpay.io/",
  },
  {
    img: simplex,
    link: "https://www.simplex.com/",
  },
  {
    img: HexTrust,
    link: "https://hextrust.com/",
  },
  {
    img: Plasma,
    link: "https://plasmapay.com/",
  },

  {
    img: Substrate,
    link: "https://www.parity.io/substrate/",
  },
  {
    img: Bcw,
    link: "https://bcw.group/",
  },
  {
    img: Bluzelle,
    link: "https://bluzelle.com/",
  },
  {
    img: Vauld,
    link: "https://www.vauld.com/home",
  },
  {
    img: Aleph,
    link: "https://aleph.im/#/",
  },
  {
    img: Raze,
    link: "https://raze.network/",
  },
  {
    img: spiderdao,
    link: "https://www.spiderdao.io/",
  },
  {
    img: netvrk,
    link: "https://www.netvrk.co/",
  },
  {
    img: guarda,
    link: "https://guarda.com",
  },
  {
    img: unifarm,
    link: "https://unifarm.co/",
  },
  {
    img: Web3auth,
    link: "https://web3auth.io/",
  },
  {
    img: refinable,
    link: "https://refinable.com/",
  },
  {
    img: creator,
    link: "https://www.creatorchain.network/",
  },
  {
    img: kardia,
    link: "https://kardiachain.io/",
  },
  {
    img: theforce,
    link: "https://theforce.trade/",
  },


  {
    img: bondly,
    link: "https://www.bondly.finance/",
  },

  
  {
    img: defina,
    link: "https://defina.finance/",
  },

  {
    img: phantom,
    link: "https://phantom.app/",
  },

  {
    img: celestial,
    link: "https://celt.game/#/",
  },

  {
    img: gaurdawallet,
    link: "https://guarda.co/app/?utm_source=Landing&utm_medium=to-app&utm_campaign=Marketing",
  },

  // {
  //   img: cinchblock,
  //   link: "https://www.cinchblock.com/",
  // },
 
  {
    img: Kylin,
    link: "https://kylin.network/",
  },
  {
    img: zmorph,
    link: "",
  },



];

const ventureList = [
  {
    img: Plutus,
    link: "http://plutus.vc/",
  },
  {
    img: Moonrock,
    link: "https://www.moonrockcapital.io/",
  },
  {
    img: SignalVentures,
    link: "https://signal.vc/",
  },
  {
    img: Vendetta,
    link: "https://vendettacapital.net/",
  },
  {
    img: Brilliance,
    link: "https://brillianceventures.com/",
  },
  {
    img: Amplifi,
    link: "https://amplifi.vc/",
    width: "160px",
  },
  {
    img: MasterVentures,
    link: "https://www.master.ventures/",
  },
  {
    img: Kenetic,
    link: "https://kenetic.capital/",
  },
  {
    img: MerkleCapital,
    link: "https://merkle.capital/",
  },
  {
    img: AU21Capital,
    link: "https://au21.capital/",
  },
  {
    img: Consensus,
    link: "http://consensus-lab.com/",
  },

  {
    img: Mapleblock,
    link: "https://www.mapleblock.capital/",
  },
  {
    img: Xtream,
    link: "#",
  },
  {
    img: Lemonode,
    link: "http://lemon.studio-88.ru/",
  },
  {
    img: megala,
    link: "https://megala.ventures/",
  },
];

const exchangeList = [
  {
    img: Okex,
    link: "https://www.okex.com/",
  },
  {
    img: Uniswap,
    link: "https://uniswap.org/",
  },
  {
    img: ascend,
    link: "https://ascendex.com/",
  },
  {
    img: Kucoin,
    link: "https://www.kucoin.com/",
  },
  {
    img: Bitmart,
    link: "https://www.bitmart.com/",
  },
  {
    img: Poloniex,
    link: "https://poloniex.com/",
  },
  {
    img: bitbns,
    link: "https://bitbns.com/",
  },
  {
    img: pancakeswap,
    link: "https://pancakeswap.finance/",
  },
  // {
  //   img: Bithumb,
  //   link: "https://www.bithumb.pro/",
  // },
  // {
  //   img: Kingswap,
  //   link: "https://www.kingswap.io/",
  // },
  {
    img: Hotbit,
    link: "https://www.hotbit.io/",
  },
  // {
  //   img: Hoo,
  //   link: "https://hoo.com/",
  // },
  {
    img: Gateio,
    link: "https://gate.io/",
  },

  // {
  //   img: bagels,
  //   link: "https://bagels.finance",
  // },
];

const styles = (theme) => ({
  root: {
    // display: "flex",
    // overflow: "hidden",
    // backgroundColor: "#4E87F7",
    // margin: "64px auto 44px",
  },
  container: {
    marginTop: "90px",
    marginBottom: "90px",
    display: "flex",
    position: "relative",
    flexDirection: "column",
  },
  item1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // height: '438px',
    padding: theme.spacing(0, 5),
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "438px",
    padding: theme.spacing(0, 5),
  },
  image: {
    width: "90%",
    // marginBottom: "32px",
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255,255,255,1) 100%), rgba(235, 235, 235, .5)",
    borderRadius: "25px",
    padding: "0 30px",
    height: "180px",
    marginBottom: "25px",
    "@media (max-width: 992px)": {
      padding: "0 16px",
      height: "120px",
    },
  },
  title: {
    fontWeight: "300",
    fontSize: "64px",
    lineHeight: "72px",
    textAlign: "center",
    padding: "25px 0",
    position: "relative",
    marginBottom: "80px",
    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: "0",
      height: "2px",
      width: "100%",
      borderRadius: "5px",
    },
    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      bottom: "0",
      height: "2px",
      width: "100%",
      borderRadius: "5px",
    },
    "@media (max-width: 992px)": {
      marginBottom: "32px",
      fontSize: "32px",
      lineHeight: "1.2",
    },
  },
  title1: {
    color: "#F40F9D",
    "&::before": {
      background:
        "linear-gradient(270deg, rgba(0, 32, 96, 0) 0%, #F40F9D 50%, rgba(0, 32, 96, 0) 100%), #FFFFFF",
    },
    "&::after": {
      background:
        "linear-gradient(270deg, rgba(0, 32, 96, 0) 0%, #F40F9D 50%, rgba(0, 32, 96, 0) 100%), #FFFFFF",
    },
  },
  title2: {
    color: "#4E87F7;",
    "&::before": {
      background:
        "linear-gradient(270deg, rgba(0, 32, 96, 0) 0%, #4E87F7 50%, rgba(0, 32, 96, 0) 100%), #FFFFFF;",
    },
    "&::after": {
      background:
        "linear-gradient(270deg, rgba(0, 32, 96, 0) 0%, #4E87F7 50%, rgba(0, 32, 96, 0) 100%), #FFFFFF;",
    },
  },
  title3: {
    color: "#07E9B2",
    "&::before": {
      background:
        "linear-gradient(270deg, rgba(0, 32, 96, 0) 0%, #07E9B2 50%, rgba(0, 32, 96, 0) 100%), #FFFFFF;",
    },
    "&::after": {
      background:
        "linear-gradient(270deg, rgba(0, 32, 96, 0) 0%, #07E9B2 50%, rgba(0, 32, 96, 0) 100%), #FFFFFF;",
    },
  },
  block: {
    // background: "rgba(255, 255, 255, 0.9)",
    // borderRadius: "50px",
    // padding: "16px 32px",
    marginBottom: "44px",
  },
});

function Partners(props) {
  const { classes } = props;
  const lang = Language.CurLanguage();

  return (
    <section id="Partners" className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.block}>
          <Typography className={cn(classes.title, classes.title1)}>
            {Language.Home.Partners.EcosystemTitle[lang]}
          </Typography>
          <Row
            type="flex"
            align="middle"
            // justify="center"
            gutter={{ xs: 16, md: 18, lg: 26 }}
          >
            {ecosystemList.map((item) => (
              <Col xs={12} md={8} lg={6}>
                <a
                  href={item.link}
                  className={classes.link}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  <img src={item.img} className={classes.image} />
                </a>
              </Col>
            ))}
          </Row>
        </div>

        <div className={classes.block}>
          <Typography className={cn(classes.title, classes.title2)}>
            {Language.Home.Partners.VentureTitle[lang]}
          </Typography>
          <Row
            type="flex"
            align="middle"
            // justify="center"
            gutter={{ xs: 16, md: 18, lg: 26 }}
          >
            {ventureList.map((item) => (
              <Col xs={12} md={8} lg={6}>
                <a
                  className={classes.link}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  <img
                    src={item.img}
                    className={classes.image}
                    style={{ width: item.width }}
                  />
                </a>
              </Col>
            ))}
          </Row>
        </div>

        <div className={classes.block}>
          <Typography className={cn(classes.title, classes.title3)}>
            {Language.Home.Partners.ExchangeTitle[lang]}
          </Typography>
          <Row
            type="flex"
            align="middle"
            // justify="center"
            gutter={{ xs: 24, md: 18, lg: 26 }}
          >
            {exchangeList.map((item) => (
              <Col xs={12} md={8} lg={6}>
                <a
                  href={item.link}
                  className={classes.link}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  <img src={item.img} className={classes.image} />
                </a>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </section>
  );
}

Partners.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Partners);
