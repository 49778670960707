import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '../components/Typography';
import RioFlower from "../../assets/img/riochain-flower.png";
import Phone from "../../assets/img/phone_2.png";
import Parity from "../../assets/img/parity.svg";
import CompatIcon from "../../assets/img/compatibility_icon.svg";
import ConnectIcon from "../../assets/img/connect_icon.svg";
import InteroperIcon from "../../assets/img/interoper_icon.svg";
import Grid from '@material-ui/core/Grid';
import Language from '../common/Language';


const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgba(255,255,255,1)',
    width: '100%',
    height: 'auto'
  },
  item: {
    backgroundImage: `url(${RioFlower})`,
    backgroundSize: '100%',
    height: 'auto',
    minHeight: '360px',
    minWidth: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
  },
  title: {
    marginTop: '40px',
    fontSize: '22px',
    fontWeight: 400,
    color: 'rgba(255,255,255,1)',
    lineHeight: '26px',
  },
  icon: {
    marginTop: '60px',
  },
  desc: {
    marginTop: '60px',
    fontSize: '16px',
    fontWeight: 400,
    width: '334px',
    height: '69px',
    color: 'rgba(255,255,255,1)',
    lineHeight: '22px',
    letterSpacing: '1px',
    // wordBreak: 'break-all',
    textAlign: 'center',
  }
});

function ProductCategories(props) {
  const { classes } = props;
  const lang =Language.CurLanguage();

  const images1 = {"English": [
    {
      url: '',
      title: 'INTEROPERABILITY',
      icon: CompatIcon,
      content: 'RioChain is a public Blockchain built on the Parity Substrate framework and fully compatible with the POLKADOT network.',
      backgroundColor: 'rgba(11,16,60,1)',
    },
    {
      url: RioFlower,
      title: '',
      icon: '',
      content: '',
      backgroundColor: '',
    },
    {
      url: '',
      title: 'CONNECTIVITY',
      icon: ConnectIcon,
      content: 'Rio Wallet provides access to payment systems and allows users to withdraw fiat off their crypto accounts',
      width: '33%',
      backgroundColor: 'rgba(11,16,60,1)',
    }
  ],
  "Spanish": [
    {
      url: '',
      title: 'INTEROPERABILIDAD',
      icon: CompatIcon,
      content: 'RioChain es una Blockchain pública construida sobre el marco de Parity Substrate y totalmente compatible con la red POLKADOT',
      backgroundColor: 'rgba(11,16,60,1)',
    },
    {
      url: RioFlower,
      title: '',
      icon: '',
      content: '',
      backgroundColor: '',
    },
    {
      url: '',
      title: 'CONECTIVIDAD',
      icon: ConnectIcon,
      content: 'Rio Wallet proporciona acceso a los sistemas de pago y permite a los usuarios retirar dinero fiduciario de sus cuentas de criptomonedas',
      width: '33%',
      backgroundColor: 'rgba(11,16,60,1)',
    }
  ],
  "中文": [
    {
      url: '',
      title: '兼容性',
      icon: CompatIcon,
      content: 'Rio链是基于Parity的Substrate框架构建的联合区块链，与Polkadot网络完全兼容。',
      backgroundColor: 'rgba(11,16,60,1)',
    },
    {
      url: RioFlower,
      title: '',
      icon: '',
      content: '',
      backgroundColor: '',
    },
    {
      url: '',
      title: '连接性',
      icon: ConnectIcon,
      content: 'Rio钱包可以访问各种数字资产和移动支付选项。',
      width: '33%',
      backgroundColor: 'rgba(11,16,60,1)',
    }
  ]
};

  const images2 = {"English":[
    {
      url: Parity,
      title: '',
      icon: '',
      content: '',
    },
    {
      url: '',
      title: 'LIQUIDITY',
      backgroundColor: 'rgba(11,16,60,1)',
      icon: InteroperIcon,
      content: 'RioDeFi bridges centralized and decentralized financial systems and can facilitate cross-chain transactions.',
    },
    {
      url: Phone,
      title: '',
      icon: '',
      content: '',
    }
  ],
  "Spanish":[
    {
      url: Parity,
      title: '',
      icon: '',
      content: '',
    },
    {
      url: '',
      title: 'LIQUIDEZ',
      backgroundColor: 'rgba(11,16,60,1)',
      icon: InteroperIcon,
      content: 'RioDeFi sirve de puente entre los sistemas financieros centralizados y descentralizados y puede facilitar las transacciones entre cadenas.',
    },
    {
      url: Phone,
      title: '',
      icon: '',
      content: '',
    }
  ],
  "中文": [
    {
      url: Parity,
      title: '',
      icon: '',
      content: '',
    },
    {
      url: '',
      title: '互操作性',
      backgroundColor: 'rgba(11,16,60,1)',
      icon: InteroperIcon,
      content: 'Rio链促进了跨链交易，并在区块链与传统金融系统间架构了桥梁。',
    },
    {
      url: Phone,
      title: '',
      icon: '',
      content: '',
    }
  ]
};

  return (
    <section className={classes.root}>
      <Grid container justify="space-around" wrap="nowrap" style={{
        width: '1200px'
      }}  spacing={0}>
        {
          images1[lang].map(image => (
            <Grid item md={4}>
              <div style={{
                background: `url(${image.url}) center center no-repeat`,
                backgroundSize: '100%',
                backgroundColor: image.backgroundColor,
                height: '450px',
                width: '400px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
                <Typography className={classes.title}>
                  {image.title}
                </Typography>
                {image.icon && <Typography className={classes.icon}>
                  <img src={image.icon} style={{
                    height: '120px',
                    width: '120px'
                  }} alt="" />
                </Typography>}
                <Typography className={classes.desc}>
                  {image.content}
                </Typography>
              </div>
            </Grid>
          ))
        }
      </Grid>
      <Grid container justify="space-around" wrap="nowrap"  style={{
        width: '1200px'
      }}  spacing={0}>
        {
          images2[lang].map(image => (
            <Grid item md={4}>
              <div style={{
                background: `url(${image.url})  center center no-repeat`,
                backgroundColor: image.backgroundColor,
                height: '450px',
                width: '400px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
                <Typography className={classes.title}>
                  {image.title}
                </Typography>
                {
                  image.icon &&  <Typography className={classes.icon}>
                  <img src={image.icon} style={{
                    height: '120px',
                    width: '120px'
                  }} alt="" />
                </Typography>
                }
               
                <Typography className={classes.desc}>
                  {image.content}
                </Typography>
              </div>
            </Grid>
          ))
        }
      </Grid>
    </section>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCategories);
