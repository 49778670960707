// --- Post bootstrap -----
import React from "react";
import Recruitment from "../components/Recruitment";
import MobileFooter from "../components/MobileFooter";

import Layout from "../components/Layout";

class Index extends React.Component {
  state = {
    open: false
  };

  render() {
    return (
      <React.Fragment>
        <Layout>
          <Recruitment />
          <MobileFooter />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Index;
