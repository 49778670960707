import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
// import AppleIcon from "../../assets/img/apple_download_en.svg";
// import AppleIconComing from "../../assets/img/apple_download_en.svg";
// import AndroidIcon from "../../assets/img/android_download_en.svg";
import AndroidIconComing from "../../assets/img/android_download_en.svg";
// import GoogleIcon from "../../assets/img/google_coming_en.svg";
// import AppleIconCn from "../../assets/img/apple_download_cn.svg";
// import AppleIconCnComing from "../../assets/img/apple_download_cn.svg";
// import AndroidIconCn from "../../assets/img/android_download_cn.svg";
import AndroidIconCnComing from "../../assets/img/android_download_cn.svg";
// import GoogleIconCn from "../../assets/img/google_coming_cn.svg";
import GoogleIcon from "../../assets/img/google_riowallet.svg";

import AppstoreIcon from "../../assets/img/download_appstore.svg";
import GoogleplayIcon from "../../assets/img/download_googleplay.svg";

import Language from "../common/Language";

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    marginBottom: "69px",
  },
  container: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  title: {
    marginTop: "100px",
    fontSize: "66px",
    fontWeight: 800,
    textAlign: "center",
    // fontFamily: 'PingFangSC-Medium,PingFang SC;',
    lineHeight: "92px",
    color: "rgba(11,16,60,1)",
  },
  subtitle: {
    marginTop: "40px",
    marginBottom: "70px",
    fontSize: "36px",
    fontWeight: 400,
    color: "rgba(90,90,90,1)",
    lineHeight: "50px",
    width: "968px",
    textAlign: "center",
    // fontFamily: 'PingFangSC-Regular,PingFang SC;'
  },
  download: {
    display: "flex",
    position: "relative",
    marginTop: "40px",
    alignItems: "center",
  },
  download_link: {
    fontSize: "36px",
    fontWeight: 400,
    color: "rgba(90,90,90,1)",
    lineHeight: "80px",
    height: "80px",
    textAlign: "center",
    margin: '0 8px'
    // fontFamily: 'PingFangSC-Regular,PingFang SC;'
  },
  download_img: {
      height: '80px'
  },
  download_link1: {
    paddingLeft: "50px",
    paddingRight: "50px",
    fontSize: "36px",
    fontWeight: 400,
    color: "rgba(90,90,90,1)",
    lineHeight: "50px",
    height: "50px",
    textAlign: "center",
    // fontFamily: 'PingFangSC-Regular,PingFang SC;'
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    height: "200px",
    width: "750px",
  },
});

function ProductWallet(props) {
  const { classes } = props;
  const lang = Language.CurLanguage();

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <h1>
          <Typography className={classes.title}>
            {Language.Wallet.Title[lang]}
            <br />
            {Language.Wallet.Title2[lang]}
          </Typography>
        </h1>
        <Typography className={classes.subtitle}>
          {Language.Wallet.SubTitle[lang]}
        </Typography>
        <div className={classes.buttons}>
          <Typography className={classes.download}>
            <Link
              className={classes.download_link}
              underline="none"
              target="_blank"
              rel="noreferrer noopener nofollow"
              href="https://wallet.riochain.io/wallet"
            >
              <img src={GoogleIcon} className={classes.download_img} alt="" />
              {/* {
                                "English" === lang ? (
                                ) : null
                            }

                            {
                                "中文" === lang ? (
                                    <img src={GoogleIconCn} alt="" />
                                ) : null
                            } */}
            </Link>
          </Typography>
          <Typography className={classes.download}>
            <Link
              className={classes.download_link}
              underline="none"
              target="_blank"
              href="/riowallet_android.apk"
            >
              {"English" === lang ? (
                <img src={AndroidIconComing} className={classes.download_img} alt="" />
              ) : // <img src={AndroidIcon} alt="" />

              null}

              {"中文" === lang ? (
                <img src={AndroidIconCnComing} className={classes.download_img} alt="" />
              ) : // <img src={AndroidIconCn} alt="" />
              null}
            </Link>
          </Typography>
          <Typography className={classes.download}>
            <Link
              className={classes.download_link}
              underline="none"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.riodefi.store"
            >
              <img src={GoogleplayIcon} className={classes.download_img} alt="" />
            </Link>
          </Typography>

          <Typography className={classes.download}>
            <Link
              className={classes.download_link}
              underline="none"
              target="_blank"
              href="https://apps.apple.com/us/app/riodefi-wallet/id1560789648"
            >
              <img src={AppstoreIcon} className={classes.download_img} alt="" />
            </Link>
          </Typography>
        </div>
      </Container>
    </section>
  );
}

ProductWallet.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductWallet);
