export const STYLES = {
  column: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    position: 'relative',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    position: 'relative',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}

