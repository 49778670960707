import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Fade } from 'react-reveal'
// import TitleArrow from "../../assets/img/title-arrow.png";
import Typography from '../components/Typography';
// import ArchitectureEn from "../../assets/img/architecture_en.svg";
// import ArchitectureCn from "../../assets/img/architecture_cn.svg";
import Language from '../common/Language';

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: 'rgba(255,255,255,1)',
    },
    container: {
        marginTop: '16px',
        marginBottom: '60px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
    },
    item1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // height: '438px',
        padding: theme.spacing(0, 5),
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '438px',
        padding: theme.spacing(0, 5),
    },
    img_grid_root: {
        marginTop: '150px',
        marginBottom: '0px',
    },
    h2title: {
        display: 'block',
        width: '100%'
    },
    title: {
        fontSize: '46px',
        fontWeight: 600,
        color: '#002060',
        padding: '8px 18px',
        marginBottom: '14px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        // fontFamily: 'PingFangSC-Semibold,PingFang SC;'
    },
    body_font_content: {
        marginTop: '30px',
        width: '650px',
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'center',
        color: 'rgba(90,90,90,1)',
        lineHeight: '30px',
        // fontFamily: 'PingFangSC-Regular,PingFang SC;'
    }
});

function Ecosystem(props) {
    const { classes } = props;
    const lang = Language.CurLanguage();

    return (
        <section id="ecosystem" className={classes.root}>
            <Container className={classes.container}>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={12}>
                        <div className={classes.item1}>
                            <h2 className={classes.h2title}>
                                <Typography className={classes.title}>
                                <Fade left>

                                    {/* <img src={TitleArrow} className="title-arrow"/> */}
                                    <span dangerouslySetInnerHTML={{__html: Language.Home.Ecosystem.Title[lang]}}></span>
                                    </Fade>
                                </Typography>
                            </h2>
                            {/* <Typography className={classes.body_font_content} >
                                {Language.Home.Ecosystem.Content1[lang]}
                            </Typography> */}
                            {/* <Typography className={classes.body_font_content} >
                                {Language.Home.Ecosystem.Content2[lang]}
                            </Typography>
                            <Typography className={classes.body_font_content} >
                                {Language.Home.Ecosystem.Content3[lang]}
                            </Typography> */}
                        </div>
                    </Grid>
                </Grid>
                {/* <Grid container spacing={5} className={classes.img_grid_root}>
                    <Grid item xs={12} md={12}>
                        <div className={classes.item}>
                            {
                                "English" === lang ? (
                                    <img src={ArchitectureEn} style={{
                                        marginLeft: '10px'
                                    }} alt="" />
                                ) : null
                            }

                            {
                                "中文" === lang ? (
                                    <img src={ArchitectureCn} style={{
                                        marginLeft: '10px'
                                    }} alt="" />
                                ) : null
                            }

                        </div>
                    </Grid>
                </Grid> */}
            </Container>
        </section>
    );
}

Ecosystem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Ecosystem);
