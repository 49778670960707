// --- Post bootstrap -----
import React from "react";

import MobileFooter from "../components/MobileFooter";

import Layout from "../components/Layout";

const iframeWrapperStyle = {
  background: "url('/moonpay-bg.png') no-repeat",
  backgroundSize: '100% 100%',
  padding: '88px 0',
  textAlign: 'center'
}

const iframeStyle = {
  width: '95%',
  height: '700px',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  border: 0,
  overflow: 'hidden',
  background: '#fff'
}

class Index extends React.Component {
  state = {
    open: false
  };

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div style={iframeWrapperStyle}>
            <iframe
              style={iframeStyle}
              allow="accelerometer; autoplay; camera; gyroscope; payment"
              scrolling="no"
              frameborder="0"
              src="https://buy.moonpay.io/?apiKey=pk_live_sPRMDnKpUTez87jURbtB6GtTvROEuCA"
            >
            </iframe>
          </div>

          <MobileFooter />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Index;
