// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";

class Index extends React.Component {
  state = {};

  componentDidMount() {}

  render() {
    const lang = Language.CurLanguage();

    return (
      <div
        className={"row center"}
        style={{
          width: apx(750),
          flexWrap: "wrap",
          backgroundColor: "#ffffff"
        }}
      >
        <div
          className={"column"}
          style={{ width: apx(375),backgroundColor: 'rgba(34,42,74,1)', height: apx(316), alignItems: "center" }}
        >
          <span
            style={{ fontSize: apx(20), color: "#fff", marginTop: apx(30) }}
          >
            {lang === "English" && "I N T E R O P E R A B I L I T Y"}
            {lang === "中文" && "兼容性"}
            {lang === "Spanish" && "INTEROPERABILIDAD"}

          </span>
          <img
            src={require("../assets/category_1.svg")}
            style={{ width: apx(64), height: apx(64), marginTop: apx(40) }}
            alt=""
          />
          <span
            style={{
              fontSize: apx(16),
              color: "#fff",
              fontWeight: 400,
              textAlign: "center",
              marginTop: apx(30),
              width: apx(280),
              height: apx(84)
            }}
          >
            {lang === "English" && "RioChain is a public Blockchain built on the Parity Substrate framework and fully compatible with the POLKADOT network." }
            {lang === '中文' && "Rio链是基于Parity的Substrate框架构建的联合区块链，与Polkadot网络完全兼容。"}
            {lang === 'Spanih' && "RioChain es una Blockchain pública construida sobre el marco de Parity Substrate y totalmente compatible con la red POLKADOT"}
          </span>
        </div>

        <img
          src={require("../assets/category_2.png")}
          style={{ width: apx(375), height: apx(316) }}
          alt=""
        />

        <img
          src={require("../assets/category_3.png")}
          style={{ width: apx(375), height: apx(316) }}
          alt=""
        />
        <div
          className={"column"}
          style={{ width: apx(375),backgroundColor: 'rgba(34,42,74,1)', height: apx(316), alignItems: "center" }}
        >
          <span
            style={{ fontSize: apx(20), color: "#fff", marginTop: apx(30) }}
          >
            {lang === "English" && "C O N N E C T I V I T Y"}
            {lang === "中文" && "连接性"}
            {lang === "Spanish" && "CONECTIVIDAD"}
          </span>
          <img
            src={require("../assets/category_4.svg")}
            style={{ width: apx(64), height: apx(64), marginTop: apx(40) }}
            alt=""
          />
          <span
            style={{
              fontSize: apx(16),
              color: "#fff",
              fontWeight: 400,
              textAlign: "center",
              marginTop: apx(30),
              width: apx(280),
              height: apx(84)
            }}
          >
            {lang === "English"
              && "Rio Wallet provides access to payment systems and allows users to withdraw fiat off their crypto accounts."}
            {lang === "中文" && "Rio 钱包可以访问各种数字资产和移动支付选项。"}
            {lang === "Spanish" && "Rio Wallet proporciona acceso a los sistemas de pago y permite a los usuarios retirar dinero fiduciario de sus cuentas de criptomonedas"}
          </span>
        </div>

        <div
          className={"column"}
          style={{ width: apx(375), height: apx(316),backgroundColor: 'rgba(34,42,74,1)', alignItems: "center" }}
        >
          <span
            style={{ fontSize: apx(20), color: "#fff", marginTop: apx(30) }}
          >
            {lang === "English" && "L I Q U I D I T Y" }
            {lang === "中文" && "互操作性"}
            {lang === "Spanish" && "LIQUIDEZ"}
          </span>
          <img
            src={require("../assets/category_5.svg")}
            style={{ width: apx(64), height: apx(64), marginTop: apx(40) }}
            alt=""
          />
          <span
            style={{
              fontSize: apx(16),
              color: "#fff",
              fontWeight: 400,
              textAlign: "center",
              marginTop: apx(30),
              width: apx(280),
              height: apx(84)
            }}
          >
            {lang === "English" && "RioDeFi bridges centralized and decentralized financial systems and can facilitate cross-chain transactions."}
            {lang === "中文" && "Rio链促进了跨链交易，并在区块链与传统金融系统间架构了桥梁。"}
            {lang === "Spanish" && "RioDeFi sirve de puente entre los sistemas financieros centralizados y descentralizados y puede facilitar las transacciones entre cadenas."}
          </span>
        </div>

        <img
          src={require("../assets/category_6_2.png")}
          style={{ width: apx(375), height: apx(316) }}
          alt=""
        />
      </div>
    );
  }
}

export default Index;
