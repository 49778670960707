// --- Post bootstrap -----
import React from "react";
import NavHeader from "./NavHeader";
import { Drawer, List } from "antd-mobile";
import { Link } from "@material-ui/core";
import Language from "../../modules/common/Language";

class Index extends React.Component {
  state = {
    open: false,
  };

  render() {
    const lang = Language.CurLanguage();
    const whitePaperLink =
      lang !== "中文"
        ? "/Rio_DeFi_Whitepaper_English.pdf"
        : "/Rio_DeFi_Whitepaper_Chinese.pdf";

    const sidebar = (
      <List>
        {[0, 1, 2, 3].map((i, index, ar) => {
          if (0 === index) {
            return (
              <List.Item key={index} multipleLine>
                <Link underline="none" href="/">
                  {Language.Nav.Home[lang]}
                </Link>
              </List.Item>
            );
          }

          if (1 === index) {
            return (
              <List.Item key={index} multipleLine>
                <Link underline="none" href="/about">
                  {Language.Nav.About[lang]}
                </Link>
              </List.Item>
            );
          }
          if (2 === index) {
            return (
              <List.Item key={index} multipleLine>
                <Link underline="none" href="https://scan.v1.riochain.io/">
                  {Language.Nav.Block[lang]}
                </Link>
              </List.Item>
            );
          }

          if (3 === index) {
            return (
              <List.Item key={index} multipleLine>
                <Link underline="none" href="/wallet">
                  {Language.Nav.Wallet[lang]}
                </Link>
              </List.Item>
            );
          }

          return null;
        })}
        <List.Item multipleLine>
          <Link
            underline="none"
            href={whitePaperLink}
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            {Language.Nav.Whitepaper[lang]}
          </Link>
        </List.Item>
        <List.Item multipleLine>
          <Link underline="none" href="/moonpay">
            {Language.Nav.PurchaseMoonpay[lang]}
          </Link>
        </List.Item>
        <List.Item multipleLine>
          <Link underline="none" href="/simplex">
            {Language.Nav.PurchaseSimplex[lang]}
          </Link>
        </List.Item>
      </List>
    );

    return (
      <React.Fragment>
        <NavHeader onDrawer={() => this.setState({ open: !this.state.open })} />
        <Drawer
          className="my-drawer"
          style={{ minHeight: document.documentElement.clientHeight }}
          enableDragHandle
          sidebar={sidebar}
          open={this.state.open}
          onOpenChange={() => this.setState({ open: !this.state.open })}
        >
          {this.props.children}
        </Drawer>
      </React.Fragment>
    );
  }
}

export default Index;
