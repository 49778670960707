// --- Post bootstrap -----
import React from "react";

import MobileFooter from "../components/MobileFooter";

import Layout from "../components/Layout";

const iframeWrapperStyle = {
  // background: "url('/moonpay-bg.png') no-repeat",
  backgroundSize: "100% 100%",
  padding: "88px 0",
  textAlign: "center",
};

const iframeStyle = {
  width: "95%",
  height: "700px",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  border: 0,
  overflow: "hidden",
  background: "#fff",
};

class Index extends React.Component {
  state = {
    open: false,
  };

  componentDidMount() {
    if (document.getElementById("simplex-form")) {
      const trackScript = document.createElement("script");
      trackScript.src = "https://checkout.simplexcc.com/splx.js";
      document.body.appendChild(trackScript);

      const script = document.createElement("script");
      script.src = "https://iframe.simplex-affiliates.com/form.js";
      document.body.appendChild(script);


      const script2 = document.createElement("script");
      script2.src = "/handle-simplex.js";
      document.body.appendChild(script2);

    }
  }

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div style={iframeWrapperStyle}>
            <div id="simplex-form"></div>
          </div>

          <MobileFooter />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Index;
