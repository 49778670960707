// --- Post bootstrap -----
import React from "react";
import Iframe from 'react-iframe'
import Layout from "../components/Layout";

class Index extends React.Component {
  render() {
    
    return (
      <React.Fragment>
        <Layout>
            <div style={{
                width: '100%',
                height: '100%',
            }}>
            <Iframe url="https://scan.staging.riodefi.com"
                width="100%"
                height='100%'
                scrolling="on"
                display="block"
                frameBorder="0"
                overflow="hidden"
                position="relative" />
            </div>
            
        </Layout>
      </React.Fragment>
    );
  }
}

export default Index;
