// --- Post bootstrap -----
import React from "react";
import { apx } from "../../themes/util";
import Language from "../../modules/common/Language";
import { Typography } from "@material-ui/core";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

class Index extends React.Component {
    state = {};

    componentDidMount() { }

    render() {
        const lang = Language.CurLanguage();

        return (
            <div id="faq" className={"column"} style={{ backgroundColor: "#fff" }}>
                <div className={"column center"} style={{
                    marginBottom: '80px'
                }}>
                    <span
                        style={{
                            fontSize: apx(46),
                            color: 'rgba(0,0,0,1)',
                            fontWeight: 600,
                            marginTop: apx(140),
                            marginBottom: apx(40),
                            width: apx(616),
                            lineHeight: apx(65),
                            textAlign: 'center'
                        }}
                    >
                        {Language.ProductFaq.Title[lang]}
                    </span>
                    {/* <Typography className={classes.title}>
                        {Language.ProductFaq.Title[lang]}
                    </Typography> */}
                    <Typography style={{
                        color: '#717F99',
                        margin: '30px 0 20px',
                        textAlign: 'left',
                        fontSize: apx(46)
                    }}>
                        RioChain
                        </Typography>
                    {Language.ProductFaq.RioChain[lang].map((item, index, arr) => (
                        <ExpansionPanel style={{
                            width: '90%'
                        }}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{
                                fontSize: '18px'
                            }} />}>
                                <Typography style={{
                                    color: '#111760',
                                    fontSize: '14px',
                                }}>{item.title}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    <div style={{fontSize: '14px'}} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}
                    <Typography style={{
                        color: '#717F99',
                        margin: '30px 0 20px',
                        textAlign: 'left',
                        fontSize: apx(46)
                    }}>
                        {Language.ProductFaq.GeneralTitle[lang]}
                    </Typography>
                    {Language.ProductFaq.General[lang].map((item, index, arr) => (
                        <ExpansionPanel style={{
                            width: '90%'
                        }}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{
                                fontSize: '18px'
                            }} />}>
                                <Typography style={{
                                    color: '#111760',
                                    fontSize: '14px',
                                }}>{item.title}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    <div style={{fontSize: '14px'}} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}
                </div>

            </div>
        );
    }
}

export default Index;
