// --- Post bootstrap -----
import React from "react";
// import Language from "../../modules/common/Language";
import ReactPlayer from 'react-player'
import VideoThumb from '../../assets/img/video-thumb-1.png';

class Index extends React.Component {
  state = {};

  componentDidMount() {}

  render() {
    // const lang = Language.CurLanguage();
    return (
      <div className={"column center"} style={{ backgroundColor: "#ffffff", marginBottom: '32px' }}>
        {/* <ReactPlayer light={VideoThumb} width="100%" height="100%" url="https://media.riochain.io/rio-chain-intro.mov" controls/> */}
      </div>
    );
  }
}

export default Index;
