/*

=========================================================
* Now UI Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/qinxueweb
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/qinxueweb/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import Home from "./Home";
import About from "./AboutUs";
import Wallet from "./Wallet";
import BlockExplorer from "./BlockExplorer";
import Faq from './FAQ';
import Article from './Article'
import Moonpay from './Moonpay'
import Simplex from './Simplex'
import Contact from './pages/Contact'
import Card from './pages/Card'
import NotFound from './NotFound';
import Recruitment from './Recruitment';
import { Helmet } from 'react-helmet';

export default class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Helmet>
          <meta name="description" content="RioChain is a blockchain that features robust security, speed, scalability, and interoperability with existing blockchains." />
          <meta name="keywords" content="RioChain,Riodefi,blockchain,DeFi,cryptocurrencies,Bitcoin,Substrate,Contract,Staking,sidechain,decentralize" />
        </Helmet>
        <Switch>
          <Switch>
            <Route path="/" exact render={props => <Home />} />
            <Route path="/about" render={props => <About />} />
            <Route path="/wallet" render={props => <Wallet />} />
            <Route path="/block" render={props => <BlockExplorer />} />
            <Route path="/faq" render={props => <Faq />} />
            <Route path="/article/:slug" render={props => <Article/>} />
            <Route path="/recruit" render={props => <Recruitment />} />
            <Route path="/moonpay" render={props => <Moonpay />} />
            <Route path="/simplex" render={props => <Simplex />} />
            <Route path="/contact" render={props => <Contact />} />
            <Route path="/card" render={props => <Card />} />
            <Route path="/not-found" render={props => <NotFound />} />
            <Route render={() => <Redirect to="/not-found" />} />
          </Switch>
        </Switch>
      </BrowserRouter>
    );
  }
}
