import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '1240px',
    zIndex: 10,
  },
  container: {
    marginTop: theme.spacing(3),
    // display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

function HomeTopLayout(props) {
  const { children, classes } = props;

  return (
    <section className={classes.root}>
        {children}
    </section>
  );
}

HomeTopLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeTopLayout);
